.Horizontal__line {
  border-top:2px solid $green;
  width:200px;
}

.Text__image {
  position:relative;

  .Text__image_large {
    background: #fff;
    padding-top:100px;
    padding-bottom:100px;

    h2 {
      color: $black;
      font-weight: 800;
    }

    p {
      margin-top:50px;
      a {
        color:$green;
        font-weight: 800;
      }

      i {
        font-weight: 800;
      }
    }

  }

  .Text__image_small{
    .Horizontal__line {
      position:relative;
      left:-150px;
      top: 250px;
    }

    h3 {
      color:#fff;
      font-weight: 800;
    }

    h4 {
      color:#fff;
    }
  }

  .Text__image__right {
    padding: 50px 0;
    min-height: 300px;

  }
}

.About__Text_image {
  background:#fff;
  margin-top:20px;
  //background: url("/images/about/greenbelt.jpg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
  display: block;
  overflow: hidden;
}

.About_team {
  .About_team__Text {
    background: $green;
    min-height: 300px;
    top: -300px;
    padding-top: 50px;
    padding-right: 50px;
    padding-left:50px;

    p {
      color:#fff;
    }

    h3 {
      color: $black;
      text-transform: uppercase;
      font-weight: 500;
    }

    a {
      color:$black;
      border-bottom:1px solid $black;
      padding-bottom: 5px;
    }


  }
}

//Inspiring Section (Image-Text)

.Inspiring-section {
  .Inspiring_image {
    margin-top: -16px;
    margin-bottom: -16px;
    padding:0;
    .img-inspiring {
      width: 50%;
    }

    @media (min-width: 1024px) {
      .img-inspiring {
        width: 82%;
      }
    }

    @media (min-width:1143px) {
    .img-inspiring {
      width: 75.9%;
    }
    }

    @media (min-width: 1200px) {
      .img-inspiring {
        width: 72%;
      }
    }

  }

  .Inspiring_text {
    padding-top:100px;
    padding-bottom:100px;

      .Horizontal__line {
        display: none;
      }

    h2 {
      color: $black;
      font-weight: 800;
    }

    p:first-of-type {
      margin-top: 30px;
      margin-bottom:30px;
    }

    p {
      a {
        color:$green;
        font-weight: 800;
      }

      i {
        font-weight: 800;
      }
    }
  }
}

.Events_section {
  margin-top:-65px;
  background: #fff;

  .Events_background {
    background-size: 70%;
    background-position: right;
    background-repeat: no-repeat;

    .Events__image {
      padding:0;
      img {
        display: block;
      }
    }


    .Events_text {
      padding-top:90px;
      padding-bottom:90px;
      background: $green;

      h6 {
        color:#fff;
      }

      h2 {
        color:$black;
        font-weight: 800;
      }

      p:first-of-type {
        margin-top:20px;
        margin-bottom: 20px;
      }
      p:last-of-type {
        margin-bottom: 50px;
      }

      p {
        color:#fff;

        a {
          color:$black;
          font-weight: 800;
        }

        i {
          font-weight: 800;
        }
      }
    }
  }
}


@media (min-width: 40em) {

}

@media (min-width: 992px) {

  .Inspiring-section {
    margin-top: -300px;
  }
  .About_team {
    .About_team__Text {
      position: relative;
      width: 50%;
      background: $green;
      height: 300px;
      top: -300px;
    }
  }
}

@media (min-width: 1024px) {

  .Inspiring-section {
    .Inspiring_text {
      .Horizontal__line {
        position: relative;
        left:-140px;
        display: block;
      }
    }
  }

  .Events_section {

    background: linear-gradient(120deg, $green,$green, $green,rgba(255,255,255,0),rgba(255,255,255,0), rgba(255,255,255,0),rgba(255,255,255,0), rgba(255,255,255,0), rgba(255,255,255,0));

    .Events_background {
      //background:url("/images/about/events.jpg");
      background-size: 72%;
      background-repeat: no-repeat;
      background-position: right;

      .Events__image {
        img {
          display: none;
        }
      }
    }
  }



  .Text__image {

    .Text__image__right {
      position: relative;
      padding-top:0;
      padding-bottom:0;
    }
    .Text__image_large {
      padding-top:100px;
      padding-bottom:100px;
      padding-right:200px;

      h2 {
        color: $black;
        font-weight: 800;
      }

      p {
        margin-top:50px;
        a {
          color:$green;
          font-weight: 800;
        }

        i {
          font-weight: 800;
        }
      }
    }

    .Text__image_small{
      padding-top:100px;
      padding-bottom: 100px;
      img {
        position:absolute;
        top:0;
        width: 100%;
        left:0;
        right:0;
        bottom:0;
      }

      h3 {
        color:#fff;
        font-weight: 800;
      }

      h4 {
        color:#fff;
      }
    }
  }

  .About__Text_image {
    margin-top:20px;
    //background: url("/images/about/greenbelt.jpg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 36%;
    display: block;
  }
}

@media (min-width: 1440px) {
  .Text__image {
    .Text__image__right {
      right: -25%;
    }
  }
}
