.Register_header {
height: 310px;
background: linear-gradient(120deg, rgb(62, 73, 82), rgb(62, 73, 82), rgb(49, 129, 115), rgb(49, 129, 115));
background-size: cover;
position: relative;
z-index: 5;

  h1, h2, h3, h4, h5, h6, p, td, th, a {
    font-family: 'Open Sans', sans-serif;
  }
}

.Register_column {
  position: absolute;
  top: 200px;
  z-index: 1;
  overflow: hidden;

  @media (min-width: 640px) {
    left: 60px;
  }

  h3 {
    color: #fff;
    font-weight: 300;
    margin-top: 0;
    text-transform: uppercase;
  }

}

.Register_form {
  padding-top: 35px;
  padding-bottom: 50px;

  .input {
    position: relative;
    display: block;

    .fa {
      color: $gray;
    }

    .one_time_key {
      padding-left: 30px;
      padding-right: 30px;
      border: none;
      margin-bottom: 50px;
      border-bottom: 1px solid $gray;
    }

    i.first-icon {
      top: 11px;
      position: absolute;
      left: 2px;
    }

    i.last-icon {
      top: 11px;
      position: absolute;
      right: 2px;
      color: $green;
    }
  }

  @media (min-width: 640px) {
    padding-top: 30px;
    padding-bottom: 100px;

    .Code_columns {
      padding-right:0;
      padding-left:0;

      .Title   {
        font-size: 34px;
      }
    }

    .Form_columns {
      padding-right:150px;
      padding-left:30px;

      .call-para {
        margin-top: 20px;
        margin-bottom: 40px;
        font-weight: 600;
        color:$gray;
      }
    }
  }


  h1, h2, h3, h4, h5, h6, p, td, th, a {
    font-family: 'Open Sans', sans-serif;
  }

  .Title {
    margin-top: 20px;
    margin-bottom: 30px;
    color:$black;
  }

  .Icon {
  text-align: center;
    img {
    width: 80px;
    }
  }

  .Form {
    .firstname {
      padding-left:0;
      padding-right:0;
    }
    .lastname {
      padding-right:0;
      padding-left:0;
    }

    @media (min-width: 640px) {
      .firstname {
        padding-right:15px;
      }
      .lastname {
        padding-left: 15px;
      }

      .left {
        padding-left: 0;
      }

      .right {
        padding-right:0;
      }

      .both {
        padding-left:0;
        padding-right: 0;
      }
    }

    input[type=text], input[type=email], input[type=password], select, textarea {
      border-radius: 2px;
      margin-bottom: 30px;
      height: 48px;
    }

    .Register_button {
      width: 100%;
      border-radius: 2px;
      background: $green;
      padding-top: 20px;
      padding-bottom: 20px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom:0;
      margin-top:20px;
    }

    .Terms {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;

      p {
        margin:0;
      }
    }
    .Call_background {
      background: $light-gray;
      border-radius: 2px;
      p.heavy {
        margin: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        font-weight: 700;
        a {
          color: $green;
        }
      }
    }
  }
}