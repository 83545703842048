.Faq {
  position: absolute;
  top: 200px;
  z-index: 1;
  overflow: hidden;

  h1 {
    color:$green;
    font-weight: 200;

    margin-top:-10px;
    text-transform:uppercase;
    font-size:30px;
    padding-left:60px;
  }
}

.News {
  top: 150px;

  h2 {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
  }
}

.Faq__Form {
  padding: 40px 100px 20px;
  background: #fdfdfd;

  input[type=text], input[type=email], input[type=password], select, textarea {
    margin-bottom: 10px;
    font-size: 12px;
    background-color: #ffffff;
  }

  .Top_heading {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    padding-top: 15px;
  }

  .Top__border {
    border-top: 2px solid $green;
    width: 25px;
  }

  .Bottom__heading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 15px;
  }
  .faq-search-row {
    margin-top: 35px;
  }
  .faq-titles-row {
    padding-bottom: 80px;
  }
  .faq-search-button {
    height: 30px;
    width: 60px;
    border-radius: 0 2px 2px 0;
    background-color: $green;
    box-shadow: 0px 2px 1px 0 #ccc;
    border: #82ca9c;
    font-size: 7px;
    line-height: 2.5;
  }
  .faq-search {

  }
  .search {
    width: 207px;
    border: none;
    border-radius: 2px 0 0 2px;
    height: 30px;
    box-shadow: 1px 2px 2px 0 #ccc;
    float: right;

  }
  .faq-box {
    width: 60px;
    height: 60px;
    background-color: $green;
    p {
      font-size: 30px;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      vertical-align: middle;
      line-height: 60px;
    }
  }
  .question {
    text-transform: Uppercase;
    color: $black;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 14px;
    border-top: 1px;
  }
  .answer {
    font-size: 14px;
    margin-bottom: 30px;
  }
  .faq-questions {
    margin-top: -48px;
    margin-left: 90px;
    padding-bottom: 20px;
  }
  .faq-green-button {
    width: 145px;
    height: 48px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 132px;
    background-color: $green;
  }
  .faq-hr {
    hr {
      width: 100%;
      max-width: 100%;
      border-color: #dbdbdb;
    }
  }
  .faq-btn{
    text-align: center;
    padding-top:35px;
    padding-bottom: 35px;
    margin:0 auto;

    .ask-button{
      background-color: $green;
      max-width:350px;
      width: 100%;
      height:50px;
      line-height: 25px;
      text-transform: uppercase;
      font-weight:600;
    }
  }

  .faq-form-content{
    input[type=text],input[type=email], input[type=password] {
      margin-bottom: 10px;
      font-size: 12px;
      height:50px;
    }

  }
  .Faq-row {
    overflow: hidden;
  }
}

@media(max-width: 640px){
  .Faq__Form {
    padding:0;
    margin:0;
  }
  .Faq {
    h1 {
      padding:0;
    }
  }
  .faq-search-row {
    margin-top: 35px;
  }
  .faq-titles {
    padding-top: 50px;
  }

  .faq-search-button {
    height: 30px;
    width: 60px;
    border-radius: 0 2px 2px 0;
    background-color: $green;
    box-shadow: 0px 2px 1px 0 #ccc;
    border: #82ca9c;
    font-size: 7px;
    line-height: 2.5;
  }

  .search {
    width: 207px;
    border: none;
    border-radius: 2px 0 0 2px;
    height: 30px;
    box-shadow: 1px 2px 2px 0 #ccc;
    float: right;
  }

  .faq-questions {
    margin-top: -48px;
    margin-left: 90px;
    margin-right: -71px;
    padding-bottom: 20px;
  }

  .Faq__Form .faq-green-button {
    width: 100%;
  }
}

.faq-section {
  padding-right: 80px;
}

