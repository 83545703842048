.News_Body {
  padding-top: 100px;
  padding-bottom: 100px;

  .Col {
    background: $green;
    padding: 10px 5px;
    border-radius: 5px;
    margin-top: 10px;

    h3 {
      color: #fff;
      text-align:center;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0;

    }

    p {
      color: #fff;
      margin-top:-5px;
      margin-bottom: 0;
      text-align:center;
    }
  }

  .News_Content {
    padding-right: 30px;
    padding-left: 30px;

    hr {
      border-bottom: 1px solid #dfdfdf;
    }

    ul {
      li {
        font-size: 13px;
        color: $paragraph;
      }
    }

    .Avatar {
      .avatar {
        width: 60px;
        padding-bottom: 10px;
      }
    }

    @media (min-width: 40em) {
      .Avatar {
        padding-bottom: 40px;
        .avatar {
          padding-bottom: 0;
        }
      }
    }

    .Body {
      font-size: 13px;
      color: $paragraph;
      padding-bottom: 20px;

      .name {
        color: $black;
        text-transform: none;
        margin-bottom: 7px;
        font-weight: 600;
      }
    }

    @media (min-width: 40em) {
      .Body {
        padding-left: 0;
        padding-bottom: 40px;

        &.First {
          padding-bottom: 0;
        }
      }
    }

    .Submit {
      text-align: right;

      .button {
        background: $green;
        padding-right: 30px;
        padding-left: 30px;
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 0;
      }
    }

    .comments-title {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
    }

    .Title_hr {
      margin-top: 5px;
      margin-bottom: 40px;
    }

    .Title_para {
      margin-bottom: 10px;
      color: #9b9b9b;

      .author {
        color: #006666;
      }
    }

    p {
      margin-bottom: 10px;
    }

    .Title {
      color: $black;
      font-weight: 400;
      font-size: 32px;
      text-transform: uppercase;
    }

    .Blog_title {
      text-transform: none;
      font-weight: 600;
    }

    .first_para {
      color: $gray;
      .fa {
        color: $green;
      }
    }

    .blurb {
      p {
        line-height: 26px;
      }
    }

    .content_blurb {
      margin-bottom: 60px;
    }

    .share {
      list-style: none;

      li {
        display: inline;
        margin-right: 5px;
        margin-left: 5px;
        color: #c1c1c1;
        font-size: 14px;
        a {
          color : #c1c1c1;
          .fa {
            color : #c1c1c1;
          }
        }
      }
    }

    .link {
      color: $green;
      font-size: 14px;
      font-weight: 600;

      .fa {
        font-weight: 800;
        font-size: 18px;
      }
    }

    .bottom {
    }

    .articles_bottom {
      border: 1px solid #e1e1e1;
      padding: 30px 20px 20px;
      text-align: center;
    }

    .share_para {
      text-align: center;
      margin-bottom: -16px;

      span {
        background: #fff;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 24px;
        font-weight: 300;
      }
    }

    .facebook {
      color: #4267b2;
    }

    .twitter {
      color: #1da1f2;
    }
  }

  .Tags {
    .Categories {
      background: #f1f1f1;
      padding: 0 20px;
      table {
        margin-top: 30px;
        tr {
          td {
            padding: 10px 0;
            border-bottom: 1px solid #ddd;
            background: #f1f1f1;

            a {
              color: $black;
            }
            .active {
              color: $green;
            }
          }

          &:last-of-type {
            td {
              padding-bottom: 20px;
              border: transparent;
            }
          }
          &:first-of-type {
            td {
              padding-top: 20px;
              font-weight: 600;
            }
          }
        }


      }
    }

    h3 {
      font-size: 24px;
      color: $black;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    .Recent {
      line-height: 20px;

      a {
        font-size: 13px;
        color: $gray;
      }

      hr {
        margin-top: 8px;
        margin-bottom:8px;
        border-bottom: 1px solid $gray;
      }
    }

    .Call {
      .button {
        background: $green;
        color: #fff;
        width: 100%;
        padding-top: 14px;
        padding-bottom: 14px;
        font-size: 13px;
        font-weight: 700;
        margin-top: 20px;
        border-radius: 0;
      }

    }
    padding-top: 20px;
    .form-group {
      position: relative;

      .search-button {
        position:absolute;
        top: 10px;
        right: 20px;
        color: #c1c1c1;
      }
    }

    .button {
      background: #f8f9fb;
      color: $black;
      padding: 7px 8px;
      border-radius: 3px;
      margin-bottom: 5px;
      font-size: 12px;

      &.active {
        background: $green;
        color:#fff;
      }
    }

    .facebook {
      color: #4267b2;
    }

    .twitter {
      color: #1da1f2;
    }
  }
}

.Positioner {
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 40em) {
  .Positioner.first {
    padding-left: 0;
    padding-right: 15px;
  }

  .Positioner.last {
    padding-right: 0;
    padding-left: 15px;

  }
}

.Form {
  .h3 {
    color: $gray;
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    .fa {
      color: $green;
    }
  }

  hr {
    margin-top: 5px;
  }

  .button {
    margin-top: 15px;
  }
}

.split-form {
  label {
    font-weight: 600;
    color: $gray;
    margin-bottom: 3px;
    .italic {
      font-style: italic;
    }
  }
}




