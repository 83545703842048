input[type=text],input[type=email], input[type=password],input[type=time],,input[type=number], select,textarea {
  border-radius: 1px;
  box-shadow: none;
  padding-left:20px;
  border:1px solid #E1E1E1;
  font-size: 13px;
}

input[type=file] {
  font-size: 13px;
  padding-left:20px;
  border-radius: 1px;
  box-shadow: none;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px dotted $border-gray;
}

.nv-file-over {
  background-color: #FFBEA3;
}

.dropzone-area {
  font-size: 13px;
  padding-left:20px;
  border-radius: 1px;
  box-shadow: none;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px dashed $border-gray;
}


input[type=text]:focus, input[type=number]:focus, input[type=email]:focus,input[type=password]:focus,textarea,input[type=time] {
  outline: $green;
  box-shadow: none;
}

input[type=text]:active,input[type=text]:focus,input[type=number]:focus, input[type=email]:active, input[type=email]:focus,input[type=password]:active, input[type=number]:active, input[type=password]:focus,textarea:active,input[type=time]:active, input[type=time]:focus, input[type=time]:hover, input[type=number]:hover, textarea:focus {
  border:1px solid $green;
  box-shadow: none;
}

select:focus, select:active {
  outline: none;
  border:1px solid $green;
  box-shadow: none;
}

.Green__button {
  background:$green;
  text-transform: uppercase;
  color:#fff;
  border:transparent;
  padding:12px 30px;
  font-weight: 600;
  font-size: 14px;
}

.Green_button {
  background: $green;
  font-weight: 700;
  text-transform: uppercase;

  &:hover, &:active {
    background: $green;
  }
  &:focus {
    outline:none;
    background: $green;
  }
}

.Full_width {
  width: 100%;
}

.Long_width {
  padding-left: 80px;
  padding-right: 80px;
}

ul.submenu.menu.vertical.Submenu.is-dropdown-submenu.first-sub {
  background: $green;
  border: transparent;
}

li.has-submenu.is-dropdown-submenu-parent.opens-left.is-active {
  background: $green;
}