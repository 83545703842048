body {
  font-family: 'Open Sans', sans-serif;

  p,h1,h2,h3,h4,h5,h6,td,th, blockquote {
    color: #4b4a4a;
    font-family: 'Open Sans', sans-serif;
  }

  th {
    text-align: left;
  }

  p, td, th {
    font-size:13px;
  }

  h1 {
    font-size:36px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size:18px;
  }

  h4 {
    font-size: 16px;
  }

  h6 {
    text-transform: uppercase;
    color: #898989;
    font-size: 15px;
  }

  @media (min-width: 640px) {
    h1 {
      font-size:40px;
    }
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size:20px;
    }

    h4 {
      font-size: 18px;
    }
  }

  @media (min-width: 768px) {
    h1 {
      font-size:48px;
    }
    h2 {
      font-size: 40px;
    }
    h3 {
      font-size:28px;
    }

    h4 {
      font-size: 20px;
    }
  }

  .alert-box {
    padding: 15px;
    &.success {
      background: $green;
    }
  }

  .close {
    padding-left:20px;
  }

  .callout {
    margin: auto;
    border: none;
    color: $black;
    background-color: #fff;
  }
  .alert {
    z-index:10;
    position:fixed;
    top:80px;
    right:10px;
    border: none;
    color: $black;
  }

  .alert.ng-enter {
    opacity: 0;
    transition: opacity 1s ease-in;
  }

  .alert.ng-enter-active {
    opacity: 1;
  }

  .alert.ng-leave {
    opacity: 1;
    transition: opacity 1s ease-out;
  }
  .alert.ng-leave-active {
    opacity: 0;
  }

  @media (min-width: 992px) {
    h1 {
      font-size:56px;
    }
    h2 {
      font-size: 48px;
    }
    h3 {
      font-size:40px;
    }

    h4 {
      font-size: 28px;
    }
  }
}

.text-center {
  text-align: center;
}
