.reveal {
  .Login_icon {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 30px;

    span {
      img {
        width: 20%;
      }
    }

    h4 {
      color: $black;
      font-weight: 500;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
  .login-header, .login-body, .login-footer {
    padding-left: 40px;
    padding-right: 40px;
  }

  .login-body {
    .Login_button {
      width: 100%;
      background-color: $green;
      font-weight: 700;
      text-transform: uppercase;
    }

    .forgot-password {
      color: $green;
      font-size: 13px;
    }

    padding-bottom: 60px;
  }

  .login-footer {
    background: $light-gray;
    padding: 20px 30px;
    text-align: center;

    p {
      margin:0;
      font-size: 13px;
      font-weight: 700;

      a {
        color: $green;
      }
    }
  }

  .login-header {
    .close-button {
      color: #ccc;
    }
  }
}

.forgot-password {
  font-size: 13px;
  color: $green;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;

  &:hover {
    color:$green;
  }
}

