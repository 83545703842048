

  .an_event_row {
    overflow: hidden;
  }
  .an_event_form_content {

    padding-right: 68px;
    padding-left: 68px;

    input[type=text], input[type=email], input[type=password] {
      margin-bottom: 10px;
      font-size: 12px;
      height: 50px;
    }

  }

  .An_event {
    position: relative;
    top: 200px;
    z-index: 1;
    overflow: hidden;
    text-align: center;

    h1 {
      color: #fff;
      font-weight: 800;
      text-transform: uppercase;
    }

    h1:last-of-type {
      color: #ffffff;

      font-size:43px;
      margin-top: 14px;
      padding-left:70px;
    }

  }
  .an_event_title {
    position: absolute;
    bottom: 0;
    height: 90px;
    width: 100%;
    background-color: rgba(51, 51, 51, 0.5);
    left: 0;
    padding-right: 50px;
    padding-left: 50px;

    h1 {
      float: left;
      line-height: 3.0;
      color: $green;
      font-size: 35px;
      font-weight: 600;
      text-transform: capitalize;
    }
    a {

      line-height: 8.5;
      color: #ffffff

    }
    span {
      color:$green;;
      float: right;
      font-size: 14px;
      font-weight: 600;

    }

  }
  .an_event_details {
    padding-left: 50px;
    padding-right: 50px;

    .book_an_event {

      padding-top: 32px;
      padding-right: 0;

      a {
        float: right;
        text-transform: capitalize;
        font-weight: 600;
        text-decoration: none;
        color: $black;
        font-size: 12px;
      }

      .fa-bookmark {
        color: $green;
      }
    }

    .event_details_hr {
      h4 {
        text-transform: capitalize;
        font-style: italic;
        color: $gray;
        padding-left: 10px;
        font-size: 25px;
      }

    }
    .event_date_hr {
      h4 {
        text-transform: capitalize;
        font-style: italic;
        color: $gray;
        padding-left: 20px;
        padding-top: 10px;

        font-size: 16px;

      }
      hr {

      }

    }
    .event_details_text {
      padding-top: 25px;
      color: $black;
      font-size: 14px;
    }
    .event_date_text {
      padding-left: 20px;
      padding-top: 20px;
      font-size: 20px;
      font-weight: 600;
      color: $black;
      .title h4 {

        color: $gray;
        font-style: italic;
        font-size: 16px;
        padding-top: 10px;
        text-transform: capitalize;

      }

    }
    .an_event_content {
      padding-bottom: 20px;
    }

    .an_event_buttons {
      padding-bottom: 54px;
      padding-top: 34px;

      .previous_button {
        position: relative;
        margin-top: 18px;
        span {

          a {
            color: $green;
            font-weight: 600;
            text-transform: capitalize;
          }
          .fa-chevron-circle-left {
            color: $green;
          }
        }

      }
      .all_events_button {
        .button {
          width: 140px;
          height: 38px;
          background-color: #ffffff;
          border-color: $green;
          border-radius: 4px;
          border-width: 1px;
          line-height: 0.8;
          font-weight: 600;
          display: block;
          margin: 0 auto;
          color: #82ca9c;
          text-transform: capitalize;

        }
        .a {

        }

      }
      .next_button {
        span {
          margin-top: 18px;
          float: right;
          a {
            color: $green;
            font-weight: 600;
            text-transform: capitalize;
          }
          .fa-chevron-circle-right {
            color: $green;
          }
        }

      }
    }

  }


//small screens
  @media(max-width: 640px)
  {
    .an_event_details{

      .an_event_buttons {


        .previous_button {
          position: relative;

          span {


            a {
              color: $green;
              font-size: 12px;
              font-weight: 600;
              text-transform: capitalize;


            }
            .fa-chevron-circle-left {
              color: $green;
              font-size: 12px;
            }
          }

        }
        .all_events_button {
          .button {
            width:100px;
            font-size: 10px;
            height: 38px;
            background-color: #ffffff;
            border-color: $green;
            border-radius: 4px;
            border-width: 1px;
            line-height:1.6;
            font-weight: 600;
            display: block;
            //margin:0 auto;

            color: #82ca9c;
            text-transform: capitalize;

          }
          .a {

          }

        }
        .next_button {
          span {
            margin-top: 18px;
            float: right;
            //padding-left: 30px;


            a {
              color: $green;
              font-weight: 600;
              text-transform: capitalize;
              font-size: 12px;

            }
            .fa-chevron-circle-right {
              color: $green;
              font-size: 12px;
            }
          }

        }
      }
      .event_details_hr {
        h4 {
          text-transform: capitalize;
          font-style: italic;
          color: $gray;
          padding-left: 10px;
          font-size: 25px;
        }

      }
      .event_date_hr {
        h4 {
          text-transform: capitalize;
          font-style: italic;
          color: $gray;
          padding-left: 20px;
          padding-top: 10px;

          font-size: 16px;

        }
        hr {

        }

      }
      .event_details_text {
        padding-top: 25px;
        color: $black;
        font-size: 14px;
      }
      .event_date_text {
        padding-left: 20px;
        padding-top: 20px;
        font-size: 20px;
        font-weight: 600;
        color: $black;
        .title h4 {

          color: $gray;
          font-style: italic;
          font-size: 16px;
          padding-top: 10px;
          text-transform: capitalize;

        }

      }

    }

  }

