
.Members_section {
  padding-top:35px;
  padding-bottom:35px;

  .Member_row {
    margin-top:50px;
  }

  .Member_row:first-of-type {
    margin-top:25px;
  }
  .Member_row:last-of-type {
    margin-bottom:50px;
  }
  .Member_image {
    margin-top:10px;
    width:250px;
    padding-left: 20px;
  }

  .Title {
    padding-left:34px;
    font-size:22px;
    color:$gray;
  }

  .Horizontal__line {
    display: none;
  }

  @media (min-width: 640px) {
    .Horizontal__line {
      top: -75px;
      width: 90px;
      position: relative;
      left: 85%;
      border-top: 3px solid $green;
      display: block;
    }
  }

  @media (min-width: 1024px)  {
    .Horizontal__line {
      top: -115px;
    }
  }

  .Name {
    font-weight: 700;
    color:$black;
  }

  .Position {
    font-style: italic;
    color: $gray;
  }

  .Bio {
    p {
      font-size: 14px;
    }
  }

  a.Read-more {
    color:$green;
    font-size: 14px;
    font-weight: 800;
  }
  .fa {
    color:$green;
    font-size: 18px;
    font-weight: 800;
  }

  @media (min-width: 640px) {
      padding-top:70px;
      padding-bottom:70px;
    .Member_row {
      padding-left:75px;
    }
  }
}
