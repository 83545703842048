.img-responsive {

}

.img-circle {
  border-radius: 50%;
}

.no-js .top-bar {
  display: none;
}

@media screen and (min-width: 40em) {
  .no-js .top-bar {
    display: block;
  }

  .no-js .title-bar {
    display: none;
  }
}

.is-reveal-open {
  .body {
    -webkit-filter: url('#blur');
    filter: url('#blur');
    -webkit-filter: blur(5px);
    filter: blur(5px);
    background-size: cover;
    opacity: 0.9;
  }
}

.reveal-overlay {
  background: rgba(0,0,0,.6);


  .reveal {
    padding: 0;
    border: transparent;
    border-radius: 3px;

    &:focus {
      outline:none;
    }

    .reveal-header {
      padding: 20px;
      background: $green;
      * {
        color: #fff;
      }

      h5 {
        font-weight: 700;
        margin:0;
      }
    }

    .reveal-body {
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .reveal-footer {
      padding: 0 20px 20px;

      .submit-button {
        width: 100%;
        border-radius: 3px;
        background: $green;
        font-weight: 700;

        &:focus {
          outline:none;
        }
      }

      .close-button {
        top: 1.2rem;
        color:#fff;
      }
    }
  }
}

body {
  a, .button {
    &:focus {
      border-radius: 3px;
    }
  }
}