.orbit-slide {
  max-height: none!important;
}

.Events_header {
  height: auto;
}

.Events__Overlay {
  background: linear-gradient(120deg,rgba(62,73,82,.6),rgba(0,0,0,.2),rgba(0,0,0,.2),rgba(0,0,0,.2));;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Events_header {
  .Nav-row {
    padding-left:15px;
  }
  .Navs {
    position:absolute;
    top: 55%;

    .orbit-next, .orbit-previous {
      z-index: 2;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      border:1px solid #fff;
      line-height: 16px;

      &:focus {
        outline:none;
        color:#fff;
        background: rgba(0,0,0,.2);
      }

      &:hover {
        color:#fff;
        background: rgba(0,0,0,.2);
      }

      &:active {

      }
    }

    .orbit-next {
      left: auto;
      right: auto;
      margin-top: -60px;
    }
  }

  .orbit-container {
    .Mobile {
      display: block;
    }

    .Normal {
      display: none;
    }

    @media (min-width: 1024px) {
      .Mobile {
        display: none;
      }

      .Normal {
        display: block;
      }
    }
  }

}


.Events_Content {
  position: absolute;
  top: 15%;
  z-index: 1;
  padding-left: 100px;

  h1,h2,h3,h4 {
    color:#fff;
    margin-bottom: 0;
    letter-spacing: 6px;
  }


  h1 {
    margin-bottom:0;
    line-height: 48px;

  }

  p{
    color: #fff;
    margin-top:10px;
    font-size: 16px;
  }

  a {
    color:$green;
    border-bottom:2px solid $green;
    padding-bottom:5px;
    font-weight: 600;
  }

  @media (min-width: 480px) {
    top:25%;
  }

  @media (min-width: 640px) {
    top: 40%;
  }

  @media (min-width: 1024px) {
    top: 35%;
  }
}


.Event__buttons {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  bottom: 1%;
  text-align: center;

  .Event_button {
    border-radius: 2px;
    background-color: rgba(0,0,0,.3);
    text-transform: uppercase;
    border: 1px solid #fff;
    font-weight: 700;

    @media (min-width: 640px) {
      padding: 15px 30px;
    }
  }

  @media (min-width: 480px) {
    bottom: 40px;
  }

  @media (min-width: 640px) {
    bottom: 150px;
  }

  @media (min-width: 1115px) {
    bottom: 100px;
  }
}

.Search_section {
  padding-top:40px;
  padding-bottom:10px;

  .Search__row {
    border-bottom: 1px solid #d9d9d9;

    @media(min-width: 1024px) {
      .columns {
        padding-right:0;
        padding-left:0;
      }
    }
  }
}
.Events__Search {
  ul {
    list-style: none;
    margin-left:0;
    @media (min-width: 640px) {
      float: right;
    }

    li {
      display: inline-block;

      select {
        padding-left: 20px;
        border-radius: 3px;
        min-width: 110px;
      }

      .search-button {
        margin-top: 15px;
        border-radius: 3px;
        font-weight: 700;
        background: $green;
      }
    }
  }
}

.Event_upcoming {
  padding-bottom:50px;


  .Upcoming_row {
    border-bottom: 1px solid #d9d9d9;
    padding-top:50px;
    padding-bottom:70px;
  }

  .Event_Month {
    border-bottom: 2px solid $green;
    padding-bottom: 5px;
    width: 35px;
    margin:0;
    font-size: 20px;
  }

  .Event_Date {
    color:$green;
    font-weight: 700;
    font-size: 48px;
  }

  .Event_Image {
    position: relative;

    .button {
      margin: 0;
      position: absolute;
      bottom: -1px;
      background: $green;
      padding-right:30px;
      padding-left:30px;
      left:0;
    }
  }

  .Event_details {
    position: relative;
   .Header {
     h2 {
       color: $black;
       font-weight: 700;
     }

     p {
       color:$gray;
       font-weight: 500;
       font-size: 16px;
       line-height: 22px;
       margin:0;
     }

     p:last-of-type {
       margin-bottom:20px;
     }
   }

    p {

    }

    a {
      color:$green;
      font-weight: 700;
      position:absolute;
      bottom:-20px;
      i {
        font-weight: 800;
      }
    }

    @media (min-width: 640px) {
      a {
        bottom: 0;
      }
    }
  }
}

.Recent_Events {
  background-size: cover;
  background: url("/images/slider/img1.jpg") center;


  .Recent_Events__Overlay {
    background: $green-overlay;
    padding-top:40px;
    padding-bottom:40px;
  }
  .row {
    position: relative;

    .Recent__heading {
      padding-bottom: 50px;

      h4 {
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        margin: 0;
      }
    }

    .Recent__button {
      padding-top:100px;
      text-align: center;

      .button {
        border:1px solid $green;
        padding: 13px 30px;
        text-transform: uppercase;
        font-size: 14px;
        border-radius: 3px;
        background: transparent;
        font-weight: 500;
      }
    }

    .Carousel {
      width: 90%;
      overflow: hidden;
      margin-right: auto;
      margin-left: auto;

      .Recent_Event__Content  {
        padding:0 15px;
        .Content-section {
          padding-top:15px;
          background: $black;
          margin-right: auto;
          margin-left: auto;
          text-align: center;

          h4,p,a {
            color: #fff;
          }

          a {
            margin:0;
            width: 100%;
            background: $green;
            font-size: 13px;
            font-weight: 600;
            padding-top: 15px;
            padding-bottom: 15px;
          }

          h4 {
            margin-top:-4px;
            color:#fff;
            margin-bottom:25px;
            font-weight: 600;
            font-size: 26px;
          }
          p {
            margin:0;
            color:$gray;
            text-transform: uppercase;
          }
        }
      }

      .slick-slide:focus {
        outline: none;
      }

      .slick-prev, .slick-next {
        z-index:10;
        color:#fff;
        &:before {
          //color: $gray;
          font-size: 24px;
        }
      }

      .slick-prev {
        left: 25px;
      }
      .slick-next {
        right:25px;
      }

      .slick-slider {
        position: static;
      }
    }
  }
}

.More_Events__section {
  padding-bottom: 50px;

  .row {
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    .button.More_Events {
      border-radius: 3px;
      border: 1px solid $green;
      color: $green;
      font-weight: 700;
      background: transparent;
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}