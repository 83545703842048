.Full_Testimonial {
  .slick-list {
    overflow:hidden;
  }

  .slick-dots {
    text-align: right;
    padding: 15px;

    li {
      margin: 0;
    }
  }

  .Title {
    text-transform: none;
    font-size: 14px;
    font-style: italic;
  }

  &.slick-dotted.slick-slider {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.Video_Testimonials {
    padding-top: 50px;
    padding-bottom: 20px;
    background: #1b2733;
  .slick-dots li button::before {
    color: #fff;
    font-size: 10px;
  }

  .video-note {
    position: relative;
    p {
      color: #fff;
      text-transform: uppercase;
      font-size: 15px;
      margin-bottom: 0;
      margin-top: 5px;
    }

    h4 {
      color: $green;
      font-weight: 800;
      text-transform: uppercase;
      font-size: 34px;
    }

    h1 {
      font-size: 105px;
      color: #648970;
      margin-top: -32px;
    }
    hr {
      margin-top: 0;
      margin-bottom: 0;
      width: 30px;
      position: absolute;
      left:15px;
      border-bottom: 2px solid $green;
    }
  }
}

.no-padding {
  padding-right: 0;
  padding-left: 0;
}

.Text_Testimonials {
  padding-top: 50px;
  padding-bottom: 50px;

  .text {
    font-size: 13px;
    color: #9e9c9c;
    padding-top: 20px;
  }

  p {
    color: #9e9c9c;
  }

  .more {
    text-align: center;
    a {
      color: $green;
      background: #fff;
      border: 1px solid $green;
      padding-left: 30px;
      padding-right: 30px;
      text-transform: uppercase;
      margin-top: -50px;
      margin-bottom:0;
    }
  }

  @media (min-width: 40em) {
    .even {
      border-left: 1px solid #d1d1d1;
      padding-left: 60px;
    }
    .odd {
      padding-right: 60px;
    }
  }

  .even, .odd {
    margin-bottom: 100px;
    padding-bottom: 20px;
  }

  img {
    border-radius: 50%;
    border: 1px solid #d1d1d1;
    width: 75px;
  }

  .head-section {
    position:relative;
    margin-top: -5px;
    .title {
      position: absolute;
      text-align: center;
      left: 100px;

      &.heading {
        top: 5px;
        color:#1b2733;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 800;
      }
      &.name {
        top: -20px;
        color: $gray;
        font-size: 13px;
        font-weight: 600;
        right:0;
      }
    }
  }

  .icon {
    color: $green;
    margin-bottom: 0;
  }
}

.Testimonials_Form {
  background: #fbfcfb;
  padding-top: 70px;
  padding-bottom: 70px;

  .Green_button {
    padding-right: 40px;
    padding-left: 40px;
  }
}