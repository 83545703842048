.Corporate {
  position: absolute;
  top: 300px;
  z-index: 1;
  overflow: hidden;

  h1 {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 46px;
  }

  h3 {
    text-transform: uppercase;
    color: $green;
    font-weight: 300;
    line-height: 36px;

    .ifas {
      color: #fff;
    }
  }
}

.Corporate_header {
  //background: url("/images/contacts/ivy.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
}

.Partners_header {
  background-position: center;
  background-size: cover;
  position: relative;

  .Header_Overlay {
    background: linear-gradient(120deg, rgba(28, 41, 52, 0.8), rgba(28, 41, 52, 0.6), rgba(13, 107, 91, 0.5), rgba(13, 107, 91, 0.7));
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.Content_Section {
  padding-top: 60px;


  h6 {
    letter-spacing: 3px;
  }

  h4 {
    color: $black;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  h4:first-of-type {
    margin-top:0;
  }

  a {
    color: $green;
    font-weight: 700;
    font-size: 13px;
  }

  hr {
    width: 150px;
    margin-right: 0;
    margin-left: 0;
    position: relative;
    margin-top:10px;
    border-bottom: 2px solid $black;
  }

  ul {
    li {
      font-size: 13px;
    }
  }


  .tabs-panel {
    padding-left:0;
    padding-right:0;
  }

  .tabs {
    border:none;

    .tabs-title {
      a {
        text-transform: uppercase;
        margin-right: 20px;
        padding-top: 0;
        padding-left:0;
        padding-right:0;
        color: $black;
        background: #fff;
        padding-bottom:10px;


        &:hover {
          border-bottom: 2px solid $black;

        }
      }

      &.is-active {
        a {
          color:$green;
          border-bottom: 2px solid $green;
        }
      }
    }
  }

  .tabs-content {
    border:none;
  }

  .corporate-image {
    padding-top: 50px;
    .Col {
      padding-left: 0;
      padding-right:0;

      .Green {
        border-color: $green;
      }
    }
  }

  #panel2 {
    padding-bottom: 60px;
  }
}


@media (min-width: 640px) {
  .Corporate {
    padding-left: 80px;
  }

  .Corporate_header {
    height: 650px;
  }

  .Content_Section {
    .page-content {

      hr {
        left: -80px;
      }
    }
  }
}
