.Partner_body {
  padding-top: 80px;
  padding-bottom: 80px;

  .line-section {
    .h4 {
      color: $gray;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 600;
      margin-left: 15px;
      i.fa {
        color: $green;
        margin-right: 20px;
      }
    }

    hr {
      color: $gray;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  .Contacts {
    margin-top: 20px;
  }

  .fields-row {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.Ads {
  margin-top: 50px;
  height: 350px;
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 30px;
  box-shadow: 0 0 10px 10px #e3e3e3;
  margin-left: 30px;
}

.Partner_section {
  padding-top: 50px;
  padding-bottom: 50px;

  .Logo_image {
    height: 100px;
  }

  .h3 {
    color: $black;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 28px;
  }

  .Partner {
    border: 1px solid #e3e3e3;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    box-shadow: 0 0 15px #888888;
    min-height: 380px;

    .image {
      padding-bottom: 30px;
      filter: grayscale(1);
      transition-duration: .5s;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      &:hover {
        filter: none;
        transition-duration: .5s;
      }

    }

    .bio {
      font-size: 12px;
    }

    .link {
      font-size: 12px;
      text-align: right;
      padding-top: 30px;
      padding-bottom: 20px;
      a {
        color: $green;
        font-weight: 600;

        .fa-angle-right {
          font-weight: 800;
          font-size: 17px;
        }
      }
    }
  }
}

.Banner_section {
  .Banner {
    padding-bottom: 50px;
  }
}

.Partners_header {
  .partner-button {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $green;
    color: #fff;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 700;

    .fa-home {
      font-size: 20px;
    }
    .fa-angle-right {
      font-size: 16px;
      font-weight: 800;
      padding-left: 10px;
      border-left: 2px solid #82ca9c;
    }
  }
}

.Partner_Overview {
  .columns:first-of-type {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .Title {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    color: $gray;
  }

  .h4 {
    color: $black;
    margin-bottom: 30px;
    margin-top: 15px;
    font-size: 19px;
    font-weight: 700;
  }
}

.Product_overview {
  .Line {
    background: linear-gradient(90deg, $black, #fff);
    height: 1px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .Title {
    font-size: 15px;
    font-weight: 600;
    color: $black;
    min-height: 42px;
  }

  h4 {
    color: #192531;
    font-size: 19px;
    font-weight: 700;
  }

  .content {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}