@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,800,300italic,300,400italic,600,700,600italic,700italic);
.img-circle {
  border-radius: 50%; }

.no-js .top-bar {
  display: none; }

@media screen and (min-width: 40em) {
  .no-js .top-bar {
    display: block; }
  .no-js .title-bar {
    display: none; } }

.is-reveal-open .body {
  -webkit-filter: url("#blur");
  filter: url("#blur");
  -webkit-filter: blur(5px);
  filter: blur(5px);
  background-size: cover;
  opacity: 0.9; }

.reveal-overlay {
  background: rgba(0, 0, 0, 0.6); }
  .reveal-overlay .reveal {
    padding: 0;
    border: transparent;
    border-radius: 3px; }
    .reveal-overlay .reveal:focus {
      outline: none; }
    .reveal-overlay .reveal .reveal-header {
      padding: 20px;
      background: #82ca9c; }
      .reveal-overlay .reveal .reveal-header * {
        color: #fff; }
      .reveal-overlay .reveal .reveal-header h5 {
        font-weight: 700;
        margin: 0; }
    .reveal-overlay .reveal .reveal-body {
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px; }
    .reveal-overlay .reveal .reveal-footer {
      padding: 0 20px 20px; }
      .reveal-overlay .reveal .reveal-footer .submit-button {
        width: 100%;
        border-radius: 3px;
        background: #82ca9c;
        font-weight: 700; }
        .reveal-overlay .reveal .reveal-footer .submit-button:focus {
          outline: none; }
      .reveal-overlay .reveal .reveal-footer .close-button {
        top: 1.2rem;
        color: #fff; }

body a:focus, body .button:focus {
  border-radius: 3px; }

body {
  font-family: 'Open Sans', sans-serif; }
  body p, body h1, body h2, body h3, body h4, body h5, body h6, body td, body th, body blockquote {
    color: #4b4a4a;
    font-family: 'Open Sans', sans-serif; }
  body th {
    text-align: left; }
  body p, body td, body th {
    font-size: 13px; }
  body h1 {
    font-size: 36px; }
  body h2 {
    font-size: 24px; }
  body h3 {
    font-size: 18px; }
  body h4 {
    font-size: 16px; }
  body h6 {
    text-transform: uppercase;
    color: #898989;
    font-size: 15px; }
  @media (min-width: 640px) {
    body h1 {
      font-size: 40px; }
    body h2 {
      font-size: 28px; }
    body h3 {
      font-size: 20px; }
    body h4 {
      font-size: 18px; } }
  @media (min-width: 768px) {
    body h1 {
      font-size: 48px; }
    body h2 {
      font-size: 40px; }
    body h3 {
      font-size: 28px; }
    body h4 {
      font-size: 20px; } }
  body .alert-box {
    padding: 15px; }
    body .alert-box.success {
      background: #82ca9c; }
  body .close {
    padding-left: 20px; }
  body .callout {
    margin: auto;
    border: none;
    color: #003333;
    background-color: #fff; }
  body .alert {
    z-index: 10;
    position: fixed;
    top: 80px;
    right: 10px;
    border: none;
    color: #003333; }
  body .alert.ng-enter {
    opacity: 0;
    transition: opacity 1s ease-in; }
  body .alert.ng-enter-active {
    opacity: 1; }
  body .alert.ng-leave {
    opacity: 1;
    transition: opacity 1s ease-out; }
  body .alert.ng-leave-active {
    opacity: 0; }
  @media (min-width: 992px) {
    body h1 {
      font-size: 56px; }
    body h2 {
      font-size: 48px; }
    body h3 {
      font-size: 40px; }
    body h4 {
      font-size: 28px; } }

.text-center {
  text-align: center; }

.Header_Slider {
  margin-top: -20px;
  position: static;
  height: 620px; }
  .Header_Slider .Slider_images {
    position: absolute;
    top: -20px; }
    .Header_Slider .Slider_images div.slick-list.draggable {
      position: relative;
      top: -142px; }
  .Header_Slider .Header_Overlay {
    zoom: 1; }
    .Header_Slider .Header_Overlay .slick-slider {
      margin: 0;
      top: -142px; }
  .Header_Slider .Homepage__header {
    height: 600px; }

.Home_header {
  background-size: cover;
  background-position: center center;
  height: 550px !important; }

@media (min-width: 480px) {
  .Home_header {
    height: 670px !important; } }

.Menu {
  background: transparent; }
  .Menu .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a:after {
    border-color: #fff transparent transparent !important; }
  .Menu .Dropdown {
    background: #82ca9c; }
  .Menu .Menu_top {
    max-height: 88px;
    display: flex;
    justify-content: flex-end; }
    .Menu .Menu_top ul li {
      color: #fff; }
      .Menu .Menu_top ul li a {
        text-transform: none;
        color: #fff;
        font-size: 14px;
        font-weight: 300; }
  .Menu ul li a {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding-right: 10px;
    padding-left: 10px;
    font-weight: 600; }
    .Menu ul li a i {
      margin-right: 10px;
      font-size: 16px; }
  .Menu ul li a:focus {
    outline: none; }

.top-bar {
  background: transparent; }
  .top-bar ul {
    background: transparent; }

.Menu__top-bar {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: -0.1rem !important;
  background: #82ca9c;
  z-index: 3; }

.Top-bar__scroll {
  background: #82ca9c;
  transition: all linear .5s; }

.Fade-out {
  transition: all linear 2s;
  position: absolute;
  top: 0; }

.Menu__Logo {
  padding: 5px 0; }

.slide {
  transition: all linear 2s; }

.slide.ng-hide {
  position: absolute;
  top: 0; }

.slick-list {
  overflow: visible; }

.slick-slide img {
  background: #000; }

.slick-track:focus {
  outline: none; }

.Slider__text {
  backface-visibility: hidden;
  margin-top: 200px;
  position: relative;
  z-index: 4; }
  .Slider__text h1, .Slider__text h2, .Slider__text p {
    color: #fff; }
  .Slider__text a {
    color: #fff;
    font-weight: 800;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    font-size: 15px; }
  .Slider__text ul {
    margin-left: 0;
    margin-bottom: 20px;
    list-style: none; }
    .Slider__text ul li {
      padding-bottom: 10px; }
      .Slider__text ul li a {
        color: #fff;
        font-weight: 600;
        border-bottom: transparent;
        padding-bottom: 5px;
        font-size: 16px; }
        .Slider__text ul li a i {
          font-weight: 800;
          font-size: 18px;
          padding-right: 5px; }
        .Slider__text ul li a:hover {
          color: #82ca9c; }
    .Slider__text ul li:last-of-type a {
      color: #82ca9c; }

.Slider_image {
  background-size: cover !important;
  background-position: center;
  background-color: #82ca9c;
  height: 600px; }

.Slider__position {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.Diaspora_image {
  padding: 0;
  margin: 0;
  background-size: cover !important;
  background-position: center !important; }
  .Diaspora_image .Diaspora_background {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    min-height: 280px;
    padding: 45px 30px; }
    .Diaspora_image .Diaspora_background .Products_link {
      position: absolute;
      bottom: 30px; }

footer {
  background: linear-gradient(0deg, #0d6b5b, #1b2733);
  color: #fff;
  padding-top: 100px; }
  footer .website-links {
    padding-top: 50px;
    padding-bottom: 60px;
    text-align: center; }
    footer .website-links a {
      font-size: 13px;
      color: #fff; }
      footer .website-links a:hover {
        color: #82ca9c; }
    footer .website-links .separator {
      display: none; }
  @media (min-width: 480px) {
    footer .website-links {
      text-align: left; }
      footer .website-links .separator {
        display: inline; } }
  footer .Social {
    padding-top: 60px; }
    footer .Social ul {
      text-align: center;
      margin: 0; }
      footer .Social ul li {
        display: inline-block;
        padding-left: 25px;
        list-style: none; }
        footer .Social ul li a {
          color: #82ca9c; }
          footer .Social ul li a i {
            font-size: 22px; }
  footer .gray {
    color: #a0a09c; }
  footer .Get-in-touch {
    padding-bottom: 50px;
    padding-top: 20px; }
    footer .Get-in-touch p {
      margin-bottom: unset; }
  footer .subscribe-input {
    border: 1px solid transparent; }
    footer .subscribe-input input[type="text"] {
      border: transparent; }
    footer .subscribe-input input[type=text]:focus, footer .subscribe-input input[type=text]:active {
      border: transparent;
      outline: none; }
  footer .footer-list {
    margin-left: 0; }
    footer .footer-list li {
      list-style: none;
      font-size: 12px; }
      footer .footer-list li a {
        color: #fff; }
        footer .footer-list li a:hover {
          color: #82ca9c; }
    footer .footer-list li.first {
      margin-bottom: 15px;
      font-weight: 600; }
  footer .Footer__row .columns {
    padding: 0; }
  footer .Footer__row h5, footer .Footer__row p {
    color: #fff; }
  footer .Subscribe_col h5 {
    color: #fff; }
  footer .Subscribe_col input[type=text] {
    height: 50px;
    border-radius: 2px 0 0 2px; }
  footer .Subscribe_col .Button {
    background: #82ca9c;
    height: 50px;
    display: flex;
    justify-content: center;
    line-height: 50px;
    border-radius: 0 2px 2px 0; }
    footer .Subscribe_col .Button a {
      color: #fff;
      width: 100%;
      text-align: center; }
    footer .Subscribe_col .Button i {
      font-size: 28px; }
  footer ul.Link_cols {
    margin-left: 0; }
    footer ul.Link_cols .Horizontal-links {
      list-style: none;
      display: inline-block;
      padding-right: 35px; }

.Testimonials {
  padding-top: 90px;
  padding-bottom: 90px;
  position: relative; }
  .Testimonials .Title {
    margin-bottom: 30px; }
  .Testimonials .slick-list {
    overflow: hidden; }
  .Testimonials .Testimonial_identity {
    position: relative;
    left: 100px;
    top: -50px; }
    .Testimonials .Testimonial_identity p {
      margin-bottom: 5px;
      color: #999999; }
    .Testimonials .Testimonial_identity p:first-of-type {
      font-size: 16px; }
  @media (min-width: 992px) {
    .Testimonials .row {
      padding-left: 150px;
      padding-right: 150px; } }
  .Testimonials .row img {
    background-color: transparent; }
  .Testimonials .Para_small {
    font-size: 10px; }
  .Testimonials .slick-slide {
    background: #fff;
    outline: none; }
  .Testimonials .slick-dots {
    display: block;
    position: relative;
    top: 20px;
    margin-top: -20px; }
    .Testimonials .slick-dots li.slick-active button::before {
      color: #82ca9c; }
    .Testimonials .slick-dots li[aria-hidden="true"] {
      visibility: visible; }
    .Testimonials .slick-dots li {
      width: 5px;
      height: 5px; }
      .Testimonials .slick-dots li button::before {
        display: block;
        opacity: 1;
        font-size: 10px;
        color: #999999; }
      .Testimonials .slick-dots li button {
        display: block; }

.Green {
  color: #82ca9c; }

.Black {
  color: #003333; }

.Overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(29, 50, 68, 0.13); }

.Menu_desktop {
  display: none; }

ul.Submenu li:hover {
  background: #7abd92;
  transition-delay: 0s;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: ease; }
  ul.Submenu li:hover a {
    color: #222d32;
    transition-delay: 0s;
    transition-duration: .2s;
    transition-property: all;
    transition-timing-function: ease; }

li.has-submenu.is-dropdown-submenu-parent.opens-left.is-active {
  background: #7abd92;
  transition-delay: 0s;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: ease; }

.Linked_menu > ul > li:hover {
  background: #82ca9c;
  transition-delay: 0s;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: ease; }

.Menu_mobile {
  display: block;
  background: #82ca9c;
  z-index: 4; }
  .Menu_mobile ul.Submenu {
    background: #82ca9c; }
  .Menu_mobile .Menu__top-bar {
    padding-top: 15px;
    padding-bottom: 0; }
  .Menu_mobile .Menu__Logo img {
    height: 30px; }
  .Menu_mobile button.menu-icon {
    position: absolute;
    right: 15px;
    top: 45px; }
    .Menu_mobile button.menu-icon:focus {
      outline: none; }

.Menu__Logo.menu > li > a img {
  max-width: 140px; }

.Home__Feature-text {
  font-size: 32px; }

@media (min-width: 480px) {
  .Home__Feature-text {
    font-size: 42px; } }

.title-bar {
  background: #82ca9c;
  padding-top: 20px;
  padding-bottom: 20px; }

.Small__buttons {
  display: block; }

.Large__buttons {
  display: none; }

.User__menu {
  padding-left: 0;
  display: none; }

@media (min-width: 640px) {
  .User__menu {
    display: block; }
  .Header_Slider {
    height: 650px; }
  .Menu_desktop {
    display: block; }
  .Menu_top {
    display: block; }
  .Menu_mobile {
    display: none; }
  .Menu__top-bar {
    background: transparent;
    transition: background linear .5s,padding linear .5s; }
  .Top-bar__scroll {
    transition: padding linear .5s;
    background: #82ca9c; }
  .Homepage__header {
    height: 790px; }
  .Slider_image {
    height: 650px; }
  footer .Social {
    padding-top: 115px; }
    footer .Social ul {
      text-align: right; }
  .User__menu {
    padding-left: 40px; }
  .Linked_menu {
    margin-top: -7px; } }

@media (min-width: 380px) {
  .Small__buttons {
    display: none; }
  .Large__buttons {
    display: block; } }

blockquote {
  border-left: transparent;
  margin-bottom: 30px;
  color: #999999; }
  blockquote i.fa {
    font-size: 20px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
    color: #999999; }

.Testimonial__border {
  width: 85%;
  margin: 20px auto; }

.Testimonial__person {
  padding: 0 8%; }

#responsive-menu .is-drilldown-submenu-parent > a:after {
  border-color: transparent transparent transparent #fff; }

#responsive-menu .js-drilldown-back > a:before {
  border-color: transparent #fff transparent transparent; }

.Medium_header {
  height: 540px;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 5; }
  .Medium_header .Header_Overlay {
    background: rgba(13, 107, 91, 0.36);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .Medium_header .Header_Overlay .Menu__top-bar {
      z-index: 5; }

.Faq_header {
  height: 310px;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 5; }
  .Faq_header .Header_Overlay {
    background: rgba(13, 107, 91, 0.36);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .Faq_header .Header_Overlay .Menu__top-bar {
      z-index: 5; }

.NewsHeader {
  height: 310px;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 5; }
  .NewsHeader .Header_Overlay {
    background: linear-gradient(120deg, rgba(28, 41, 52, 0.8), rgba(28, 41, 52, 0.8), rgba(13, 107, 91, 0.8), rgba(13, 107, 91, 0.8));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.BlogsHeader {
  height: 310px;
  background: url("/images/blogs/blog.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 5; }
  .BlogsHeader .Header_Overlay {
    background: linear-gradient(120deg, rgba(28, 41, 52, 0.8), rgba(28, 41, 52, 0.8), rgba(13, 107, 91, 0.8), rgba(13, 107, 91, 0.8));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.TestimonialsHeader {
  height: 310px;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 5; }
  .TestimonialsHeader .Header_Overlay {
    background: linear-gradient(120deg, rgba(28, 41, 52, 0.8), rgba(28, 41, 52, 0.8), rgba(13, 107, 91, 0.8), rgba(13, 107, 91, 0.8));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.an_event_header {
  height: 620px;
  background: url("/images/contacts/ivy.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 5; }
  .an_event_header .Header_Overlay {
    background: rgba(13, 107, 91, 0.36);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .an_event_header .Header_Overlay .Menu__top-bar {
      z-index: 5; }

.Greenish_Overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.36); }

.Small_header {
  background: linear-gradient(120deg, #3e4952, #3e4952, #3e4952, #318173, #318173, #318173);
  height: 350px;
  position: relative;
  background-position: center;
  background-size: cover; }
  .Small_header .Header_Overlay {
    background: rgba(13, 107, 91, 0.36);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.About__Slider_text {
  margin-top: 300px; }

.Small_header .Small_column {
  position: absolute;
  bottom: 25%;
  left: 10%; }
  .Small_header .Small_column h1, .Small_header .Small_column h2, .Small_header .Small_column h3, .Small_header .Small_column h4 {
    color: #fff;
    margin: 0; }
  .Small_header .Small_column.Staff_column {
    bottom: 175px; }

.Name, .Position, .Bio, .Read-more {
  padding-left: 30px; }

.Member_image {
  margin-bottom: 20px; }

@media (min-width: 640px) {
  .Medium_header {
    height: 680px; }
  .Events_header {
    height: auto; }
    .Events_header .Menu {
      height: auto !important; }
  .Name, .Position, .Bio, .Read-more {
    padding-left: 0; }
  .Member_image {
    margin-bottom: 0; }
  .Small_header {
    height: 459px; }
    .Small_header .Small_column {
      padding-left: 75px;
      position: absolute;
      bottom: 115px;
      z-index: 0; }
      .Small_header .Small_column h2, .Small_header .Small_column h3, .Small_header .Small_column h4 {
        color: #fff; } }

.Dark_Overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4); }

.Short_Header {
  background-size: cover;
  background-position: 50%;
  height: 400px !important; }
  .Short_Header .Short_Header__text {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .Short_Header .Short_Header__text .Short_Header__text__content {
      color: white !important; }
      .Short_Header .Short_Header__text .Short_Header__text__content p {
        color: white !important; }

.Custom-cms-header {
  margin-top: 1rem; }
  .Custom-cms-header h1, .Custom-cms-header h2, .Custom-cms-header h3 {
    color: white; }

.Horizontal__line {
  border-top: 2px solid #82ca9c;
  width: 200px; }

.Text__image {
  position: relative; }
  .Text__image .Text__image_large {
    background: #fff;
    padding-top: 100px;
    padding-bottom: 100px; }
    .Text__image .Text__image_large h2 {
      color: #003333;
      font-weight: 800; }
    .Text__image .Text__image_large p {
      margin-top: 50px; }
      .Text__image .Text__image_large p a {
        color: #82ca9c;
        font-weight: 800; }
      .Text__image .Text__image_large p i {
        font-weight: 800; }
  .Text__image .Text__image_small .Horizontal__line {
    position: relative;
    left: -150px;
    top: 250px; }
  .Text__image .Text__image_small h3 {
    color: #fff;
    font-weight: 800; }
  .Text__image .Text__image_small h4 {
    color: #fff; }
  .Text__image .Text__image__right {
    padding: 50px 0;
    min-height: 300px; }

.About__Text_image {
  background: #fff;
  margin-top: 20px;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
  display: block;
  overflow: hidden; }

.About_team .About_team__Text {
  background: #82ca9c;
  min-height: 300px;
  top: -300px;
  padding-top: 50px;
  padding-right: 50px;
  padding-left: 50px; }
  .About_team .About_team__Text p {
    color: #fff; }
  .About_team .About_team__Text h3 {
    color: #003333;
    text-transform: uppercase;
    font-weight: 500; }
  .About_team .About_team__Text a {
    color: #003333;
    border-bottom: 1px solid #003333;
    padding-bottom: 5px; }

.Inspiring-section .Inspiring_image {
  margin-top: -16px;
  margin-bottom: -16px;
  padding: 0; }
  .Inspiring-section .Inspiring_image .img-inspiring {
    width: 50%; }
  @media (min-width: 1024px) {
    .Inspiring-section .Inspiring_image .img-inspiring {
      width: 82%; } }
  @media (min-width: 1143px) {
    .Inspiring-section .Inspiring_image .img-inspiring {
      width: 75.9%; } }
  @media (min-width: 1200px) {
    .Inspiring-section .Inspiring_image .img-inspiring {
      width: 72%; } }

.Inspiring-section .Inspiring_text {
  padding-top: 100px;
  padding-bottom: 100px; }
  .Inspiring-section .Inspiring_text .Horizontal__line {
    display: none; }
  .Inspiring-section .Inspiring_text h2 {
    color: #003333;
    font-weight: 800; }
  .Inspiring-section .Inspiring_text p:first-of-type {
    margin-top: 30px;
    margin-bottom: 30px; }
  .Inspiring-section .Inspiring_text p a {
    color: #82ca9c;
    font-weight: 800; }
  .Inspiring-section .Inspiring_text p i {
    font-weight: 800; }

.Events_section {
  margin-top: -65px;
  background: #fff; }
  .Events_section .Events_background {
    background-size: 70%;
    background-position: right;
    background-repeat: no-repeat; }
    .Events_section .Events_background .Events__image {
      padding: 0; }
      .Events_section .Events_background .Events__image img {
        display: block; }
    .Events_section .Events_background .Events_text {
      padding-top: 90px;
      padding-bottom: 90px;
      background: #82ca9c; }
      .Events_section .Events_background .Events_text h6 {
        color: #fff; }
      .Events_section .Events_background .Events_text h2 {
        color: #003333;
        font-weight: 800; }
      .Events_section .Events_background .Events_text p:first-of-type {
        margin-top: 20px;
        margin-bottom: 20px; }
      .Events_section .Events_background .Events_text p:last-of-type {
        margin-bottom: 50px; }
      .Events_section .Events_background .Events_text p {
        color: #fff; }
        .Events_section .Events_background .Events_text p a {
          color: #003333;
          font-weight: 800; }
        .Events_section .Events_background .Events_text p i {
          font-weight: 800; }

@media (min-width: 992px) {
  .Inspiring-section {
    margin-top: -300px; }
  .About_team .About_team__Text {
    position: relative;
    width: 50%;
    background: #82ca9c;
    height: 300px;
    top: -300px; } }

@media (min-width: 1024px) {
  .Inspiring-section .Inspiring_text .Horizontal__line {
    position: relative;
    left: -140px;
    display: block; }
  .Events_section {
    background: linear-gradient(120deg, #82ca9c, #82ca9c, #82ca9c, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); }
    .Events_section .Events_background {
      background-size: 72%;
      background-repeat: no-repeat;
      background-position: right; }
      .Events_section .Events_background .Events__image img {
        display: none; }
  .Text__image .Text__image__right {
    position: relative;
    padding-top: 0;
    padding-bottom: 0; }
  .Text__image .Text__image_large {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 200px; }
    .Text__image .Text__image_large h2 {
      color: #003333;
      font-weight: 800; }
    .Text__image .Text__image_large p {
      margin-top: 50px; }
      .Text__image .Text__image_large p a {
        color: #82ca9c;
        font-weight: 800; }
      .Text__image .Text__image_large p i {
        font-weight: 800; }
  .Text__image .Text__image_small {
    padding-top: 100px;
    padding-bottom: 100px; }
    .Text__image .Text__image_small img {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0; }
    .Text__image .Text__image_small h3 {
      color: #fff;
      font-weight: 800; }
    .Text__image .Text__image_small h4 {
      color: #fff; }
  .About__Text_image {
    margin-top: 20px;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 36%;
    display: block; } }

@media (min-width: 1440px) {
  .Text__image .Text__image__right {
    right: -25%; } }

.Members_section {
  padding-top: 35px;
  padding-bottom: 35px; }
  .Members_section .Member_row {
    margin-top: 50px; }
  .Members_section .Member_row:first-of-type {
    margin-top: 25px; }
  .Members_section .Member_row:last-of-type {
    margin-bottom: 50px; }
  .Members_section .Member_image {
    margin-top: 10px;
    width: 250px;
    padding-left: 20px; }
  .Members_section .Title {
    padding-left: 34px;
    font-size: 22px;
    color: #999999; }
  .Members_section .Horizontal__line {
    display: none; }
  @media (min-width: 640px) {
    .Members_section .Horizontal__line {
      top: -75px;
      width: 90px;
      position: relative;
      left: 85%;
      border-top: 3px solid #82ca9c;
      display: block; } }
  @media (min-width: 1024px) {
    .Members_section .Horizontal__line {
      top: -115px; } }
  .Members_section .Name {
    font-weight: 700;
    color: #003333; }
  .Members_section .Position {
    font-style: italic;
    color: #999999; }
  .Members_section .Bio p {
    font-size: 14px; }
  .Members_section a.Read-more {
    color: #82ca9c;
    font-size: 14px;
    font-weight: 800; }
  .Members_section .fa {
    color: #82ca9c;
    font-size: 18px;
    font-weight: 800; }
  @media (min-width: 640px) {
    .Members_section {
      padding-top: 70px;
      padding-bottom: 70px; }
      .Members_section .Member_row {
        padding-left: 75px; } }

.orbit-slide {
  max-height: none !important; }

.Events_header {
  height: auto; }

.Events__Overlay {
  background: linear-gradient(120deg, rgba(62, 73, 82, 0.6), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.Events_header .Nav-row {
  padding-left: 15px; }

.Events_header .Navs {
  position: absolute;
  top: 55%; }
  .Events_header .Navs .orbit-next, .Events_header .Navs .orbit-previous {
    z-index: 2;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    line-height: 16px; }
    .Events_header .Navs .orbit-next:focus, .Events_header .Navs .orbit-previous:focus {
      outline: none;
      color: #fff;
      background: rgba(0, 0, 0, 0.2); }
    .Events_header .Navs .orbit-next:hover, .Events_header .Navs .orbit-previous:hover {
      color: #fff;
      background: rgba(0, 0, 0, 0.2); }
  .Events_header .Navs .orbit-next {
    left: auto;
    right: auto;
    margin-top: -60px; }

.Events_header .orbit-container .Mobile {
  display: block; }

.Events_header .orbit-container .Normal {
  display: none; }

@media (min-width: 1024px) {
  .Events_header .orbit-container .Mobile {
    display: none; }
  .Events_header .orbit-container .Normal {
    display: block; } }

.Events_Content {
  position: absolute;
  top: 15%;
  z-index: 1;
  padding-left: 100px; }
  .Events_Content h1, .Events_Content h2, .Events_Content h3, .Events_Content h4 {
    color: #fff;
    margin-bottom: 0;
    letter-spacing: 6px; }
  .Events_Content h1 {
    margin-bottom: 0;
    line-height: 48px; }
  .Events_Content p {
    color: #fff;
    margin-top: 10px;
    font-size: 16px; }
  .Events_Content a {
    color: #82ca9c;
    border-bottom: 2px solid #82ca9c;
    padding-bottom: 5px;
    font-weight: 600; }
  @media (min-width: 480px) {
    .Events_Content {
      top: 25%; } }
  @media (min-width: 640px) {
    .Events_Content {
      top: 40%; } }
  @media (min-width: 1024px) {
    .Events_Content {
      top: 35%; } }

.Event__buttons {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  bottom: 1%;
  text-align: center; }
  .Event__buttons .Event_button {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    border: 1px solid #fff;
    font-weight: 700; }
    @media (min-width: 640px) {
      .Event__buttons .Event_button {
        padding: 15px 30px; } }
  @media (min-width: 480px) {
    .Event__buttons {
      bottom: 40px; } }
  @media (min-width: 640px) {
    .Event__buttons {
      bottom: 150px; } }
  @media (min-width: 1115px) {
    .Event__buttons {
      bottom: 100px; } }

.Search_section {
  padding-top: 40px;
  padding-bottom: 10px; }
  .Search_section .Search__row {
    border-bottom: 1px solid #d9d9d9; }
    @media (min-width: 1024px) {
      .Search_section .Search__row .columns {
        padding-right: 0;
        padding-left: 0; } }

.Events__Search ul {
  list-style: none;
  margin-left: 0; }
  @media (min-width: 640px) {
    .Events__Search ul {
      float: right; } }
  .Events__Search ul li {
    display: inline-block; }
    .Events__Search ul li select {
      padding-left: 20px;
      border-radius: 3px;
      min-width: 110px; }
    .Events__Search ul li .search-button {
      margin-top: 15px;
      border-radius: 3px;
      font-weight: 700;
      background: #82ca9c; }

.Event_upcoming {
  padding-bottom: 50px; }
  .Event_upcoming .Upcoming_row {
    border-bottom: 1px solid #d9d9d9;
    padding-top: 50px;
    padding-bottom: 70px; }
  .Event_upcoming .Event_Month {
    border-bottom: 2px solid #82ca9c;
    padding-bottom: 5px;
    width: 35px;
    margin: 0;
    font-size: 20px; }
  .Event_upcoming .Event_Date {
    color: #82ca9c;
    font-weight: 700;
    font-size: 48px; }
  .Event_upcoming .Event_Image {
    position: relative; }
    .Event_upcoming .Event_Image .button {
      margin: 0;
      position: absolute;
      bottom: -1px;
      background: #82ca9c;
      padding-right: 30px;
      padding-left: 30px;
      left: 0; }
  .Event_upcoming .Event_details {
    position: relative; }
    .Event_upcoming .Event_details .Header h2 {
      color: #003333;
      font-weight: 700; }
    .Event_upcoming .Event_details .Header p {
      color: #999999;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      margin: 0; }
    .Event_upcoming .Event_details .Header p:last-of-type {
      margin-bottom: 20px; }
    .Event_upcoming .Event_details a {
      color: #82ca9c;
      font-weight: 700;
      position: absolute;
      bottom: -20px; }
      .Event_upcoming .Event_details a i {
        font-weight: 800; }
    @media (min-width: 640px) {
      .Event_upcoming .Event_details a {
        bottom: 0; } }

.Recent_Events {
  background-size: cover;
  background: url("/images/slider/img1.jpg") center; }
  .Recent_Events .Recent_Events__Overlay {
    background: rgba(13, 107, 91, 0.7);
    padding-top: 40px;
    padding-bottom: 40px; }
  .Recent_Events .row {
    position: relative; }
    .Recent_Events .row .Recent__heading {
      padding-bottom: 50px; }
      .Recent_Events .row .Recent__heading h4 {
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        margin: 0; }
    .Recent_Events .row .Recent__button {
      padding-top: 100px;
      text-align: center; }
      .Recent_Events .row .Recent__button .button {
        border: 1px solid #82ca9c;
        padding: 13px 30px;
        text-transform: uppercase;
        font-size: 14px;
        border-radius: 3px;
        background: transparent;
        font-weight: 500; }
    .Recent_Events .row .Carousel {
      width: 90%;
      overflow: hidden;
      margin-right: auto;
      margin-left: auto; }
      .Recent_Events .row .Carousel .Recent_Event__Content {
        padding: 0 15px; }
        .Recent_Events .row .Carousel .Recent_Event__Content .Content-section {
          padding-top: 15px;
          background: #003333;
          margin-right: auto;
          margin-left: auto;
          text-align: center; }
          .Recent_Events .row .Carousel .Recent_Event__Content .Content-section h4, .Recent_Events .row .Carousel .Recent_Event__Content .Content-section p, .Recent_Events .row .Carousel .Recent_Event__Content .Content-section a {
            color: #fff; }
          .Recent_Events .row .Carousel .Recent_Event__Content .Content-section a {
            margin: 0;
            width: 100%;
            background: #82ca9c;
            font-size: 13px;
            font-weight: 600;
            padding-top: 15px;
            padding-bottom: 15px; }
          .Recent_Events .row .Carousel .Recent_Event__Content .Content-section h4 {
            margin-top: -4px;
            color: #fff;
            margin-bottom: 25px;
            font-weight: 600;
            font-size: 26px; }
          .Recent_Events .row .Carousel .Recent_Event__Content .Content-section p {
            margin: 0;
            color: #999999;
            text-transform: uppercase; }
      .Recent_Events .row .Carousel .slick-slide:focus {
        outline: none; }
      .Recent_Events .row .Carousel .slick-prev, .Recent_Events .row .Carousel .slick-next {
        z-index: 10;
        color: #fff; }
        .Recent_Events .row .Carousel .slick-prev:before, .Recent_Events .row .Carousel .slick-next:before {
          font-size: 24px; }
      .Recent_Events .row .Carousel .slick-prev {
        left: 25px; }
      .Recent_Events .row .Carousel .slick-next {
        right: 25px; }
      .Recent_Events .row .Carousel .slick-slider {
        position: static; }

.More_Events__section {
  padding-bottom: 50px; }
  .More_Events__section .row {
    margin-right: auto;
    margin-left: auto;
    text-align: center; }
    .More_Events__section .row .button.More_Events {
      border-radius: 3px;
      border: 1px solid #82ca9c;
      color: #82ca9c;
      font-weight: 700;
      background: transparent;
      padding-right: 30px;
      padding-left: 30px; }

.Register_header {
  height: 310px;
  background: linear-gradient(120deg, #3e4952, #3e4952, #318173, #318173);
  background-size: cover;
  position: relative;
  z-index: 5; }
  .Register_header h1, .Register_header h2, .Register_header h3, .Register_header h4, .Register_header h5, .Register_header h6, .Register_header p, .Register_header td, .Register_header th, .Register_header a {
    font-family: 'Open Sans', sans-serif; }

.Register_column {
  position: absolute;
  top: 200px;
  z-index: 1;
  overflow: hidden; }
  @media (min-width: 640px) {
    .Register_column {
      left: 60px; } }
  .Register_column h3 {
    color: #fff;
    font-weight: 300;
    margin-top: 0;
    text-transform: uppercase; }

.Register_form {
  padding-top: 35px;
  padding-bottom: 50px; }
  .Register_form .input {
    position: relative;
    display: block; }
    .Register_form .input .fa {
      color: #999999; }
    .Register_form .input .one_time_key {
      padding-left: 30px;
      padding-right: 30px;
      border: none;
      margin-bottom: 50px;
      border-bottom: 1px solid #999999; }
    .Register_form .input i.first-icon {
      top: 11px;
      position: absolute;
      left: 2px; }
    .Register_form .input i.last-icon {
      top: 11px;
      position: absolute;
      right: 2px;
      color: #82ca9c; }
  @media (min-width: 640px) {
    .Register_form {
      padding-top: 30px;
      padding-bottom: 100px; }
      .Register_form .Code_columns {
        padding-right: 0;
        padding-left: 0; }
        .Register_form .Code_columns .Title {
          font-size: 34px; }
      .Register_form .Form_columns {
        padding-right: 150px;
        padding-left: 30px; }
        .Register_form .Form_columns .call-para {
          margin-top: 20px;
          margin-bottom: 40px;
          font-weight: 600;
          color: #999999; } }
  .Register_form h1, .Register_form h2, .Register_form h3, .Register_form h4, .Register_form h5, .Register_form h6, .Register_form p, .Register_form td, .Register_form th, .Register_form a {
    font-family: 'Open Sans', sans-serif; }
  .Register_form .Title {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #003333; }
  .Register_form .Icon {
    text-align: center; }
    .Register_form .Icon img {
      width: 80px; }
  .Register_form .Form .firstname {
    padding-left: 0;
    padding-right: 0; }
  .Register_form .Form .lastname {
    padding-right: 0;
    padding-left: 0; }
  @media (min-width: 640px) {
    .Register_form .Form .firstname {
      padding-right: 15px; }
    .Register_form .Form .lastname {
      padding-left: 15px; }
    .Register_form .Form .left {
      padding-left: 0; }
    .Register_form .Form .right {
      padding-right: 0; }
    .Register_form .Form .both {
      padding-left: 0;
      padding-right: 0; } }
  .Register_form .Form input[type=text], .Register_form .Form input[type=email], .Register_form .Form input[type=password], .Register_form .Form select, .Register_form .Form textarea {
    border-radius: 2px;
    margin-bottom: 30px;
    height: 48px; }
  .Register_form .Form .Register_button {
    width: 100%;
    border-radius: 2px;
    background: #82ca9c;
    padding-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 20px; }
  .Register_form .Form .Terms {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px; }
    .Register_form .Form .Terms p {
      margin: 0; }
  .Register_form .Form .Call_background {
    background: #eeedf1;
    border-radius: 2px; }
    .Register_form .Form .Call_background p.heavy {
      margin: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
      font-weight: 700; }
      .Register_form .Form .Call_background p.heavy a {
        color: #82ca9c; }

input[type=text], input[type=email], input[type=password], input[type=time], input[type=number], select, textarea {
  border-radius: 1px;
  box-shadow: none;
  padding-left: 20px;
  border: 1px solid #E1E1E1;
  font-size: 13px; }

input[type=file] {
  font-size: 13px;
  padding-left: 20px;
  border-radius: 1px;
  box-shadow: none;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px dotted #39484d; }

.nv-file-over {
  background-color: #FFBEA3; }

.dropzone-area {
  font-size: 13px;
  padding-left: 20px;
  border-radius: 1px;
  box-shadow: none;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px dashed #39484d; }

input[type=text]:focus, input[type=number]:focus, input[type=email]:focus, input[type=password]:focus, textarea, input[type=time] {
  outline: #82ca9c;
  box-shadow: none; }

input[type=text]:active, input[type=text]:focus, input[type=number]:focus, input[type=email]:active, input[type=email]:focus, input[type=password]:active, input[type=number]:active, input[type=password]:focus, textarea:active, input[type=time]:active, input[type=time]:focus, input[type=time]:hover, input[type=number]:hover, textarea:focus {
  border: 1px solid #82ca9c;
  box-shadow: none; }

select:focus, select:active {
  outline: none;
  border: 1px solid #82ca9c;
  box-shadow: none; }

.Green__button {
  background: #82ca9c;
  text-transform: uppercase;
  color: #fff;
  border: transparent;
  padding: 12px 30px;
  font-weight: 600;
  font-size: 14px; }

.Green_button {
  background: #82ca9c;
  font-weight: 700;
  text-transform: uppercase; }
  .Green_button:hover, .Green_button:active {
    background: #82ca9c; }
  .Green_button:focus {
    outline: none;
    background: #82ca9c; }

.Full_width {
  width: 100%; }

.Long_width {
  padding-left: 80px;
  padding-right: 80px; }

ul.submenu.menu.vertical.Submenu.is-dropdown-submenu.first-sub {
  background: #82ca9c;
  border: transparent; }

li.has-submenu.is-dropdown-submenu-parent.opens-left.is-active {
  background: #82ca9c; }

.Plus {
  display: none;
  font-size: 18px;
  height: 72px;
  line-height: 32px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transform: matrix(1, 0, 0, 1, 0, 0);
  width: 72px;
  transition: all .5s ease; }
  .Plus .corner {
    background: #82ca9c none scroll;
    color: #fff;
    font-size: 18px;
    height: 125px;
    line-height: 32px;
    padding: 40px 4px;
    position: absolute;
    right: -5px;
    top: -50px;
    transform: matrix(0.70711, -0.70711, 0.70711, 0.70711, 0, 0);
    width: 46px; }
    .Plus .corner i {
      color: #fff;
      font-size: 16px;
      height: 34px;
      line-height: 16px;
      margin-right: 4px;
      padding: 20px 20px 14px 14px;
      text-align: left;
      transform: matrix(0.70711, 0.70711, -0.70711, 0.70711, 0, 0);
      width: 34px; }

.Real_Estate_Slider_Text {
  margin-top: 300px; }

.RE_section {
  padding-top: 40px;
  padding-bottom: 40px; }
  .RE_section .Amenity {
    max-width: 350px;
    margin: 20px auto; }
  .RE_section .small-header {
    text-transform: none;
    letter-spacing: 3px; }
  .RE_section .larger-header {
    font-weight: 700;
    color: #003333; }
  .RE_section .Content_Column {
    padding-top: 20px;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto; }
    .RE_section .Content_Column .Amenities_Rule {
      width: 120px;
      border-bottom: 2px solid #82ca9c;
      position: relative;
      left: -30px;
      margin-right: 0;
      margin-left: 0; }
  .RE_section .Amenity .Content_section {
    border: 1px solid #fbfbfb;
    background: #fff;
    box-shadow: 0 1px 3px 1px #c2c2c2;
    height: 220px;
    transition: all .5s ease;
    padding: 20px; }
    .RE_section .Amenity .Content_section .content {
      overflow: hidden; }
    .RE_section .Amenity .Content_section h5 {
      color: #82ca9c;
      text-transform: uppercase;
      font-size: 16px;
      margin-top: 15px;
      margin-bottom: 15px;
      font-weight: 600; }
    .RE_section .Amenity .Content_section .learn a {
      display: none;
      color: #82ca9c;
      padding-bottom: 5px;
      font-size: 13px;
      width: 75px;
      font-weight: 600;
      border-bottom: 1px solid #82ca9c; }
    .RE_section .Amenity .Content_section .Icon {
      margin-top: -40px;
      transition: all .5s ease; }
      .RE_section .Amenity .Content_section .Icon .fa-stack {
        font-size: 20px;
        color: #82ca9c;
        transition: all .5s ease; }
  .RE_section .Amenity .Image-section {
    position: relative;
    border: 1px solid #ececec;
    box-shadow: 0 1px 3px 1px #ececec; }
    .RE_section .Amenity .Image-section .Image {
      height: 250px;
      transition: all .5s ease; }
  .RE_section .Amenity:hover .Plus {
    display: block;
    transition: all .5s ease; }
  .RE_section .Amenity:hover .Image {
    height: 200px;
    transition: all .5s ease; }
  .RE_section .Amenity:hover .Content_section {
    height: 270px;
    transition: all .5s ease; }
    .RE_section .Amenity:hover .Content_section .learn a {
      display: block; }
    .RE_section .Amenity:hover .Content_section .Icon {
      margin-top: -50px;
      transition: all .5s ease; }
      .RE_section .Amenity:hover .Content_section .Icon .fa-stack {
        font-size: 30px;
        color: #82ca9c;
        transition: all .5s ease; }
  .RE_section .RE_Link {
    color: #82ca9c;
    font-size: 13px;
    font-weight: 600; }
    .RE_section .RE_Link .fa-angle-right {
      font-weight: 800; }

.Gray_RE_section {
  background: #eeedf1; }

.RE_About_Section {
  background-size: cover;
  background-position: center center; }
  .RE_About_Section .Explore {
    text-transform: none;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    font-style: italic;
    margin-bottom: 30px; }
  .RE_About_Section .Block_Title, .RE_About_Section .Block_Title h2 {
    font-weight: 800;
    color: #fff;
    line-height: 48px;
    margin-bottom: 30px; }
    .RE_About_Section .Block_Title a, .RE_About_Section .Block_Title h2 a {
      color: white;
      border-bottom: 2px solid #82ca9c; }
  .RE_About_Section .About {
    font-size: 16px;
    color: #fff;
    width: 200px;
    font-weight: 700;
    border-bottom: 2px solid #82ca9c;
    padding-bottom: 5px; }

.RE_About_Section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-position: center center; }

.Column_Centered {
  margin-right: auto;
  margin-left: auto; }

.Italic_Para p {
  font-size: 15px;
  font-style: italic;
  color: #58082f; }

.RE_row .content-section a {
  color: #82ca9c;
  border-color: #82ca9c; }

@media (min-width: 1024px) {
  .Italic_Para {
    padding-left: 115px; }
  .RE_section {
    padding-top: 200px;
    padding-bottom: 200px; }
    .RE_section .RE_row {
      margin-bottom: -300px;
      padding-right: 100px;
      padding-left: 100px; }
    .RE_section .Single_RE_row {
      padding-right: 100px;
      padding-left: 100px; }
  .Single_RE_section {
    padding-top: 80px;
    padding-bottom: 80px; }
  .First_RE_Section {
    padding-top: 100px; }
  .Middle_Section {
    padding-top: 40px;
    padding-bottom: 0; }
  .RE_About_Section {
    padding-top: 200px;
    padding-bottom: 150px;
    background-size: cover;
    background-position: center center; } }

.Great_Section {
  padding-top: 40px;
  padding-bottom: 40px; }
  .Great_Section .small-header {
    letter-spacing: 3px;
    color: #999999; }
  .Great_Section .larger-header {
    font-weight: 700;
    color: #003333; }
  .Great_Section .Great_Rule {
    width: 150px;
    border-bottom: 2px solid #82ca9c;
    position: relative;
    left: -60px;
    margin-right: 0;
    margin-left: 0; }
  .Great_Section p.Link {
    margin-bottom: 6px; }
    .Great_Section p.Link .RE_Link {
      color: #82ca9c;
      font-size: 13px;
      font-weight: 600; }
      .Great_Section p.Link .RE_Link .fa-angle-right {
        font-weight: 800; }

.larger-header {
  font-weight: 700;
  color: #003333; }

.small-header {
  letter-spacing: 3px;
  color: #999999; }

.has-error {
  border: 1px solid red; }

.real-estate-link {
  color: #82ca9c;
  font-weight: 600;
  transition: all .5s ease; }
  .real-estate-link:hover {
    color: #003333;
    transition: all .5s ease; }
  .real-estate-link .fa {
    font-size: 16px; }
  .real-estate-link .fa-angle-right {
    font-weight: 800; }

.video-image {
  background-size: cover;
  text-align: center; }
  .video-image .video {
    height: 600px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center; }
    .video-image .video .fa {
      color: #fff;
      font-size: 84px; }
      .video-image .video .fa:hover {
        color: #82ca9c; }

.Explore_Section {
  padding-top: 40px;
  position: relative; }
  .Explore_Section .larger-header {
    width: 300px; }
  .Explore_Section .Green_Rule {
    border-bottom: 2px solid #82ca9c;
    width: 150px;
    position: relative;
    margin-right: 0;
    margin-left: 0;
    left: -50px; }
  .Explore_Section .Calculator {
    right: 0;
    top: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 200px;
    background: #58082f; }
    .Explore_Section .Calculator .Calculator_Content {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 60px; }
      .Explore_Section .Calculator .Calculator_Content .First, .Explore_Section .Calculator .Calculator_Content .Last {
        padding-left: 0;
        padding-right: 0; }
      .Explore_Section .Calculator .Calculator_Content #rate, .Explore_Section .Calculator .Calculator_Content #period, .Explore_Section .Calculator .Calculator_Content #principal, .Explore_Section .Calculator .Calculator_Content #downpayment {
        max-width: 74px;
        display: inline-block;
        border-radius: 2px 0 0 2px;
        margin-bottom: 10px;
        padding-left: 5px;
        padding-right: 5px; }
      .Explore_Section .Calculator .Calculator_Content #output {
        max-width: 310px; }
      .Explore_Section .Calculator .Calculator_Content .field-label {
        background: #fff;
        display: inline-block;
        padding: 10px 8px;
        margin-left: -4px;
        border-radius: 0 2px 2px 0;
        font-size: 12px;
        border-top: 1px solid #fff; }
      .Explore_Section .Calculator .Calculator_Content .Developer {
        color: #82ca9c;
        font-weight: 600;
        text-align: center;
        font-size: 18px; }
      .Explore_Section .Calculator .Calculator_Content .Dev_Logo {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 50px; }
        .Explore_Section .Calculator .Calculator_Content .Dev_Logo img {
          max-width: 110px; }
    .Explore_Section .Calculator .Header {
      max-width: 230px;
      margin-bottom: 30px;
      border-bottom: 1px solid #999999; }
      .Explore_Section .Calculator .Header h5 {
        font-weight: 700;
        color: #82ca9c; }
    .Explore_Section .Calculator label {
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 10px; }
    .Explore_Section .Calculator .button {
      background: #82ca9c;
      padding: 10px 40px;
      font-weight: 600;
      border-radius: 3px; }
    .Explore_Section .Calculator .Logo {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      background: #eeedf1;
      padding-top: 17px;
      padding-bottom: 17px; }
      .Explore_Section .Calculator .Logo img {
        max-width: 125px; }

@media (min-width: 480px) {
  .Explore_Section .Calculator .Calculator_Content {
    padding-right: 100px;
    padding-left: 100px; } }

.Image_Section {
  position: relative;
  border: 1px solid #f3f3f3;
  box-shadow: 0 1px 3px 1px #f3f3f3; }

@media (min-width: 1024px) {
  .Great_Section {
    padding-top: 80px;
    padding-bottom: 80px; }
  .Calculator {
    position: absolute; }
  .First_Column {
    padding-left: 50px;
    padding-bottom: 50px;
    height: 470px; }
  .Explore_Section {
    padding-top: 80px;
    padding-bottom: 80px; } }

.Slogan {
  padding-left: 0; }

.Diaspora_background {
  border: none; }
  .Diaspora_background * {
    color: #fff; }
  .Diaspora_background a:hover, .Diaspora_background a:active {
    color: #82ca9c; }
    .Diaspora_background a:hover i, .Diaspora_background a:active i {
      color: #82ca9c; }
  .Diaspora_background a:focus {
    color: #82ca9c;
    outline: none; }
    .Diaspora_background a:focus i {
      color: #82ca9c; }

.Home__body h1, .Home__body h2, .Home__body h3, .Home__body h4, .Home__body h5, .Home__body h6 {
  font-weight: 600; }

.Welcome__section {
  padding-top: 50px;
  padding-bottom: 50px; }
  .Welcome__section h1, .Welcome__section h2 {
    color: #999999; }
  .Welcome__section h2 {
    font-weight: 300; }

.Home_Overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 530px;
  background: rgba(0, 0, 0, 0.3); }

@media (min-width: 480px) {
  .Home_Overlay {
    height: 650px; } }

.Homepage_Content {
  position: absolute;
  top: 300px;
  z-index: 1;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto; }
  .Homepage_Content h2 {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 42px; }
  .Homepage_Content h4 {
    font-weight: 300; }
  .Homepage_Content h1, .Homepage_Content h2, .Homepage_Content h3, .Homepage_Content h4 {
    color: #fff;
    margin-bottom: 0; }
  .Homepage_Content h1 {
    margin-bottom: 0;
    line-height: 48px; }
  .Homepage_Content p {
    color: #fff;
    margin-top: 10px;
    font-size: 16px; }

.Events_header.Home_header .Navs {
  position: absolute;
  top: 45%; }

.Home__buttons .Home_button {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  border: 1px solid #fff;
  font-weight: 700;
  z-index: 2;
  font-size: 16px;
  margin: auto 10px; }
  .Home__buttons .Home_button:hover {
    background: rgba(0, 0, 0, 0.7); }
  .Home__buttons .Home_button.second {
    margin-top: 10px; }

@media (min-width: 640px) {
  .Home__buttons .Home_button {
    padding: 20px 30px; } }

@media (min-width: 344px) {
  .Home__buttons .Home_button.second {
    margin-top: 0; } }

.Home__buttons {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  bottom: -100px;
  text-align: center; }

.Products_title h4 {
  font-weight: 300; }

.Finance_Header {
  position: relative; }
  .Finance_Header .Finance_Action {
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 14px;
    margin: 0;
    padding: 15px 40px;
    background: #82ca9c; }
    .Finance_Header .Finance_Action i {
      font-size: 18px;
      font-weight: 800;
      padding-left: 10px; }

.Product__Intro .Heading h4 {
  font-weight: 700;
  color: #003333; }

.Product__Intro .Heading h6 {
  text-transform: none;
  letter-spacing: 3px; }

.Product__Intro .Heading .Small__Rule {
  width: 170px;
  border-bottom: 2px solid #003333;
  margin-left: 0; }

.Product__Intro .Content * {
  color: #003333; }

.Our_Rates .Heading h4 {
  font-weight: 700;
  color: #003333; }

.Our_Rates .Rates ul.menu .is-accordion-submenu-parent > a:after {
  border-color: #999999 transparent transparent; }

.Our_Rates .Rates ul.menu li a:active, .Our_Rates .Rates ul.menu li a:focus {
  background: #82ca9c;
  color: #fff;
  outline: none;
  border: none; }

.Our_Rates .Rates ul.menu li a {
  padding: 20px;
  color: #82ca9c;
  border-radius: 0;
  border: 1px solid #dbdbdb;
  margin-bottom: 6px;
  font-weight: 600;
  font-style: italic;
  font-size: 14px; }

.Our_Rates .Rates ul.menu li .Content {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb; }

.Our_Rates .Goal_Calculator {
  padding: 30px 20px;
  background: #003333; }
  .Our_Rates .Goal_Calculator label {
    color: #fff; }
  .Our_Rates .Goal_Calculator .Inputs .columns:first-of-type {
    padding-left: 0; }
  .Our_Rates .Goal_Calculator .Inputs .columns:nth-of-type(2) {
    padding-right: 0; }
  .Our_Rates .Goal_Calculator .Title {
    color: #82ca9c; }
  .Our_Rates .Goal_Calculator .Calculate {
    background: #82ca9c;
    padding: 10px 40px;
    border-radius: 2px; }

.Product_Rates {
  padding-top: 40px;
  padding-bottom: 40px; }
  .Product_Rates .Black_Container {
    background: #003333;
    padding: 30px 30px 15px;
    border-radius: 3px; }
    .Product_Rates .Black_Container .Rate_Title {
      color: #999999;
      font-size: 24px;
      text-align: center;
      margin-top: 30px; }
    .Product_Rates .Black_Container .Invest_Call {
      margin: 0;
      text-align: center;
      color: #999999; }
    .Product_Rates .Black_Container .White_Rule {
      margin-bottom: 15px; }
    .Product_Rates .Black_Container .Date_Title {
      text-align: center;
      color: #82ca9c;
      font-size: 36px;
      margin-top: 30px;
      margin-bottom: 30px; }
    .Product_Rates .Black_Container .Padding {
      padding-right: 20px;
      padding-left: 20px; }
  .Product_Rates .Current_Rates .rzslider .rz-bar {
    height: 2px; }
  .Product_Rates .Current_Rates .rzslider {
    margin-bottom: 50px; }
    .Product_Rates .Current_Rates .rzslider .rz-bar.rz-selection {
      background: #82ca9c; }
    .Product_Rates .Current_Rates .rzslider .rz-pointer {
      top: -12.5px;
      z-index: 3;
      width: 25px;
      height: 25px;
      background: #003333; }
      .Product_Rates .Current_Rates .rzslider .rz-pointer:after {
        top: 9px;
        left: 9px;
        background: #003333; }
      .Product_Rates .Current_Rates .rzslider .rz-pointer:focus {
        outline: none;
        background: #003333; }
  .Product_Rates .Current_Rates h6 {
    color: #999999;
    text-align: center; }
  .Product_Rates .Current_Rates h4 {
    color: #82ca9c;
    text-transform: uppercase;
    font-size: 28px;
    text-align: center; }
  .Product_Rates .Current_Rates #rate {
    background: #fff;
    border: none; }
    .Product_Rates .Current_Rates #rate:focus {
      outline: none; }
  .Product_Rates .Current_Rates .slider {
    height: 2px; }
    .Product_Rates .Current_Rates .slider .slider-fill {
      height: 2px;
      background: #82ca9c; }
    .Product_Rates .Current_Rates .slider .slider-handle {
      border-radius: 50%;
      background: #003333; }
    .Product_Rates .Current_Rates .slider .slider-handle:focus {
      outline: none; }
  @media (min-width: 640px) {
    .Product_Rates .Current_Rates .Ksh_Rate {
      padding-right: 0;
      padding-left: 30px; }
    .Product_Rates .Current_Rates .Usd_Rate {
      padding-left: 0;
      padding-right: 30px;
      margin-bottom: 0; } }
  .Product_Rates .Current_Rates .Usd_Rate {
    margin-bottom: 40px; }
  .Product_Rates .Current_Rates .Rate {
    background: #82ca9c;
    padding: 16px;
    border-radius: 2px; }
    .Product_Rates .Current_Rates .Rate p {
      text-align: center;
      font-size: 16px;
      color: #fff;
      margin-bottom: 0; }
    .Product_Rates .Current_Rates .Rate h6 {
      font-size: 15px;
      color: #fff;
      font-weight: 600; }
    .Product_Rates .Current_Rates .Rate .rate {
      color: #003333;
      text-align: center;
      line-height: 28px; }
      .Product_Rates .Current_Rates .Rate .rate .symbol {
        font-size: 14px;
        top: -20px; }

.Governance {
  padding-top: 40px;
  padding-bottom: 40px; }
  .Governance .Gov_Title {
    color: #003333;
    font-weight: 600;
    margin-bottom: 20px; }
    .Governance .Gov_Title p {
      color: #4b4a4a; }
  .Governance ul.menu .is-accordion-submenu-parent > a:after {
    border-color: #82ca9c transparent transparent; }
  .Governance ul.menu li a:active, .Governance ul.menu li a:focus {
    background: #003333;
    color: #fff;
    outline: none;
    border: none; }
  .Governance ul.menu li a {
    border-radius: 0;
    padding: 25px 20px;
    color: #82ca9c;
    border: 1px solid #dbdbdb;
    margin-bottom: 6px;
    font-weight: 600;
    font-style: italic;
    font-size: 14px; }
  .Governance ul.menu li .Content {
    padding-top: 15px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #dbdbdb; }
    .Governance ul.menu li .Content p:last-of-type {
      margin-bottom: 0; }

@media (min-width: 640px) {
  .Product_Rates {
    padding-top: 80px;
    padding-bottom: 80px; }
  .Governance {
    padding-top: 0;
    padding-bottom: 80px; }
    .Governance .Governance_Content {
      padding-right: 60px; }
      .Governance .Governance_Content ul li {
        font-size: 13px;
        padding-bottom: 10px;
        color: #4b4a4a; } }

.Big_Text {
  line-height: 46px;
  font-size: 42px;
  font-weight: 600; }

.Small_Text {
  line-height: 42px;
  color: #fff; }
  .Small_Text h3 {
    color: #fff; }

.Product_Slider_Text {
  margin-top: 350px; }

.Slider__text .Green_Link {
  color: #82ca9c;
  border-bottom: 1px solid #82ca9c; }

.Slider__position .Finance_Action {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  background: #82ca9c;
  font-weight: 800; }
  .Slider__position .Finance_Action .fa-home {
    font-size: 20px; }
  .Slider__position .Finance_Action .fa-angle-right {
    font-size: 16px;
    font-weight: 800;
    padding-left: 10px;
    border-left: 2px solid #82ca9c; }

.finance_para {
  font-size: 16px; }
  .finance_para p {
    font-size: 16px; }
  .finance_para a {
    color: #82ca9c;
    border-color: #82ca9c; }

.Product_Highlights {
  padding-bottom: 20px; }
  .Product_Highlights .Highlights .Product {
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px; }
    .Product_Highlights .Highlights .Product img {
      min-height: 350px; }
    .Product_Highlights .Highlights .Product .Content_section {
      border: 1px solid #eeedf1;
      padding: 30px 30px;
      background: #fff;
      min-height: 260px;
      overflow-wrap: break-word; }
      .Product_Highlights .Highlights .Product .Content_section h5 {
        color: #82ca9c; }
      .Product_Highlights .Highlights .Product .Content_section a {
        color: #82ca9c;
        font-size: 13px;
        font-weight: 600;
        border-bottom: 1px solid #82ca9c;
        padding-bottom: 3px; }
      .Product_Highlights .Highlights .Product .Content_section p {
        font-weight: 400; }
  .Product_Highlights .Highlights_Heading {
    background: #82ca9c;
    padding-top: 50px;
    padding-bottom: 40px; }
    .Product_Highlights .Highlights_Heading .Highlights_Rule {
      width: 150px;
      float: left;
      border-bottom: 1px solid #003333; }
    .Product_Highlights .Highlights_Heading .small-header {
      text-transform: none;
      letter-spacing: 3px;
      color: #eeedf1; }
    .Product_Highlights .Highlights_Heading .bigger-header {
      color: #003333;
      font-weight: 600; }

@media (min-width: 640px) {
  .Product_Slider {
    position: relative; }
  .Product_Highlights .Highlights {
    padding-left: 80px;
    padding-right: 80px;
    margin-top: -200px; }
    .Product_Highlights .Highlights .Product {
      margin-top: 0;
      margin-bottom: 0; }
  .Product_Highlights .Highlights_Heading {
    padding-bottom: 200px; }
    .Product_Highlights .Highlights_Heading .heading {
      padding-left: 80px; } }

.Product_Rates.General_Rates {
  background: #fff; }
  .Product_Rates.General_Rates .Relative_Row {
    position: relative; }
  .Product_Rates.General_Rates .Current_Rates .Products {
    padding-left: 5px; }
  .Product_Rates.General_Rates .Current_Rates .Rate {
    background: #fff;
    border: 1px solid #82ca9c;
    border-radius: 0; }
    .Product_Rates.General_Rates .Current_Rates .Rate h6 {
      color: #999999; }
    .Product_Rates.General_Rates .Current_Rates .Rate p {
      color: #999999; }
    .Product_Rates.General_Rates .Current_Rates .Rate .rate {
      color: #82ca9c; }
  .Product_Rates.General_Rates .Holder {
    position: relative;
    margin-top: 50px; }
    .Product_Rates.General_Rates .Holder .Black_Container .Padding .Rate_Title {
      margin-top: 10px; }
    .Product_Rates.General_Rates .Holder .Black_Container .Padding .Date_Title {
      margin-top: 10px;
      margin-bottom: 10px; }
  @media (min-width: 640px) {
    .Product_Rates.General_Rates .Holder {
      padding-left: 50px;
      position: absolute;
      bottom: 40px; } }

.Signup_Section {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #fbfbfb; }
  .Signup_Section .Signup_Call {
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    color: #82ca9c; }
  .Signup_Section .Sign_Para {
    color: #999999;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 14px; }
  .Signup_Section .Sign_button {
    background: #82ca9c;
    padding-right: 50px;
    padding-left: 50px;
    border-radius: 2px; }
  .Signup_Section .Signup_Content {
    margin-right: auto;
    margin-left: auto; }

.Slider__position .Finance_Action {
  font-weight: 700; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.About__text_image-history {
  margin-top: 0; }

.orbit-container {
  height: 100% !important; }
  .orbit-container:focus {
    outline: none; }
  .orbit-container .Normal {
    display: none; }
  .orbit-container .Mobile {
    display: block; }
  @media (min-width: 640px) {
    .orbit-container .Normal {
      display: block; }
    .orbit-container .Mobile {
      display: none; } }
  .orbit-container .Content {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #82ca9c;
    padding: 50px 50px 90px 50px;
    z-index: 3; }
    .orbit-container .Content h1, .orbit-container .Content h2, .orbit-container .Content h3, .orbit-container .Content h4, .orbit-container .Content h5, .orbit-container .Content h6, .orbit-container .Content p {
      color: #fff; }
      .orbit-container .Content h1.Title, .orbit-container .Content h2.Title, .orbit-container .Content h3.Title, .orbit-container .Content h4.Title, .orbit-container .Content h5.Title, .orbit-container .Content h6.Title, .orbit-container .Content p.Title {
        font-weight: 800; }

.Values_section .Values_row .orbit-bullets {
  position: absolute;
  bottom: 0;
  margin-top: .8rem;
  margin-bottom: .8rem;
  text-align: center;
  z-index: 5;
  padding-left: 50px; }
  .Values_section .Values_row .orbit-bullets button {
    width: 10px;
    height: 10px;
    background-color: #fff; }
    .Values_section .Values_row .orbit-bullets button:focus {
      outline: none; }
    .Values_section .Values_row .orbit-bullets button:hover {
      background-color: #82ca9c; }
    .Values_section .Values_row .orbit-bullets button.is-active {
      background-color: #003333; }
    @media (min-width: 640px) {
      .Values_section .Values_row .orbit-bullets button.is-active {
        background-color: #82ca9c; } }

.Values_section .Values_row .Navs {
  position: relative;
  bottom: 70px;
  right: auto;
  margin-right: auto;
  margin-left: auto;
  left: 0; }

.Values_section .Values_row .orbit-previous {
  left: 0;
  margin-left: 50px; }

.Values_section .Values_row .orbit-next {
  left: 50px;
  margin-left: 60px; }

.Values_section .Values_row .orbit-next, .Values_section .Values_row .orbit-previous {
  position: absolute;
  color: #fefefe;
  width: 50px;
  height: 50px;
  padding: 13px 14px;
  border-radius: 50%;
  border: 1px solid #fff;
  bottom: 0;
  z-index: 3; }
  .Values_section .Values_row .orbit-next .fa, .Values_section .Values_row .orbit-previous .fa {
    font-size: 23px; }
  .Values_section .Values_row .orbit-next:focus, .Values_section .Values_row .orbit-previous:focus {
    outline: none; }

@media (min-width: 992px) {
  .Values_section .Values_row .orbit-bullets {
    padding-left: 100px; }
  .Values_section .Values_row .orbit-previous {
    left: 0;
    margin-left: 100px; }
  .Values_section .Values_row .orbit-next {
    left: 100px; }
  .Values_section .Values_row .Content {
    padding: 80px 50px 140px 100px;
    position: absolute;
    bottom: 0; }
  .Values_section .Values_row .Navs {
    bottom: 100px;
    margin-left: auto;
    left: 0; } }

.Value_list__section {
  padding-top: 30px;
  padding-bottom: 30px; }
  .Value_list__section h3 {
    color: #82ca9c; }
    .Value_list__section h3.Title {
      font-weight: 800; }
  .Value_list__section .Value_list {
    list-style: none;
    margin-left: 0;
    color: #82ca9c;
    font-weight: 600; }
    .Value_list__section .Value_list li {
      margin-bottom: 10px; }
      .Value_list__section .Value_list li .fa {
        font-weight: 800; }
  @media (min-width: 640px) {
    .Value_list__section {
      padding-top: 50px;
      padding-bottom: 50px; } }

.Contact-us {
  position: absolute;
  top: 300px;
  z-index: 1;
  overflow: hidden; }
  .Contact-us h1 {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase; }
  .Contact-us h1:last-of-type {
    color: #82ca9c;
    font-weight: 200;
    margin-top: -20px; }

.Contact-row {
  overflow: hidden; }

@media (min-width: 640px) {
  .Contact-us {
    padding-left: 200px; } }

.Contact__Form {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #fdfdfd; }
  .Contact__Form .Contact_call {
    margin-bottom: 40px; }
  .Contact__Form .Address .columns {
    margin-bottom: 20px; }
  .Contact__Form input[type=text], .Contact__Form input[type=email], .Contact__Form input[type=password], .Contact__Form select, .Contact__Form textarea {
    margin-bottom: 6px;
    font-size: 12px; }
  .Contact__Form .Top_heading {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    padding-top: 15px; }
  .Contact__Form .Top__border {
    border-top: 2px solid #82ca9c;
    width: 40px; }
  .Contact__Form .Address__section .Address__title h5 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-top: 8px; }
  .Contact__Form .Address__section .Address__title .Email {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 5px; }
  .Contact__Form .Address__section .State__title h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0; }
  .Contact__Form .Address__section p {
    font-size: 14px;
    margin-bottom: 0; }
    .Contact__Form .Address__section p a {
      color: #4b4a4a; }
  .Contact__Form .Phone__section .Social {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 5px; }
    .Contact__Form .Phone__section .Social ul {
      list-style: none;
      margin-left: 0;
      margin-top: 15px;
      margin-bottom: 15px; }
      .Contact__Form .Phone__section .Social ul li {
        display: inline-block;
        font-size: 16px;
        padding-right: 5px;
        padding-left: 5px; }
        .Contact__Form .Phone__section .Social ul li a {
          color: #4b4a4a; }
          .Contact__Form .Phone__section .Social ul li a:hover {
            color: #82ca9c; }
  .Contact__Form .Phone__section .Phone__title h5 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-top: 8px;
    margin-bottom: 0; }
  .Contact__Form .Phone__section p {
    font-size: 14px;
    margin-bottom: 0; }
  .Contact__Form .Bottom__heading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 15px; }
  .Contact__Form .Contact__details .columns {
    padding: 0; }

.Top_line {
  border-top: 2px solid #82ca9c;
  width: 40px; }

.Hidden_Overflow {
  overflow: hidden; }

.Faq {
  position: absolute;
  top: 200px;
  z-index: 1;
  overflow: hidden; }
  .Faq h1 {
    color: #82ca9c;
    font-weight: 200;
    margin-top: -10px;
    text-transform: uppercase;
    font-size: 30px;
    padding-left: 60px; }

.News {
  top: 150px; }
  .News h2 {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700; }

.Faq__Form {
  padding: 40px 100px 20px;
  background: #fdfdfd; }
  .Faq__Form input[type=text], .Faq__Form input[type=email], .Faq__Form input[type=password], .Faq__Form select, .Faq__Form textarea {
    margin-bottom: 10px;
    font-size: 12px;
    background-color: #ffffff; }
  .Faq__Form .Top_heading {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    padding-top: 15px; }
  .Faq__Form .Top__border {
    border-top: 2px solid #82ca9c;
    width: 25px; }
  .Faq__Form .Bottom__heading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 15px; }
  .Faq__Form .faq-search-row {
    margin-top: 35px; }
  .Faq__Form .faq-titles-row {
    padding-bottom: 80px; }
  .Faq__Form .faq-search-button {
    height: 30px;
    width: 60px;
    border-radius: 0 2px 2px 0;
    background-color: #82ca9c;
    box-shadow: 0px 2px 1px 0 #ccc;
    border: #82ca9c;
    font-size: 7px;
    line-height: 2.5; }
  .Faq__Form .search {
    width: 207px;
    border: none;
    border-radius: 2px 0 0 2px;
    height: 30px;
    box-shadow: 1px 2px 2px 0 #ccc;
    float: right; }
  .Faq__Form .faq-box {
    width: 60px;
    height: 60px;
    background-color: #82ca9c; }
    .Faq__Form .faq-box p {
      font-size: 30px;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      vertical-align: middle;
      line-height: 60px; }
  .Faq__Form .question {
    text-transform: Uppercase;
    color: #003333;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 14px;
    border-top: 1px; }
  .Faq__Form .answer {
    font-size: 14px;
    margin-bottom: 30px; }
  .Faq__Form .faq-questions {
    margin-top: -48px;
    margin-left: 90px;
    padding-bottom: 20px; }
  .Faq__Form .faq-green-button {
    width: 145px;
    height: 48px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 132px;
    background-color: #82ca9c; }
  .Faq__Form .faq-hr hr {
    width: 100%;
    max-width: 100%;
    border-color: #dbdbdb; }
  .Faq__Form .faq-btn {
    text-align: center;
    padding-top: 35px;
    padding-bottom: 35px;
    margin: 0 auto; }
    .Faq__Form .faq-btn .ask-button {
      background-color: #82ca9c;
      max-width: 350px;
      width: 100%;
      height: 50px;
      line-height: 25px;
      text-transform: uppercase;
      font-weight: 600; }
  .Faq__Form .faq-form-content input[type=text], .Faq__Form .faq-form-content input[type=email], .Faq__Form .faq-form-content input[type=password] {
    margin-bottom: 10px;
    font-size: 12px;
    height: 50px; }
  .Faq__Form .Faq-row {
    overflow: hidden; }

@media (max-width: 640px) {
  .Faq__Form {
    padding: 0;
    margin: 0; }
  .Faq h1 {
    padding: 0; }
  .faq-search-row {
    margin-top: 35px; }
  .faq-titles {
    padding-top: 50px; }
  .faq-search-button {
    height: 30px;
    width: 60px;
    border-radius: 0 2px 2px 0;
    background-color: #82ca9c;
    box-shadow: 0px 2px 1px 0 #ccc;
    border: #82ca9c;
    font-size: 7px;
    line-height: 2.5; }
  .search {
    width: 207px;
    border: none;
    border-radius: 2px 0 0 2px;
    height: 30px;
    box-shadow: 1px 2px 2px 0 #ccc;
    float: right; }
  .faq-questions {
    margin-top: -48px;
    margin-left: 90px;
    margin-right: -71px;
    padding-bottom: 20px; }
  .Faq__Form .faq-green-button {
    width: 100%; } }

.faq-section {
  padding-right: 80px; }

.an_event_row {
  overflow: hidden; }

.an_event_form_content {
  padding-right: 68px;
  padding-left: 68px; }
  .an_event_form_content input[type=text], .an_event_form_content input[type=email], .an_event_form_content input[type=password] {
    margin-bottom: 10px;
    font-size: 12px;
    height: 50px; }

.An_event {
  position: relative;
  top: 200px;
  z-index: 1;
  overflow: hidden;
  text-align: center; }
  .An_event h1 {
    color: #fff;
    font-weight: 800;
    text-transform: uppercase; }
  .An_event h1:last-of-type {
    color: #ffffff;
    font-size: 43px;
    margin-top: 14px;
    padding-left: 70px; }

.an_event_title {
  position: absolute;
  bottom: 0;
  height: 90px;
  width: 100%;
  background-color: rgba(51, 51, 51, 0.5);
  left: 0;
  padding-right: 50px;
  padding-left: 50px; }
  .an_event_title h1 {
    float: left;
    line-height: 3.0;
    color: #82ca9c;
    font-size: 35px;
    font-weight: 600;
    text-transform: capitalize; }
  .an_event_title a {
    line-height: 8.5;
    color: #ffffff; }
  .an_event_title span {
    color: #82ca9c;
    float: right;
    font-size: 14px;
    font-weight: 600; }

.an_event_details {
  padding-left: 50px;
  padding-right: 50px; }
  .an_event_details .book_an_event {
    padding-top: 32px;
    padding-right: 0; }
    .an_event_details .book_an_event a {
      float: right;
      text-transform: capitalize;
      font-weight: 600;
      text-decoration: none;
      color: #003333;
      font-size: 12px; }
    .an_event_details .book_an_event .fa-bookmark {
      color: #82ca9c; }
  .an_event_details .event_details_hr h4 {
    text-transform: capitalize;
    font-style: italic;
    color: #999999;
    padding-left: 10px;
    font-size: 25px; }
  .an_event_details .event_date_hr h4 {
    text-transform: capitalize;
    font-style: italic;
    color: #999999;
    padding-left: 20px;
    padding-top: 10px;
    font-size: 16px; }
  .an_event_details .event_details_text {
    padding-top: 25px;
    color: #003333;
    font-size: 14px; }
  .an_event_details .event_date_text {
    padding-left: 20px;
    padding-top: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #003333; }
    .an_event_details .event_date_text .title h4 {
      color: #999999;
      font-style: italic;
      font-size: 16px;
      padding-top: 10px;
      text-transform: capitalize; }
  .an_event_details .an_event_content {
    padding-bottom: 20px; }
  .an_event_details .an_event_buttons {
    padding-bottom: 54px;
    padding-top: 34px; }
    .an_event_details .an_event_buttons .previous_button {
      position: relative;
      margin-top: 18px; }
      .an_event_details .an_event_buttons .previous_button span a {
        color: #82ca9c;
        font-weight: 600;
        text-transform: capitalize; }
      .an_event_details .an_event_buttons .previous_button span .fa-chevron-circle-left {
        color: #82ca9c; }
    .an_event_details .an_event_buttons .all_events_button .button {
      width: 140px;
      height: 38px;
      background-color: #ffffff;
      border-color: #82ca9c;
      border-radius: 4px;
      border-width: 1px;
      line-height: 0.8;
      font-weight: 600;
      display: block;
      margin: 0 auto;
      color: #82ca9c;
      text-transform: capitalize; }
    .an_event_details .an_event_buttons .next_button span {
      margin-top: 18px;
      float: right; }
      .an_event_details .an_event_buttons .next_button span a {
        color: #82ca9c;
        font-weight: 600;
        text-transform: capitalize; }
      .an_event_details .an_event_buttons .next_button span .fa-chevron-circle-right {
        color: #82ca9c; }

@media (max-width: 640px) {
  .an_event_details .an_event_buttons .previous_button {
    position: relative; }
    .an_event_details .an_event_buttons .previous_button span a {
      color: #82ca9c;
      font-size: 12px;
      font-weight: 600;
      text-transform: capitalize; }
    .an_event_details .an_event_buttons .previous_button span .fa-chevron-circle-left {
      color: #82ca9c;
      font-size: 12px; }
  .an_event_details .an_event_buttons .all_events_button .button {
    width: 100px;
    font-size: 10px;
    height: 38px;
    background-color: #ffffff;
    border-color: #82ca9c;
    border-radius: 4px;
    border-width: 1px;
    line-height: 1.6;
    font-weight: 600;
    display: block;
    color: #82ca9c;
    text-transform: capitalize; }
  .an_event_details .an_event_buttons .next_button span {
    margin-top: 18px;
    float: right; }
    .an_event_details .an_event_buttons .next_button span a {
      color: #82ca9c;
      font-weight: 600;
      text-transform: capitalize;
      font-size: 12px; }
    .an_event_details .an_event_buttons .next_button span .fa-chevron-circle-right {
      color: #82ca9c;
      font-size: 12px; }
  .an_event_details .event_details_hr h4 {
    text-transform: capitalize;
    font-style: italic;
    color: #999999;
    padding-left: 10px;
    font-size: 25px; }
  .an_event_details .event_date_hr h4 {
    text-transform: capitalize;
    font-style: italic;
    color: #999999;
    padding-left: 20px;
    padding-top: 10px;
    font-size: 16px; }
  .an_event_details .event_details_text {
    padding-top: 25px;
    color: #003333;
    font-size: 14px; }
  .an_event_details .event_date_text {
    padding-left: 20px;
    padding-top: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #003333; }
    .an_event_details .event_date_text .title h4 {
      color: #999999;
      font-style: italic;
      font-size: 16px;
      padding-top: 10px;
      text-transform: capitalize; } }

.reveal .Login_icon {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 30px; }
  .reveal .Login_icon span img {
    width: 20%; }
  .reveal .Login_icon h4 {
    color: #003333;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 10px; }

.reveal .login-header, .reveal .login-body, .reveal .login-footer {
  padding-left: 40px;
  padding-right: 40px; }

.reveal .login-body {
  padding-bottom: 60px; }
  .reveal .login-body .Login_button {
    width: 100%;
    background-color: #82ca9c;
    font-weight: 700;
    text-transform: uppercase; }
  .reveal .login-body .forgot-password {
    color: #82ca9c;
    font-size: 13px; }

.reveal .login-footer {
  background: #eeedf1;
  padding: 20px 30px;
  text-align: center; }
  .reveal .login-footer p {
    margin: 0;
    font-size: 13px;
    font-weight: 700; }
    .reveal .login-footer p a {
      color: #82ca9c; }

.reveal .login-header .close-button {
  color: #ccc; }

.forgot-password {
  font-size: 13px;
  color: #82ca9c;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px; }
  .forgot-password:hover {
    color: #82ca9c; }

.Corporate {
  position: absolute;
  top: 300px;
  z-index: 1;
  overflow: hidden; }
  .Corporate h1 {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 46px; }
  .Corporate h3 {
    text-transform: uppercase;
    color: #82ca9c;
    font-weight: 300;
    line-height: 36px; }
    .Corporate h3 .ifas {
      color: #fff; }

.Corporate_header {
  background-position: center;
  background-size: cover;
  position: relative; }

.Partners_header {
  background-position: center;
  background-size: cover;
  position: relative; }
  .Partners_header .Header_Overlay {
    background: linear-gradient(120deg, rgba(28, 41, 52, 0.8), rgba(28, 41, 52, 0.6), rgba(13, 107, 91, 0.5), rgba(13, 107, 91, 0.7));
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.Content_Section {
  padding-top: 60px; }
  .Content_Section h6 {
    letter-spacing: 3px; }
  .Content_Section h4 {
    color: #003333;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px; }
  .Content_Section h4:first-of-type {
    margin-top: 0; }
  .Content_Section a {
    color: #82ca9c;
    font-weight: 700;
    font-size: 13px; }
  .Content_Section hr {
    width: 150px;
    margin-right: 0;
    margin-left: 0;
    position: relative;
    margin-top: 10px;
    border-bottom: 2px solid #003333; }
  .Content_Section ul li {
    font-size: 13px; }
  .Content_Section .tabs-panel {
    padding-left: 0;
    padding-right: 0; }
  .Content_Section .tabs {
    border: none; }
    .Content_Section .tabs .tabs-title a {
      text-transform: uppercase;
      margin-right: 20px;
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      color: #003333;
      background: #fff;
      padding-bottom: 10px; }
      .Content_Section .tabs .tabs-title a:hover {
        border-bottom: 2px solid #003333; }
    .Content_Section .tabs .tabs-title.is-active a {
      color: #82ca9c;
      border-bottom: 2px solid #82ca9c; }
  .Content_Section .tabs-content {
    border: none; }
  .Content_Section .corporate-image {
    padding-top: 50px; }
    .Content_Section .corporate-image .Col {
      padding-left: 0;
      padding-right: 0; }
      .Content_Section .corporate-image .Col .Green {
        border-color: #82ca9c; }
  .Content_Section #panel2 {
    padding-bottom: 60px; }

@media (min-width: 640px) {
  .Corporate {
    padding-left: 80px; }
  .Corporate_header {
    height: 650px; }
  .Content_Section .page-content hr {
    left: -80px; } }

.Partner_body {
  padding-top: 80px;
  padding-bottom: 80px; }
  .Partner_body .line-section .h4 {
    color: #999999;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 15px; }
    .Partner_body .line-section .h4 i.fa {
      color: #82ca9c;
      margin-right: 20px; }
  .Partner_body .line-section hr {
    color: #999999;
    margin-top: 20px;
    margin-bottom: 30px; }
  .Partner_body .Contacts {
    margin-top: 20px; }
  .Partner_body .fields-row {
    padding-top: 10px;
    padding-bottom: 10px; }

.Ads {
  margin-top: 50px;
  height: 350px;
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 30px;
  box-shadow: 0 0 10px 10px #e3e3e3;
  margin-left: 30px; }

.Partner_section {
  padding-top: 50px;
  padding-bottom: 50px; }
  .Partner_section .Logo_image {
    height: 100px; }
  .Partner_section .h3 {
    color: #003333;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 28px; }
  .Partner_section .Partner {
    border: 1px solid #e3e3e3;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    box-shadow: 0 0 15px #888888;
    min-height: 380px; }
    .Partner_section .Partner .image {
      padding-bottom: 30px;
      filter: grayscale(1);
      transition-duration: .5s;
      margin-right: auto;
      margin-left: auto;
      text-align: center; }
      .Partner_section .Partner .image:hover {
        filter: none;
        transition-duration: .5s; }
    .Partner_section .Partner .bio {
      font-size: 12px; }
    .Partner_section .Partner .link {
      font-size: 12px;
      text-align: right;
      padding-top: 30px;
      padding-bottom: 20px; }
      .Partner_section .Partner .link a {
        color: #82ca9c;
        font-weight: 600; }
        .Partner_section .Partner .link a .fa-angle-right {
          font-weight: 800;
          font-size: 17px; }

.Banner_section .Banner {
  padding-bottom: 50px; }

.Partners_header .partner-button {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #82ca9c;
  color: #fff;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 700; }
  .Partners_header .partner-button .fa-home {
    font-size: 20px; }
  .Partners_header .partner-button .fa-angle-right {
    font-size: 16px;
    font-weight: 800;
    padding-left: 10px;
    border-left: 2px solid #82ca9c; }

.Partner_Overview .columns:first-of-type {
  padding-top: 50px;
  padding-bottom: 50px; }

.Partner_Overview .Title {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px;
  color: #999999; }

.Partner_Overview .h4 {
  color: #003333;
  margin-bottom: 30px;
  margin-top: 15px;
  font-size: 19px;
  font-weight: 700; }

.Product_overview .Line {
  background: linear-gradient(90deg, #003333, #fff);
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px; }

.Product_overview .Title {
  font-size: 15px;
  font-weight: 600;
  color: #003333;
  min-height: 42px; }

.Product_overview h4 {
  color: #192531;
  font-size: 19px;
  font-weight: 700; }

.Product_overview .content {
  padding-top: 15px;
  padding-bottom: 15px; }

.News_Body {
  padding-top: 100px;
  padding-bottom: 100px; }
  .News_Body .Col {
    background: #82ca9c;
    padding: 10px 5px;
    border-radius: 5px;
    margin-top: 10px; }
    .News_Body .Col h3 {
      color: #fff;
      text-align: center;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0; }
    .News_Body .Col p {
      color: #fff;
      margin-top: -5px;
      margin-bottom: 0;
      text-align: center; }
  .News_Body .News_Content {
    padding-right: 30px;
    padding-left: 30px; }
    .News_Body .News_Content hr {
      border-bottom: 1px solid #dfdfdf; }
    .News_Body .News_Content ul li {
      font-size: 13px;
      color: #4b4a4a; }
    .News_Body .News_Content .Avatar .avatar {
      width: 60px;
      padding-bottom: 10px; }
    @media (min-width: 40em) {
      .News_Body .News_Content .Avatar {
        padding-bottom: 40px; }
        .News_Body .News_Content .Avatar .avatar {
          padding-bottom: 0; } }
    .News_Body .News_Content .Body {
      font-size: 13px;
      color: #4b4a4a;
      padding-bottom: 20px; }
      .News_Body .News_Content .Body .name {
        color: #003333;
        text-transform: none;
        margin-bottom: 7px;
        font-weight: 600; }
    @media (min-width: 40em) {
      .News_Body .News_Content .Body {
        padding-left: 0;
        padding-bottom: 40px; }
        .News_Body .News_Content .Body.First {
          padding-bottom: 0; } }
    .News_Body .News_Content .Submit {
      text-align: right; }
      .News_Body .News_Content .Submit .button {
        background: #82ca9c;
        padding-right: 30px;
        padding-left: 30px;
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 0; }
    .News_Body .News_Content .comments-title {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600; }
    .News_Body .News_Content .Title_hr {
      margin-top: 5px;
      margin-bottom: 40px; }
    .News_Body .News_Content .Title_para {
      margin-bottom: 10px;
      color: #9b9b9b; }
      .News_Body .News_Content .Title_para .author {
        color: #006666; }
    .News_Body .News_Content p {
      margin-bottom: 10px; }
    .News_Body .News_Content .Title {
      color: #003333;
      font-weight: 400;
      font-size: 32px;
      text-transform: uppercase; }
    .News_Body .News_Content .Blog_title {
      text-transform: none;
      font-weight: 600; }
    .News_Body .News_Content .first_para {
      color: #999999; }
      .News_Body .News_Content .first_para .fa {
        color: #82ca9c; }
    .News_Body .News_Content .blurb p {
      line-height: 26px; }
    .News_Body .News_Content .content_blurb {
      margin-bottom: 60px; }
    .News_Body .News_Content .share {
      list-style: none; }
      .News_Body .News_Content .share li {
        display: inline;
        margin-right: 5px;
        margin-left: 5px;
        color: #c1c1c1;
        font-size: 14px; }
        .News_Body .News_Content .share li a {
          color: #c1c1c1; }
          .News_Body .News_Content .share li a .fa {
            color: #c1c1c1; }
    .News_Body .News_Content .link {
      color: #82ca9c;
      font-size: 14px;
      font-weight: 600; }
      .News_Body .News_Content .link .fa {
        font-weight: 800;
        font-size: 18px; }
    .News_Body .News_Content .articles_bottom {
      border: 1px solid #e1e1e1;
      padding: 30px 20px 20px;
      text-align: center; }
    .News_Body .News_Content .share_para {
      text-align: center;
      margin-bottom: -16px; }
      .News_Body .News_Content .share_para span {
        background: #fff;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 24px;
        font-weight: 300; }
    .News_Body .News_Content .facebook {
      color: #4267b2; }
    .News_Body .News_Content .twitter {
      color: #1da1f2; }
  .News_Body .Tags {
    padding-top: 20px; }
    .News_Body .Tags .Categories {
      background: #f1f1f1;
      padding: 0 20px; }
      .News_Body .Tags .Categories table {
        margin-top: 30px; }
        .News_Body .Tags .Categories table tr td {
          padding: 10px 0;
          border-bottom: 1px solid #ddd;
          background: #f1f1f1; }
          .News_Body .Tags .Categories table tr td a {
            color: #003333; }
          .News_Body .Tags .Categories table tr td .active {
            color: #82ca9c; }
        .News_Body .Tags .Categories table tr:last-of-type td {
          padding-bottom: 20px;
          border: transparent; }
        .News_Body .Tags .Categories table tr:first-of-type td {
          padding-top: 20px;
          font-weight: 600; }
    .News_Body .Tags h3 {
      font-size: 24px;
      color: #003333;
      margin-top: 30px;
      margin-bottom: 15px; }
    .News_Body .Tags .Recent {
      line-height: 20px; }
      .News_Body .Tags .Recent a {
        font-size: 13px;
        color: #999999; }
      .News_Body .Tags .Recent hr {
        margin-top: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #999999; }
    .News_Body .Tags .Call .button {
      background: #82ca9c;
      color: #fff;
      width: 100%;
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 13px;
      font-weight: 700;
      margin-top: 20px;
      border-radius: 0; }
    .News_Body .Tags .form-group {
      position: relative; }
      .News_Body .Tags .form-group .search-button {
        position: absolute;
        top: 10px;
        right: 20px;
        color: #c1c1c1; }
    .News_Body .Tags .button {
      background: #f8f9fb;
      color: #003333;
      padding: 7px 8px;
      border-radius: 3px;
      margin-bottom: 5px;
      font-size: 12px; }
      .News_Body .Tags .button.active {
        background: #82ca9c;
        color: #fff; }
    .News_Body .Tags .facebook {
      color: #4267b2; }
    .News_Body .Tags .twitter {
      color: #1da1f2; }

.Positioner {
  padding-right: 0;
  padding-left: 0; }

@media (min-width: 40em) {
  .Positioner.first {
    padding-left: 0;
    padding-right: 15px; }
  .Positioner.last {
    padding-right: 0;
    padding-left: 15px; } }

.Form .h3 {
  color: #999999;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase; }
  .Form .h3 .fa {
    color: #82ca9c; }

.Form hr {
  margin-top: 5px; }

.Form .button {
  margin-top: 15px; }

.split-form label {
  font-weight: 600;
  color: #999999;
  margin-bottom: 3px; }
  .split-form label .italic {
    font-style: italic; }

.IFAs.Description .Description-col {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 30px;
  background: #82ca9c;
  position: relative; }
  .IFAs.Description .Description-col .Description-content {
    padding-top: 40px; }
    .IFAs.Description .Description-col .Description-content p {
      color: #fff; }
    .IFAs.Description .Description-col .Description-content ul {
      list-style: none;
      padding: 0; }
      .IFAs.Description .Description-col .Description-content ul li {
        padding-left: 1.3em;
        color: #003333;
        font-size: 13px;
        font-weight: 600; }
      .IFAs.Description .Description-col .Description-content ul li:before {
        content: "\f105";
        /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em;
        /* same as padding-left set on li */
        width: 1.3em;
        /* same as padding-left set on li */
        font-size: 15px;
        font-weight: 800; }
  .IFAs.Description .Description-col .half-div {
    width: 65%; }
    .IFAs.Description .Description-col .half-div h4 {
      color: #003333;
      font-weight: 600; }
  .IFAs.Description .Description-col .IFA_Rule {
    border-bottom: 2px solid #003333; }
  @media (min-width: 40em) {
    .IFAs.Description .Description-col .IFA_Rule {
      width: 200px;
      left: -50px;
      position: absolute; } }

.IFAs .Join {
  position: relative; }
  .IFAs .Join .Join-row .faded-header {
    text-transform: uppercase;
    color: #999999;
    letter-spacing: 2px; }
  .IFAs .Join .Join-row .normal-header {
    color: #003333;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px; }
  .IFAs .Join .Join-row p {
    color: #003333; }
  .IFAs .Join .Join-row .Process img {
    max-width: 100%;
    height: auto;
    display: block; }
  .IFAs .Join .Join-row .Image {
    padding-top: 30px; }
  .IFAs .Join .Join-row .Empower {
    position: absolute;
    top: 350px;
    bottom: 0;
    z-index: 3;
    color: #fff;
    padding: 30px;
    text-align: left; }
    .IFAs .Join .Join-row .Empower h3, .IFAs .Join .Join-row .Empower h5 {
      color: #fff;
      text-align: left; }
    .IFAs .Join .Join-row .Empower h3 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 40px; }
    .IFAs .Join .Join-row .Empower h5 {
      font-size: 1.25rem; }
  .IFAs .Join .Join-row .Side-col {
    position: relative; }
  @media (min-width: 40em) {
    .IFAs .Join .Join-row .Side-col {
      position: static; }
    .IFAs .Join .Join-row .Empower {
      top: 120px; }
      .IFAs .Join .Join-row .Empower h3, .IFAs .Join .Join-row .Empower h5 {
        text-align: center; } }
  @media (min-width: 40em) {
    .IFAs .Join .Join-row .Image {
      padding-top: 0;
      position: absolute;
      top: -75px;
      bottom: 0; }
      .IFAs .Join .Join-row .Image img {
        max-width: 100%;
        height: auto;
        display: block; } }

.IFAs .Apply_section {
  position: relative;
  background: linear-gradient(90deg, #1b2733, #1b2733, #1b2733, #fff, #fff); }
  .IFAs .Apply_section .Apply_col {
    background: #1b2733;
    padding-top: 70px; }
    .IFAs .Apply_section .Apply_col .diligence_content {
      width: 85%; }
      .IFAs .Apply_section .Apply_col .diligence_content .diligence_content__body {
        color: #fff; }
    .IFAs .Apply_section .Apply_col h4 {
      color: #82ca9c;
      font-weight: 600;
      margin-bottom: 20px; }
    .IFAs .Apply_section .Apply_col p {
      color: #fff; }
    .IFAs .Apply_section .Apply_col .Apply {
      position: relative;
      height: 100%; }
      .IFAs .Apply_section .Apply_col .Apply a {
        position: absolute;
        display: flex;
        bottom: 20px;
        left: 15px;
        color: #82ca9c;
        font-weight: 700;
        text-transform: uppercase; }
        .IFAs .Apply_section .Apply_col .Apply a i.fa {
          font-weight: 800;
          font-size: 20px; }
      @media (min-width: 40em) {
        .IFAs .Apply_section .Apply_col .Apply a {
          right: 100px; } }

.IFA_Form {
  padding: 15px; }
  .IFA_Form h4 {
    color: #82ca9c; }
  .IFA_Form hr {
    margin-top: 5px; }

.IFA_Introduction {
  padding-top: 50px;
  padding-bottom: 50px; }

.First_container h5 {
  text-transform: uppercase;
  font-size: 17px;
  color: #ccc;
  letter-spacing: 2px; }

.First_container h3 {
  color: #003333;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px; }

@media (min-width: 40em) {
  .First_container {
    padding-top: 100px;
    padding-right: 50px; } }

.IFA_section {
  padding-top: 50px;
  padding-bottom: 50px; }
  .IFA_section.RE_section .RE_row {
    margin-bottom: 0; }
  .IFA_section.RE_section .Amenity .Content_section h5 {
    text-transform: none; }

.Blue_container {
  padding: 150px 30px 80px;
  background: #222d32;
  position: relative;
  margin-left: 30px; }
  .Blue_container hr {
    width: 200px;
    border-bottom: 2px solid #82ca9c;
    position: absolute;
    left: -75px;
    top: 100px; }
  .Blue_container a {
    color: #fff;
    font-weight: 800; }
    .Blue_container a .fa {
      font-weight: 800;
      font-size: 16px; }
  .Blue_container p {
    color: #fff;
    margin-bottom: 40px; }
  .Blue_container .para {
    margin-bottom: 10px; }
    .Blue_container .para a {
      font-weight: 400; }
  .Blue_container h4 {
    color: #fff;
    font-weight: 600; }

.Search_form {
  position: relative; }
  .Search_form .search {
    height: 40px;
    width: 100%; }
  .Search_form .search-button {
    position: absolute;
    right: 0;
    top: 0;
    background: #82ca9c; }

.Errors_form {
  padding-top: 50px;
  margin-bottom: -20px; }
  .Errors_form ul {
    color: red;
    font-style: italic; }

span.error {
  color: red;
  font-style: italic;
  font-size: 15px; }

.Full_Testimonial .slick-list {
  overflow: hidden; }

.Full_Testimonial .slick-dots {
  text-align: right;
  padding: 15px; }
  .Full_Testimonial .slick-dots li {
    margin: 0; }

.Full_Testimonial .Title {
  text-transform: none;
  font-size: 14px;
  font-style: italic; }

.Full_Testimonial.slick-dotted.slick-slider {
  padding-top: 30px;
  padding-bottom: 30px; }

.Video_Testimonials {
  padding-top: 50px;
  padding-bottom: 20px;
  background: #1b2733; }
  .Video_Testimonials .slick-dots li button::before {
    color: #fff;
    font-size: 10px; }
  .Video_Testimonials .video-note {
    position: relative; }
    .Video_Testimonials .video-note p {
      color: #fff;
      text-transform: uppercase;
      font-size: 15px;
      margin-bottom: 0;
      margin-top: 5px; }
    .Video_Testimonials .video-note h4 {
      color: #82ca9c;
      font-weight: 800;
      text-transform: uppercase;
      font-size: 34px; }
    .Video_Testimonials .video-note h1 {
      font-size: 105px;
      color: #648970;
      margin-top: -32px; }
    .Video_Testimonials .video-note hr {
      margin-top: 0;
      margin-bottom: 0;
      width: 30px;
      position: absolute;
      left: 15px;
      border-bottom: 2px solid #82ca9c; }

.no-padding {
  padding-right: 0;
  padding-left: 0; }

.Text_Testimonials {
  padding-top: 50px;
  padding-bottom: 50px; }
  .Text_Testimonials .text {
    font-size: 13px;
    color: #9e9c9c;
    padding-top: 20px; }
  .Text_Testimonials p {
    color: #9e9c9c; }
  .Text_Testimonials .more {
    text-align: center; }
    .Text_Testimonials .more a {
      color: #82ca9c;
      background: #fff;
      border: 1px solid #82ca9c;
      padding-left: 30px;
      padding-right: 30px;
      text-transform: uppercase;
      margin-top: -50px;
      margin-bottom: 0; }
  @media (min-width: 40em) {
    .Text_Testimonials .even {
      border-left: 1px solid #d1d1d1;
      padding-left: 60px; }
    .Text_Testimonials .odd {
      padding-right: 60px; } }
  .Text_Testimonials .even, .Text_Testimonials .odd {
    margin-bottom: 100px;
    padding-bottom: 20px; }
  .Text_Testimonials img {
    border-radius: 50%;
    border: 1px solid #d1d1d1;
    width: 75px; }
  .Text_Testimonials .head-section {
    position: relative;
    margin-top: -5px; }
    .Text_Testimonials .head-section .title {
      position: absolute;
      text-align: center;
      left: 100px; }
      .Text_Testimonials .head-section .title.heading {
        top: 5px;
        color: #1b2733;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 800; }
      .Text_Testimonials .head-section .title.name {
        top: -20px;
        color: #999999;
        font-size: 13px;
        font-weight: 600;
        right: 0; }
  .Text_Testimonials .icon {
    color: #82ca9c;
    margin-bottom: 0; }

.Testimonials_Form {
  background: #fbfcfb;
  padding-top: 70px;
  padding-bottom: 70px; }
  .Testimonials_Form .Green_button {
    padding-right: 40px;
    padding-left: 40px; }

.Appointment {
  position: relative;
  background: #2b3a40;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden; }
  .Appointment .Title {
    text-align: left;
    position: relative;
    color: #82ca9c;
    font-size: 21px;
    font-weight: 300;
    margin-top: 18px; }
    .Appointment .Title .fa {
      font-size: 24px; }
  .Appointment input[type=text] {
    box-shadow: none;
    padding-left: 10px;
    border-radius: 2px;
    border: none; }
  .Appointment button[type=submit].Button {
    border: none;
    background: none;
    display: inline;
    color: #82ca9c;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 18px; }
  .Appointment label {
    text-align: left;
    color: #fff;
    padding-top: 5px; }
  .Appointment .form-control {
    margin-bottom: 0; }
  .Appointment .Form {
    margin-top: 12px;
    margin-bottom: 12px; }
  .Appointment .Button {
    margin: 0; }

@media (min-width: 40em) {
  .Appointment .Title {
    text-align: left; }
    .Appointment .Title .fa {
      position: absolute;
      left: -25px;
      top: 3px; }
  .Appointment .Button {
    position: absolute;
    right: 15px; }
  label {
    text-align: center; } }

.no-padding {
  padding-left: 0;
  padding-right: 0; }

.home {
  color: #333; }

.Home__body {
  overflow: hidden; }

.e {
  margin-top: 0;
  margin-bottom: 20px;
  color: red;
  font-style: italic; }

.alert, .alert-box {
  background: #82ca9c;
  z-index: 1000; }

td.figure {
  text-align: right; }

.Subscription_notification {
  position: fixed;
  top: 50% !important; }

.Menu__Logo {
  padding: 0; }
  .Menu__Logo ul {
    padding: 0; }
    .Menu__Logo ul li {
      padding: 0; }
      .Menu__Logo ul li a {
        padding: 0; }

#responsive-menu {
  padding-top: 20px;
  padding-bottom: 20px; }

@media (min-width: 640px) {
  #responsive-menu {
    padding: 10px 0; } }

.block-header {
  text-transform: uppercase; }

.Product__Intro {
  padding-top: 40px;
  padding-bottom: 40px; }

@media (min-width: 640px) {
  .Product__Intro {
    padding-top: 80px;
    padding-bottom: 80px; } }

.alert-box {
  z-index: 10;
  position: fixed;
  top: 80px;
  right: 10px;
  border: none;
  color: #003333; }

.large-header {
  font-weight: 700; }
  .large-header h1 {
    font-weight: 700; }

.cms-partners {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px; }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.Roadshow__Form label {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400; }

.Roadshow__Form .hr {
  border-bottom: 2px solid #eeedf1;
  margin-top: 10px; }

.Roadshow__Form .button {
  font-size: 18px; }

.Roadshow__Form .form-control {
  margin-bottom: 20px; }

.Roadshow__Form .columns .h5 {
  margin-top: 50px;
  color: #82ca9c;
  text-transform: uppercase; }

.Roadshow__Form form {
  padding-top: 20px; }
  .Roadshow__Form form .cols-1, .Roadshow__Form form .cols-2 {
    padding-left: 0;
    padding-right: 0; }
  .Roadshow__Form form ul {
    margin-left: 0;
    list-style: none;
    border: 1px solid #eeedf1;
    padding: 15px;
    margin-top: -15px;
    background: #fbfbfb; }
    .Roadshow__Form form ul a {
      color: #82ca9c; }
  @media (min-width: 40em) {
    .Roadshow__Form form .cols-1 {
      padding-left: 0;
      padding-right: 15px; }
    .Roadshow__Form form .cols-2 {
      padding-right: 0;
      padding-left: 15px; } }

.logo-section .row .logo {
  display: block;
  text-align: center;
  margin: 20px auto 40px;
  width: 200px; }

.logo-section .header-row .columns .h3 {
  color: #82ca9c;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 600;
  line-height: 32px; }

.logo-section .header-row .columns .hr {
  border-bottom: 1px solid #eeedf1;
  margin-top: 0; }

.logo-section .header-row .columns .h4 {
  color: #4b4a4a;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600; }

.logo-section .header-row .columns .details {
  float: right;
  list-style: none;
  margin-left: 0;
  max-width: 300px; }
  .logo-section .header-row .columns .details li {
    color: #4b4a4a; }

header.feature {
  background: linear-gradient(60deg, #3e4952, #3e4952, #3e4952, #318173, #318173, #318173);
  padding-bottom: 105px; }
  header.feature .h3 {
    color: #fff;
    font-weight: 800;
    text-transform: uppercase; }

section.row .form-body {
  padding: 30px;
  border: 1px solid #82ca9c;
  background: #f8fbf8; }
  section.row .form-body .h4.investment {
    color: #82ca9c;
    text-decoration: underline;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 600; }
  section.row .form-body .form-submit {
    text-align: center;
    margin-right: auto;
    margin-left: auto; }
    section.row .form-body .form-submit .survey-submit {
      color: #fff;
      background: #82ca9c;
      font-weight: 600;
      text-align: center;
      padding: 12px 100px;
      text-transform: uppercase;
      border-radius: 2px; }
  section.row .form-body label.small-title {
    font-size: 17px;
    padding-top: 20px;
    color: #555454;
    font-weight: 600; }
  section.row .form-body .rating input {
    padding-right: 70px; }
  section.row .form-body .rating label {
    font-size: 15px; }
  section.row .form-body .items input {
    font-size: 15px; }
  section.row .form-body .items label {
    font-size: 15px; }
  section.row .form-body .email-address input[type=text] {
    margin: 0; }
  section.row .form-body .email-address ul {
    margin: 0 0 -10px;
    list-style: none;
    border: 1px solid #cae8d5; }
    section.row .form-body .email-address ul li {
      padding-left: 20px; }
      section.row .form-body .email-address ul li a {
        color: #82ca9c; }

section.row .footer-section {
  background: linear-gradient(0deg, #3e4952, #318173);
  border-left: 1px solid #3e4952;
  border-right: 1px solid #318173; }
  section.row .footer-section .footer-list {
    margin: 0;
    list-style: none;
    padding-top: 30px;
    padding-bottom: 50px;
    position: relative; }
    section.row .footer-section .footer-list li {
      display: inline-block; }
    section.row .footer-section .footer-list li:first-child {
      padding-left: 100px; }
      section.row .footer-section .footer-list li:first-child .h4 {
        color: #fff;
        font-weight: 800;
        font-size: 18px;
        text-transform: uppercase;
        padding-top: 20px;
        text-align: center; }
        @media (min-width: 40em) {
          section.row .footer-section .footer-list li:first-child .h4 {
            text-align: left; } }
    section.row .footer-section .footer-list li:last-child {
      text-align: center;
      display: block; }
      section.row .footer-section .footer-list li:last-child img {
        width: 140px; }
      @media (min-width: 40em) {
        section.row .footer-section .footer-list li:last-child {
          text-align: right;
          position: absolute;
          display: inline-block;
          right: 100px; } }

.ifa-welcome {
  position: relative; }
  .ifa-welcome h2 {
    color: #1d2a2f !important;
    font-weight: 600 !important;
    font-size: 42px; }
  .ifa-welcome .high {
    min-height: 350px; }
  .ifa-welcome img {
    position: relative;
    right: 5px;
    bottom: 5px;
    width: 100%; }
  .ifa-welcome .find-more {
    text-transform: uppercase;
    color: #82ca9c;
    font-weight: 600;
    font-size: 13px; }
    .ifa-welcome .find-more i.fa {
      font-weight: 800;
      font-size: 17px; }

.employee-details {
  padding-top: 75px;
  padding-bottom: 60px;
  background: #f8fbf8; }
  .employee-details .endorse-image {
    padding-right: 15px; }
    .employee-details .endorse-image img {
      width: 100%;
      box-shadow: 0 0 15px 5px #888; }
    .employee-details .endorse-image .email a {
      color: #6b6a6a;
      font-weight: 600; }
  .employee-details .h4 {
    font-weight: 600;
    margin-top: 20px;
    color: #1d2a2f;
    margin-bottom: 0; }
  .employee-details .h5 {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 15px;
    color: #6b6a6a; }
  .employee-details .contents .name {
    color: #1d2a2f;
    margin-top: -10px;
    font-size: 36px;
    font-weight: 500; }
  .employee-details .contents .region {
    color: #1d2a2f;
    font-weight: 400;
    margin-bottom: 30px; }

.ifa-RE {
  background: #1d2a2f;
  padding-top: 75px;
  padding-bottom: 75px; }
  .ifa-RE * {
    color: #fff; }
  .ifa-RE .real-estate h6 {
    color: #fff;
    letter-spacing: 3px;
    text-transform: none; }
  .ifa-RE .real-estate .content p {
    color: #fff; }
  .ifa-RE .advisor .top-box {
    box-shadow: 0 0 20px 5px #000;
    min-height: 250px;
    background: #1d2a2f;
    position: relative;
    padding: 25px; }
    .ifa-RE .advisor .top-box p {
      position: absolute;
      bottom: 45px;
      color: #fff; }
    .ifa-RE .advisor .top-box a {
      position: absolute;
      bottom: 25px;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase; }
  .ifa-RE .advisor .green-rule {
    border-bottom: 3px solid #82ca9c;
    z-index: 1;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    left: -65px;
    max-width: 120px; }
  @media (min-width: 40em) {
    .ifa-RE .advisor .top-box {
      margin-top: -155px; } }

.ifa-investment {
  padding-top: 75px;
  padding-bottom: 75px; }
  .ifa-investment .endorse-image .image {
    position: relative;
    margin-top: -50px;
    padding-right: 20px; }
    .ifa-investment .endorse-image .image a {
      position: absolute;
      bottom: 30px;
      right: 70px;
      color: #fff;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase; }
      .ifa-investment .endorse-image .image a i {
        font-weight: 800;
        font-size: 17px; }
  .ifa-investment .endorse-text h6 {
    color: #999999;
    letter-spacing: 3px;
    text-transform: none;
    margin-top: 15px; }
  .ifa-investment .endorse-text h3 {
    color: #1d2a2f;
    font-weight: 500; }
  .ifa-investment .endorse-text .endorse-text {
    color: #6b6a6a; }

ul li {
  font-size: 13px;
  color: #6b6a6a; }

.ifa-footer {
  background: #1d2a2f;
  padding-top: 20px;
  padding-bottom: 20px; }
  .ifa-footer .copyright p {
    color: #fff;
    margin: 0; }
  .ifa-footer .links ul li a {
    color: #fff;
    padding: 0 5px;
    font-weight: 600;
    font-size: 13px; }
  .ifa-footer .links ul li:hover a {
    color: #82ca9c; }
  @media (min-width: 40em) {
    .ifa-footer .links ul {
      float: right; } }
  .ifa-footer .links.menu > li > a {
    padding-right: 10px;
    padding-left: 10px; }

.ifa-tips {
  min-height: 300px;
  position: relative; }
  .ifa-tips .row .image {
    position: relative;
    max-width: 800px; }
    .ifa-tips .row .image h3 {
      position: absolute;
      top: 30px;
      color: #fff;
      font-style: italic; }
  .ifa-tips p.name {
    position: absolute;
    bottom: 30px;
    right: 40px;
    color: #fff;
    font-weight: 800;
    font-size: 18px; }

@media (min-width: 40em) {
  .ifa-welcome {
    position: relative; }
    .ifa-welcome img {
      position: absolute;
      right: 5px;
      width: 35%;
      bottom: -60px; }
  .employee-details .endorse-image {
    padding-right: 40px; } }
