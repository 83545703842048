.Appointment {
  position: relative;
  background: $dark-green;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  .Title {
    text-align: left;
    position: relative;
    color: $green;
    font-size: 21px;
    font-weight: 300;
    margin-top: 18px;

    .fa {
      font-size: 24px;
    }
  }

  input[type=text] {
    box-shadow: none;
    padding-left: 10px;
    border-radius: 2px;
    border: none;
  }

  button[type=submit].Button {
    border: none;
    background: none;
    display: inline;
    color: $green;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 18px;
  }

  label {
    text-align: left;
    color: #fff;
    padding-top: 5px;
  }

  .form-control {
    margin-bottom: 0;
  }

  .Form {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .Button {
    margin: 0;
  }
}

@media (min-width: 40em) {
  .Appointment {

    .Title {
      text-align: left;
      .fa {
        position: absolute;
        left: -25px;
        top: 3px;
      }
    }

    .Button {
      position: absolute;
      right: 15px;
    }
  }

  label {
    text-align: center;
  }
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}