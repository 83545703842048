.Contact-us {
  position: absolute;
  top: 300px;
  z-index: 1;
  overflow: hidden;

  h1 {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }

  h1:last-of-type {
    color:$green;
    font-weight: 200;
    margin-top:-20px;
  }
}

.Contact-row {
  overflow: hidden;
}

@media (min-width: 640px) {
  .Contact-us {
    padding-left:200px;
  }
}


.Contact__Form {
  padding-top:50px;
  padding-bottom:50px;
  background: #fdfdfd;

  .Contact_call {
    margin-bottom:40px;
  }

  .Address {
    .columns {
      margin-bottom: 20px;
    }
  }


  input[type=text],input[type=email], input[type=password],select,textarea {
    margin-bottom: 6px;
    font-size: 12px;
  }

  .Top_heading {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    padding-top: 15px;
  }

  .Top__border {
    border-top: 2px solid $green;
    width: 40px;
  }

  .Address__section {
    .Address__title {
      h5 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        padding-top:8px;
      }

      .Email {
        font-size:12px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom:5px;
      }
    }

    .State__title {
      h5 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom:0;
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 0;

      a {
        color:$paragraph;
      }
    }
  }

  .Phone__section {
    .Social {
      font-size:12px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom:5px;

      ul {
        list-style: none;
        margin-left:0;
        margin-top:15px;
        margin-bottom:15px;
        li {
          display: inline-block;
          font-size: 16px;
          padding-right:5px;
          padding-left:5px;


          a {
            color: $paragraph;

            &:hover {
              color:$green;
            }
          }
        }
      }
    }

    .Phone__title {
      h5 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        padding-top:8px;
        margin-bottom:0;
      }
    }

    p {
      font-size: 14px;
      margin-bottom:0;
    }
  }

  .Bottom__heading {
    text-transform: uppercase;
    font-weight:600;
    font-size:24px;
    margin-bottom: 15px;
  }

  .Contact__details {
    .columns {
      padding:0;
    }
  }
}

.Top_line {
  border-top:2px solid $green;
  width:40px;

}

.Hidden_Overflow {
  overflow: hidden;
}

