.Plus {
  display: none;
  font-size: 18px;
  height: 72px;
  line-height: 32px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transform: matrix(1, 0, 0, 1, 0, 0);
  width: 72px;
  transition: all .5s ease;

  .corner {
    background: $green none scroll;
    color: #fff;
    font-size: 18px;
    height: 125px;
    line-height: 32px;
    padding: 40px 4px;
    position: absolute;
    right: -5px;
    top: -50px;
    transform: matrix(0.707107, -0.707107, 0.707107, 0.707107, 0, 0);
    width: 46px;

    i {
      color: #fff;
      font-size: 16px;
      height: 34px;
      line-height: 16px;
      margin-right: 4px;
      padding: 20px 20px 14px 14px;
      text-align: left;
      transform: matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0);
      width: 34px;
    }

  }
}

.Real_Estate_Slider_Text {
  margin-top: 300px;
}

.RE_section {
  padding-top: 40px;
  padding-bottom: 40px;

  .Amenity {
    max-width: 350px;
    margin: 20px auto;
  }

  .small-header {
    text-transform: none;
    letter-spacing: 3px;
  }

  .larger-header {
    font-weight: 700;
    color: $black;
  }

  .Content_Column {
    padding-top: 20px;
    max-width: 350px;
    margin-right:auto;
    margin-left:auto;

    .Amenities_Rule {
      width: 120px;
      border-bottom: 2px solid $green;
      position: relative;
      left: -30px;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .Amenity {


    .Content_section {
      border: 1px solid $lightest-gray;
      background: #fff;
      box-shadow: 0 1px 3px 1px #c2c2c2;
      height: 220px;
      transition: all .5s ease;
      padding: 20px;

      .content {
        overflow: hidden;
      }

      h5 {
        color:$green;
        text-transform: uppercase;
        font-size: 16px;
        margin-top:15px;
        margin-bottom:15px;
        font-weight: 600;
      }

      .learn {

        a {
          display: none;
          color: $green;
          padding-bottom: 5px;
          font-size: 13px;
          width: 75px;
          font-weight: 600;
          border-bottom: 1px solid #82ca9c;
        }

      }

      .Icon {
        margin-top: -40px;
        transition: all .5s ease;

        .fa-stack {
          font-size: 20px;
          color:$green;
          transition: all .5s ease;
        }
      }
    }

    .Image-section {
      position:relative;
       border: 1px solid #ececec;
      box-shadow: 0 1px 3px 1px #ececec;

      .Image {
        height: 250px;
        transition: all .5s ease;
      }
    }

    &:hover {
      .Plus {
        display: block;
        transition: all .5s ease;
      }

      .Image {
        height: 200px;
        transition: all .5s ease;
      }

      .Content_section {
        height: 270px;
        transition: all .5s ease;

        .learn {
          a {
            display: block;
          }
        }

        .Icon {
          margin-top: -50px;
          transition: all .5s ease;

          .fa-stack {
            font-size: 30px;
            color:$green;
            transition: all .5s ease;
          }
        }
      }
    }
  }

  .RE_Link {
    color: $green;
    font-size: 13px;
    font-weight: 600;

    .fa-angle-right {
      font-weight: 800;
    }
  }
}


.Gray_RE_section {
  background: $light-gray;
}

.RE_About_Section {
  background-size: cover;
  background-position:center center;

  .Explore {
    text-transform: none;
    font-size:20px;
    font-weight: 600;
    color: #fff;
    font-style: italic;
    margin-bottom:30px;
  }

  .Block_Title, .Block_Title h2 {
    font-weight: 800;
    color: #fff;
    line-height: 48px;
    margin-bottom:30px;

    a{
      color: white;
      border-bottom: 2px solid #82ca9c;
    }

  }

  .About {
    font-size: 16px;
    color:#fff;
    width:200px;
    font-weight: 700;
    border-bottom: 2px solid $green;
    padding-bottom: 5px;
  }

}

.RE_About_Section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-position:center center;
}


.Column_Centered {
  margin-right: auto;
  margin-left: auto;
}

.Italic_Para {
  p {
    font-size: 15px;
    font-style: italic;
    color: $situ;
  }
}

.RE_row {
  .content-section{
    a{
      color: $green;
      border-color: $green;
    }
  }
}

@media (min-width: 1024px) {

  .Italic_Para {
    padding-left: 115px;
  }


  .RE_section {
    padding-top: 200px;
    padding-bottom: 200px;

    .RE_row {
      margin-bottom: -300px;
      padding-right: 100px;
      padding-left: 100px;
    }


    .Single_RE_row {
      padding-right: 100px;
      padding-left: 100px;
    }
  }

  .Single_RE_section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .First_RE_Section {
    padding-top: 100px;
  }

  .Middle_Section {
    padding-top:40px;
    padding-bottom: 0;
  }


  .RE_About_Section {
    padding-top: 200px;
    padding-bottom: 150px;
    background-size: cover;
    background-position:center center;
  }
}


.Great_Section {
  padding-top: 40px;
  padding-bottom: 40px;

  .small-header {
    letter-spacing: 3px;
    color: $gray;
  }

  .larger-header {
    font-weight: 700;
    color: $black;
  }

  .Great_Rule {
    width: 150px;
    border-bottom: 2px solid $green;
    position: relative;
    left: -60px;
    margin-right: 0;
    margin-left: 0;
  }

  p.Link {
    margin-bottom: 6px;

    .RE_Link {
      color: $green;
      font-size: 13px;
      font-weight: 600;

      .fa-angle-right {
        font-weight: 800;
      }
    }
  }
}

.larger-header {
  font-weight: 700;
  color: $black;
}

.small-header {
  letter-spacing: 3px;
  color: $gray;
}

.has-error {
  border: 1px solid red;
}

.real-estate-link {
  color: $green;
  font-weight: 600;
  transition: all .5s ease;

  &:hover {
    color:$black;
    transition: all .5s ease;
  }

  .fa {
    font-size:16px;
  }
  .fa-angle-right {
    font-weight: 800;
  }
}

.video-image {
  background-size: cover;
  text-align: center;
  .video {
    height: 600px;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .fa {
      color:#fff;
      font-size: 84px;

      &:hover {
        color: $green;
      }
    }
  }
}

.Explore_Section {
  padding-top: 40px;
  position:relative;

  .larger-header {
    width: 300px;
  }

  .Green_Rule {
    border-bottom: 2px solid $green;
    width: 150px;
    position:relative;
    margin-right:0;
    margin-left:0;
    left: -50px;
  }

  .Calculator {
    right: 0;
    top: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 200px;
    background: $situ;

    .Calculator_Content {
      padding-right:20px;
      padding-left: 20px;
      padding-top:60px;

      .First, .Last {
        padding-left: 0;
        padding-right: 0;
      }

      #rate, #period,#principal, #downpayment {
        max-width: 74px;
        display: inline-block;
        border-radius: 2px 0 0 2px;
        margin-bottom: 10px;
        padding-left: 5px;
        padding-right: 5px;
      }

      #output {
        max-width: 310px;
      }


      .field-label {
        background: #fff;
        display: inline-block;
        padding: 10px 8px;
        margin-left: -4px;
        border-radius: 0 2px 2px 0;
        font-size: 12px;
        border-top:1px solid #fff;
      }

      .Developer {
        color: $green;
        font-weight: 600;
        text-align: center;
        font-size: 18px;

      }

      .Dev_Logo {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        padding-top:10px;
        padding-bottom: 50px;
        img {
          max-width: 110px;
        }
      }

    }

    .Header {
      max-width: 230px;
      margin-bottom: 30px;
      border-bottom: 1px solid $gray;

      h5 {
        font-weight: 700;
        color: $green;
      }
    }

    label {
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 10px;
    }

    .button {
      background: $green;
      padding: 10px 40px;
      font-weight: 600;
      border-radius: 3px;
    }

    .Logo {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      background: $light-gray;
      padding-top:17px;
      padding-bottom: 17px;
      img {
        max-width: 125px;
      }
    }
  }
}

@media (min-width: 480px) {
  .Explore_Section .Calculator .Calculator_Content {
    padding-right: 100px;
    padding-left: 100px;
  }
}
.Image_Section {
  position: relative;
  border: 1px solid #f3f3f3;
  box-shadow: 0 1px 3px 1px #f3f3f3;
}

@media (min-width: 1024px) {
  .Great_Section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .Calculator {
    position: absolute;
  }

  .First_Column {
    padding-left: 50px;
    padding-bottom:50px;
    height: 470px;
  }

  .Explore_Section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

}

.Slogan {
  padding-left: 0;
}


