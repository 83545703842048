.Diaspora_background {
  border:none;

  * {
    color:#fff;
  }

  a:hover,a:active {
    color:$green;
    i {
      color: $green;
    }
  }

  a:focus {
    color: $green;
    outline: none;

    i {
      color: $green;
    }
  }
}

.Home__body {

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }

}

.Welcome__section {
  padding-top: 50px;
  padding-bottom: 50px;

  h1, h2 {
    color: $gray;
  }

  h2 {
    font-weight: 300;
  }
}

.Home_Overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 530px;
  background: rgba(0,0,0,.3);
}

@media (min-width: 480px) {
  .Home_Overlay {
    height: 650px;
  }
}

.Homepage_Content {
  position: absolute;
  top: 300px;
  z-index: 1;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  h2 {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 42px;
  }

  h4 {
    font-weight: 300;
  }

  h1,h2,h3,h4 {
    color:#fff;
    margin-bottom: 0;
  }


  h1 {
    margin-bottom:0;
    line-height: 48px;

  }

  p{
    color: #fff;
    margin-top:10px;
    font-size: 16px;
  }
  
}




.Events_header.Home_header {
  .Navs {
    position: absolute;
    top: 45%;
  }
}

.Home__buttons {
  .Home_button {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    border: 1px solid #fff;
    font-weight: 700;
    z-index: 2;
    font-size: 16px;
    margin: auto 10px;

    &:hover {
      background: rgba(0,0,0,.7);
    }

    &.second {
      margin-top: 10px;
    }
  }
}

@media (min-width: 640px) {
  .Home__buttons {
    .Home_button {
      padding: 20px 30px;
    }
  }
}

@media (min-width: 344px) {
  .Home__buttons {
    .Home_button {
      &.second {
        margin-top: 0;
      }
    }
  }
}

.Home__buttons {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  bottom: -100px;
  text-align: center;
}

.Products_title {
  h4  {
    font-weight: 300;
  }
}