@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,800,300italic,300,400italic,600,700,600italic,700italic);
@import "variables/colors";
@import "utilities/helpers";
@import "utilities/typography";

@import "components/menu";
@import "components/headers";
@import "components/text_image";
@import "components/member";
@import "components/event_header";
@import "pages/register";
@import "utilities/forms";
@import "pages/real-estate";
@import "pages/home";
@import "pages/finance";
@import "pages/staff";
@import "pages/history";
@import "pages/contact";
@import "pages/faq";
@import "pages/an_event";
@import "pages/login";
@import "pages/corporate";
@import "pages/partner";
@import "pages/news";
@import "pages/ifa";
@import "pages/testimonials";
@import "pages/appointment";


.home {
  color: #333;
}

.Home__body {
  overflow: hidden;
}

.e {
  margin-top: 0;
  margin-bottom: 20px;
  color: red;
  font-style: italic;
}

.alert, .alert-box {
  background: $green;
  z-index: 1000;
}

//admin
td.figure {
  text-align: right;
}

.Subscription_notification {
  position:fixed;
  top: 50%!important;
}

.Menu__Logo {
  padding:0;
  ul {
    padding:0;
    li {
      padding:0;
      a {
        padding:0;
      }
    }
  }
}

#responsive-menu {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 640px) {
  #responsive-menu {
    padding: 10px 0;
  }
}

.block-header {
  text-transform: uppercase;
}

.Product__Intro {
  padding-top:40px;
  padding-bottom: 40px;
}


@media (min-width: 640px) {
  .Product__Intro {
    padding-top:80px;
    padding-bottom: 80px;
  }
}

.alert-box {
  z-index: 10;
  position: fixed;
  top: 80px;
  right: 10px;
  border: none;
  color: #003333;
}

.large-header {
  font-weight: 700;

  h1{
    font-weight: 700;
  }

}

.cms-partners {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}


//Roadshows

.Roadshow__Form {
  label {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
  }

  .hr {
    border-bottom: 2px solid $light-gray;
    margin-top: 10px;
  }

  .button {
    font-size: 18px;
  }

  .form-control {
    margin-bottom: 20px;
  }

  .columns {
    .h5 {
      margin-top: 50px;
      color: $green;
      text-transform: uppercase;
    }
  }

  form {
    padding-top: 20px;
    .cols-1, .cols-2  {
      padding-left: 0;
      padding-right: 0;
    }

    ul {
      margin-left: 0;
      list-style: none;
      border: 1px solid $light-gray;
      padding: 15px;
      margin-top: -15px;
      background: $lightest-gray;
      a {
        color: $green;
      }
    }

    @media (min-width: 40em) {
      .cols-1 {
        padding-left: 0;
        padding-right: 15px;
      }
      .cols-2 {
        padding-right: 0;
        padding-left: 15px;
      }
    }
  }
}

.logo-section {
  .row {
    .logo {
      display: block;
      text-align: center;
      margin: 20px auto 40px;
      width: 200px;
    }
  }

  .header-row {
    .columns {
      .h3 {
        color: $green;
        text-transform: uppercase;
        font-size: 36px;
        font-weight: 600;
        line-height: 32px;
      }

      .hr {
        border-bottom: 1px solid $light-gray;
        margin-top: 0;
      }

      .h4 {
        color: $paragraph;
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 600;
      }
      .details {
        float: right;
        list-style: none;
        margin-left: 0;
        max-width: 300px;

        li {
          color: $paragraph;
        }
      }
    }
  }
}

// Survey Form

header {
  &.feature {
    background: linear-gradient(60deg, #3e4952, #3e4952,#3e4952, #318173, #318173,#318173);
    padding-bottom: 105px;

    .h3 {
      color: #fff;
      font-weight: 800;
      text-transform: uppercase;
    }
  }
}

section {
  &.row {
    .form-body {
      padding: 30px;
      border: 1px solid $green;
      background: $survey_form_background;

      .h4.investment {
        color: $green;
        text-decoration: underline;
        text-align: center;
        text-transform: uppercase;
        font-size: 24px;
        margin-top: 30px;
        margin-bottom: 20px;
        font-weight: 600;
      }

      .form-submit {
        text-align: center;
        margin-right: auto;
        margin-left: auto;

        .survey-submit {
          color: #fff;
          background: $green;
          font-weight: 600;
          text-align:center;
          padding: 12px 100px;
          text-transform: uppercase;
          border-radius: 2px;
        }
      }

      label.small-title {
        font-size: 17px;
        padding-top: 20px;
        color: lighten($paragraph, 4%);
        font-weight: 600;
      }

      .rating {
        input {
          padding-right: 70px;
        }
        label {
          font-size: 15px;
        }
      }
      .items {
        input {
          font-size: 15px;
        }
        label {
          font-size: 15px;
        }
      }

      .email-address {
        input[type=text] {
          margin: 0;
        }
        ul {
          margin: 0 0 -10px;
          list-style: none;
          border: 1px solid lighten($green, 20%);
          li {
            padding-left: 20px;
            a {
              color: $green;
            }
          }

        }
      }
    }

    .footer-section {
      background: linear-gradient(0deg, #3e4952, #318173);
      border-left: 1px solid #3e4952;
      border-right: 1px solid #318173;

      .footer-list {
        margin: 0;
        list-style: none;
        padding-top: 30px;
        padding-bottom: 50px;
        position: relative;

        li {
          display: inline-block;
        }
        li:first-child {
          padding-left: 100px;

          .h4 {
            color: #fff;
            font-weight: 800;
            font-size: 18px;
            text-transform: uppercase;
            padding-top: 20px;
            text-align: center;

            @media (min-width: 40em) {
              text-align: left;
            }
          }
        }
        li:last-child {
          text-align: center;
          display: block;
          img {
            width: 140px;
          }
          @media (min-width: 40em) {
            text-align: right;
            position:absolute;
            display: inline-block;
            right: 100px;
          }
        }
      }
    }
  }
}


.ifa-welcome {
  position: relative;

  h2 {
    color: $darkest-green!important;
    font-weight: 600 !important;
    font-size: 42px;
  }

  .high {
    min-height: 350px;
  }

  img {
    position: relative;
    right: 5px;
    bottom: 5px;
    width: 100%;
  }

  .find-more {
    text-transform: uppercase;
    color: $green;
    font-weight: 600;
    font-size: 13px;

    i.fa {
      font-weight: 800;
      font-size: 17px;
    }
  }

}

.employee-details {
  padding-top: 75px;
  padding-bottom: 60px;
  background: $background;
  .endorse-image {
    padding-right: 15px;
    img {
      width: 100%;
      box-shadow: 0 0 15px 5px #888;
    }

    .email {
      a {
        color: $para;
        font-weight: 600;
      }
    }
  }

  .h4 {
    font-weight: 600;
    margin-top: 20px;
    color: $darkest-green;
    margin-bottom: 0;
  }

  .h5 {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 15px;
    color: $para;
  }

  .contents {
    .name {
      color: $darkest-green;
      margin-top: -10px;
      font-size: 36px;
      font-weight: 500;
    }

    .region {
      color: $darkest-green;
      font-weight: 400;
      margin-bottom: 30px;
    }
  }
}

.ifa-RE {
  background: $darkest-green;
  padding-top: 75px;
  padding-bottom: 75px;

  * {
    color: #fff;
  }

  .real-estate {
    h6 {
      color: #fff;
      letter-spacing: 3px;
      text-transform: none;
    }

    .content {
      p {
        color: #fff;
      }
    }
  }

  .advisor {
    .top-box {
      box-shadow: 0 0 20px 5px #000;
      min-height: 250px;
      background: $darkest-green;
      position: relative;
      padding: 25px;

      p {
        position: absolute;
        bottom: 45px;
        color: #fff;
      }

      a {
        position: absolute;
        bottom: 25px;
        font-weight: 600;
        font-size: 13px;
        text-transform: uppercase;
      }
    }

    .green-rule {
      border-bottom: 3px solid $green;
      z-index: 1;
      position: relative;
      margin-left: 0;
      margin-right: 0;
      left: -65px;
      max-width: 120px;
    }


  }
  @media (min-width: 40em) {
    .advisor {
      .top-box {
        margin-top: -155px;
      }
    }
  }
}

.ifa-investment {
  padding-top: 75px;
  padding-bottom: 75px;

  .endorse-image {
    .image {
      position: relative;
      margin-top: -50px;
      padding-right: 20px;

      a {
        position: absolute;
        bottom: 30px;
        right: 70px;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        text-transform: uppercase;

        i {
          font-weight: 800;
          font-size: 17px;
        }
      }
    }
  }

  .endorse-text {
    h6 {
      color: $gray;
      letter-spacing: 3px;
      text-transform: none;
      margin-top: 15px;
    }

    h3 {
      color: $darkest-green;
      font-weight: 500;
    }

    .endorse-text {
      color: $para;
    }
  }
}

ul {
  li {
    font-size: 13px;
    color: $para;
  }
}

.ifa-footer {
  background: $darkest-green;
  padding-top: 20px;
  padding-bottom: 20px;

  .copyright {
    p {
      color: #fff;
      margin: 0;
    }
  }

  .links {

    ul {

      li {
        a {
          color: #fff;
          padding: 0 5px;
          font-weight: 600;
          font-size: 13px;
        }

        &:hover {
          a {
            color: $green;
          }
        }
      }
    }

    @media (min-width: 40em) {
      ul {
        float: right;
      }
    }

    &.menu>li>a {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

.ifa-tips {
  min-height: 300px;
  position: relative;

  .row {
    .image {
      position: relative;
      max-width: 800px;

      h3 {
        position:absolute;
        top: 30px;
        color: #fff;
        font-style: italic;
      }
    }
  }

  p.name {
    position: absolute;
    bottom: 30px;
    right: 40px;
    color: #fff;
    font-weight: 800;
    font-size: 18px;
  }
}


@media (min-width: 40em) {
  .ifa-welcome {
    position: relative;

    img {
      position: absolute;
      right: 5px;
      width: 35%;
      bottom: -60px;
    }
  }

  .employee-details {
    .endorse-image {
      padding-right: 40px;
    }
  }
}



















