.About__text_image-history {
  margin-top:0;
}

.orbit-container {
  height: 100%!important;

  &:focus {
    outline:none;
  }

  .Normal {
    display: none;
  }

  .Mobile {
    display: block;
  }

  @media (min-width: 640px) {
    .Normal {
      display: block;
    }

    .Mobile {
      display: none;
    }
  }

  .Content {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $green;
    padding: 50px 50px 90px 50px;
    z-index:3;

    h1,h2,h3,h4,h5, h6,p {
      color:#fff;

      &.Title {
        font-weight: 800;
      }
    }
  }
}

.Values_section {
  .Values_row {
    .orbit-bullets {
      position: absolute;
      bottom: 0;
      margin-top: .8rem;
      margin-bottom: .8rem;
      text-align: center;
      z-index: 5;
      padding-left:50px;

      button {
        width: 10px;
        height: 10px;
        background-color: #fff;

        &:focus {
          outline:none;
        }

        &:hover {
          background-color: $green;
        }

        &.is-active {
          background-color: $black;
        }

        @media (min-width: 640px) {
          &.is-active {
            background-color: $green;
          }
        }
      }
    }

    .Navs {
      position: relative;
      bottom: 70px;
      right: auto;
      margin-right: auto;
      margin-left: auto;
      left: 0;
    }

    .orbit-previous{
      left: 0;
      margin-left:50px;
    }

    .orbit-next {
      left:50px;
      margin-left:60px;
    }

    .orbit-next, .orbit-previous {
      position: absolute;
      color: #fefefe;
      width: 50px;
      height: 50px;
      padding:13px 14px;
      border-radius: 50%;
      border: 1px solid #fff;
      bottom: 0;
      z-index: 3;

      .fa {
        font-size: 23px;
      }

      &:focus {
        outline:none;
      }
    }

    @media (min-width: 992px) {
      .orbit-bullets {
        padding-left:100px;
      }
      .orbit-previous{
        left: 0;
        margin-left:100px;
      }

      .orbit-next {
        left:100px;
      }
      .Content {
        padding: 80px 50px 140px 100px;
        position: absolute;
        bottom: 0;
      }

      .Navs {
        bottom: 100px;
        margin-left: auto;
        left: 0;
      }
    }
  }
}

.Value_list__section {
  padding-top:30px;
  padding-bottom: 30px;
  h3 {
    color:$green;

    &.Title {
      font-weight: 800;
    }
  }

  .Value_list {
    list-style: none;
    margin-left: 0;
    color: $green;
    font-weight: 600;
    li {
      margin-bottom: 10px;

      .fa {
        font-weight: 800;
      }
    }
  }

  p {

  }

  @media (min-width: 640px) {
    padding-top:50px;
    padding-bottom: 50px;
  }
}
