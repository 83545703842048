.Medium_header {
   height: 540px;
   //background: url("/images/contacts/ivy.jpg");
   background-position: center;
   background-size: cover;
   position:relative;
   z-index: 5;

   .Header_Overlay {
     background: rgba(13,107,91,.36);
     position:absolute;
     top:0;
     right:0;
     bottom:0;
     left:0;

     .Menu__top-bar {
       z-index:5;
     }
   }
 }
.Faq_header {
  height: 310px;
  //background: url("/images/contacts/ivy.jpg");
  background-position: center;
  background-size: cover;
  position:relative;
  z-index: 5;

  .Header_Overlay {
    background: rgba(13,107,91,.36);
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;

    .Menu__top-bar {
      z-index:5;
    }
  }
}

.NewsHeader {
  height: 310px;
  //background: url("/images/news/papers.jpg");
  background-position: center;
  background-size: cover;
  position:relative;
  z-index: 5;

  .Header_Overlay {
    background: linear-gradient(120deg, rgba(28, 41, 52, 0.8), rgba(28, 41, 52, 0.8), rgba(13, 107, 91, 0.8), rgba(13, 107, 91, 0.8));
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }
}

.BlogsHeader {
  height: 310px;
  background: url("/images/blogs/blog.jpg");
  background-position: center;
  background-size: cover;
  position:relative;
  z-index: 5;

  .Header_Overlay {
    background: linear-gradient(120deg, rgba(28, 41, 52, 0.8), rgba(28, 41, 52, 0.8), rgba(13, 107, 91, 0.8), rgba(13, 107, 91, 0.8));
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }
}

.TestimonialsHeader {
  height: 310px;
  //background: url("/images/testimonials/testimonials.png");
  background-position: center;
  background-size: cover;
  position:relative;
  z-index: 5;

  .Header_Overlay {
    background: linear-gradient(120deg, rgba(28, 41, 52, 0.8), rgba(28, 41, 52, 0.8), rgba(13, 107, 91, 0.8), rgba(13, 107, 91, 0.8));
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }
}

.an_event_header {
  height: 620px;
  background: url("/images/contacts/ivy.jpg");
  background-position: center;
  background-size: cover;
  position:relative;
  z-index: 5;

  .Header_Overlay {
    background: rgba(13,107,91,.36);
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;

    .Menu__top-bar {
      z-index:5;
    }
  }


}

.Header_Slider {
  //background: linear-gradient(90deg, rgba(62,73,82,.3), rgba(49,129,115,.3));
}

.Greenish_Overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.36);
}




.Small_header {
  background: linear-gradient(120deg, #3e4952, #3e4952,#3e4952, #318173, #318173,#318173);
  height: 350px;
  position:relative;
  background-position: center;
  background-size: cover;

  .Header_Overlay {
    background: rgba(13,107,91,.36);
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }
}

.About__Slider_text {
  margin-top: 300px;
}

.Small_header {
  .Small_column {
    position: absolute;
    bottom: 25%;
    left: 10%;

    h1,h2,h3,h4 {
      color:#fff;
      margin:0;
    }

    &.Staff_column {
      bottom: 175px;
    }
  }
}

.Name, .Position, .Bio, .Read-more {
  padding-left: 30px;
}
.Member_image {
  margin-bottom:20px;
}
@media (min-width: 640px) {
  .Medium_header {
    height: 680px;
  }

  .Events_header {
    height: auto;

    .Menu {
      height: auto!important;
    }
  }

  .Name, .Position, .Bio, .Read-more {
    padding-left:0;
  }

  .Member_image {
    margin-bottom:0;
  }

  .Small_header {
    height: 459px;

    .Small_column {
      padding-left: 75px;
      position: absolute;
      bottom: 115px;
      z-index: 0;

      h2,h3,h4 {
        color:#fff;
      }
    }
  }
}


.Dark_Overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}

.Short_Header {
  background-size: cover;
  background-position: 50%;
  height: 400px !important;

  .Short_Header__text {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .Short_Header__text__content {
      color: white !important;

      p {
        color: white !important;
      }

    }
  }
}

.Custom-cms-header{
  margin-top: 1rem;

  h1,h2, h3{
    color: white;
  }
}

