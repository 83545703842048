$green: #82ca9c;
$paragraph: #4b4a4a;
$black: #003333;
$gray:#999999;
$green-overlay: rgba(13,107,91,.7);
$light-gray: #eeedf1;
$para: #6b6a6a;
$background: #f8fbf8;
$dark-green : #2b3a40;
$darker-green : #222d32;
$darkest-green: #1d2a2f;
$lightest-gray : #fbfbfb;

$border-gray : #39484d;
$gray-table-background: #f1f1f1;
$accordionmenu-arrow-color: #003333;
$survey_form_background: #f8fbf8;

$situ: #58082f;


