.Header_Slider {
  margin-top:-20px;
  position: static;
  height:620px;

  .Slider_images {
    position:absolute;
    top:-20px;

    div.slick-list.draggable {
      position:relative;
      top:-142px;
    }
  }

  .Header_Overlay {
    zoom: 1;

    .slick-slider {
      margin: 0;
      top:-142px;
    }
  }

  .Homepage__header {
    height:600px;
  }
}

.Home_header {
  background-size: cover;
  background-position: center center;
  height: 550px!important;
}

@media (min-width: 480px) {
  .Home_header {
    height: 670px!important;
  }
}


.Menu {
  background: transparent;
  //height: 162px!important;

  .dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a:after {
    border-color: #fff transparent transparent!important;
  }

  .Dropdown {
    background: $green;
  }

  .Menu_top {
    max-height: 88px;
    display: flex;
    justify-content: flex-end;
    ul {
      li {
        color: #fff;
        a {
          text-transform: none;
          color: #fff;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }


  ul {
    li {
      a {
        i {
          margin-right:10px;
          font-size: 16px;
        }
        color:#fff;
        font-size: 14px;
        text-transform: uppercase;
        padding-right:10px;
        padding-left:10px;
        font-weight: 600;
      }
      a:focus {
        outline: none;
      }
    }
  }


  }

  .top-bar {
    background:transparent;
    ul {
      background: transparent;
    }
  }

  .Menu__top-bar {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: -.1rem!important;
    background:#82ca9c;
    z-index: 3;
  }

  .Top-bar__scroll {
    background: #82ca9c;
    transition: all linear .5s;
  }

.Fade-out {
  transition: all linear 2s;
  position:absolute;
  top:0;
}

.Menu__Logo {
  padding: 5px 0;
}

.slide {
  transition: all linear 2s;
}

.slide.ng-hide {
  position:absolute;
  top:0;
}

.slick-list {
  overflow: visible;
}

.slick-slide img {
  background: #000;
}

.slick-track:focus {
  outline:none;
}

.Slider__text {
  backface-visibility: hidden;
  margin-top:200px;
  position:relative;
  z-index: 4;

  h1, h2, p {
    color:#fff;
  }

  a {
    color:#fff;
    font-weight: 800;
    border-bottom: 1px solid #fff;
    padding-bottom:5px;
    font-size: 15px;
  }

  ul {
    margin-left:0;
    margin-bottom:20px;
    list-style: none;
     li {
       padding-bottom:10px;
       a {
         color: #fff;
         font-weight: 600;
         border-bottom: transparent;
         padding-bottom: 5px;
         font-size: 16px;

         i {
           font-weight: 800;
           font-size: 18px;
           padding-right: 5px;
         }

         &:hover {
           color:$green;
         }
       }
     }

    li:last-of-type {
      a {
        color: $green;
      }
    }
  }
}

.Slider_image {
  background-size: cover!important;
  background-position: center;
  background-color: #82ca9c;
  height:600px;
}

.Slider__position {
  position: absolute;
  top: 0;
  right:0;
  bottom:0;
  left:0;
}

.Diaspora_image {
  padding:0;
  margin:0;
  background-size: cover!important;
  background-position: center!important;

  .Diaspora_background  {
    position:relative;
    background:rgba(0,0,0,.5);
    min-height: 280px;
    padding:45px 30px;

    .Products_link {
      position:absolute;
      bottom: 30px;
    }
   }
}

footer {
  background: linear-gradient(0deg, #0d6b5b, #1b2733);
  color:#fff;
  padding-top:100px;

  .website-links {
    padding-top: 50px;
    padding-bottom: 60px;
    text-align: center;

    a {
      font-size: 13px;
      color: #fff;

      &:hover {
        color: $green;
      }
    }

    .separator {
      display: none;
    }
  }

  @media (min-width: 480px) {
    .website-links {
      text-align: left;

      .separator {
        display: inline;
      }
    }
  }

  .Social {
    padding-top:60px;

    ul {
      text-align: center;
      margin:0;

      li {
        display: inline-block;
        padding-left:25px;
        list-style: none;

        a {
          color:#82ca9c;
          i {
            font-size:22px;
          }
        }
      }
    }
  }

  .gray {
    color:#a0a09c;
  }

  .Get-in-touch {
    padding-bottom:50px;
    padding-top:20px;

    p {
      margin-bottom: unset;
    }

  }

  .subscribe-input {
    border: 1px solid transparent;

    input[type="text"] {
      border:transparent;
    }
    input[type=text]:focus, input[type=text]:active {
      border:transparent;
      outline:none;
    }
  }

  .footer-list {
    margin-left:0;
    li {
      list-style: none;
      font-size: 12px;

      a {
        color: #fff;
        &:hover {
          color: $green;
        }
      }
    }
    li.first {
      margin-bottom:15px;
      font-weight: 600;
    }
  }

  .Footer__row {
    .columns {
      padding:0;
    }

    h5, p {
      color:#fff;
    }
  }

  .Subscribe_col {

    h5 {
      color:#fff;
    }

    input[type=text] {
      height: 50px;
      border-radius:2px 0 0 2px;
    }

    .Button {
      background: #82ca9c;
      height: 50px;
      display: flex;
      justify-content:center;
      line-height:50px;
      border-radius:0 2px 2px 0;

      a {
        color: #fff;
        width: 100%;
        text-align: center;
      }

      i {
        font-size: 28px;
      }
    }
  }

  ul.Link_cols {
    margin-left:0;
    .Horizontal-links {
      list-style: none;
      display: inline-block;
      padding-right:35px;
    }
  }


}

.Testimonials {
  padding-top:90px;
  padding-bottom:90px;
  position: relative;

  .Title {
    margin-bottom:30px;
  }

  .slick-list {
    overflow: hidden;
  }

  .Testimonial_identity {
    position: relative;
    left: 100px;
    top: -50px;

    p {
      margin-bottom:5px;
      color: $gray;
    }

    p:first-of-type {
      font-size:16px;
    }
  }
  .row {
   @media (min-width: 992px) {
     padding-left:150px;
     padding-right:150px;
   };

    img {
      background-color:transparent;
    }
  }

  .Para_small {
    font-size:10px;
  }

  .slick-slide {
    background:#fff;
    outline: none;
  }

  .slick-dots {
    display: block;
    position: relative;
    top:20px;
    margin-top: -20px;
    li.slick-active {
      button::before {
        color : #82ca9c;
      }

      button {

      }
    }

    li[aria-hidden="true"] {
      visibility: visible;
    }
    li {
      width: 5px;
      height: 5px;

      button::before {
        display:block;
        opacity: 1;
        font-size: 10px;
        color: $gray;
      }

      button {
        display:block;
      }

    }
  }
}

.Green {
  color:#82ca9c;
}
.Black{
  color: #003333;
}

.Overlay {
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background: rgba(29, 50, 68, 0.13);
}

//normal menu
.Menu_desktop {
  display:none;
}

ul.Submenu {
  li {
    &:hover {
      background: #7abd92;
      transition-delay: 0s;
      transition-duration: .2s;
      transition-property: all;
      transition-timing-function: ease;
      a {
        color:$darker-green;
        transition-delay: 0s;
        transition-duration: .2s;
        transition-property: all;
        transition-timing-function: ease;
      }
    }
  }
}

li.has-submenu.is-dropdown-submenu-parent.opens-left.is-active {
  background: #7abd92;
  transition-delay: 0s;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: ease;
}

.Linked_menu>ul>li {
  &:hover {
    background: $green;
    transition-delay: 0s;
    transition-duration: .2s;
    transition-property: all;
    transition-timing-function: ease;
  }
}

.Menu_mobile {
  display:block;
  background:#82ca9c;
  z-index:4;

  ul.Submenu {
    background: #82ca9c;
  }

  .Menu__top-bar {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .Menu__Logo {
    img {
      height:30px;
    }
  }

  button.menu-icon {
    position: absolute;
    right: 15px;
    top: 45px;

    &:focus {
      outline:none;
    }
  }
}

.Menu__Logo.menu>li>a img {
  max-width: 140px;
}

.Home__Feature-text {
  font-size:32px;
}

@media (min-width: 480px) {
  .Home__Feature-text {
    font-size:42px;
  }
}


.title-bar {
  background: #82ca9c;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Small__buttons {
  display:block;
}

.Large__buttons {
  display: none;
}

.User__menu {
  padding-left: 0;
  display: none;
}


//min width for the small menu is 640px

@media (min-width: 640px) {

  .User__menu {
    display: block;
  }

  .Header_Slider {
    height: 650px;
  }

  .Menu_desktop {
    display:block;
  }

  .Menu_top {
    display: block;
  }

  .Menu_mobile {
    display:none;
  }

  .Menu__top-bar {
    background: transparent;
    transition: background linear .5s,padding linear .5s;
  }

  .Top-bar__scroll{
    transition: padding linear .5s;
    background: #82ca9c;
  }


  .Homepage__header{
    height:790px;

  }

  .Slider_image {
    height:650px;
    //height: 100%;

  }

  footer {

    .Social {
      padding-top: 115px;

      ul {
        text-align: right;
      }
    }
  }

  .User__menu {
    padding-left: 40px;
  }

  .Linked_menu {
    margin-top: -7px;
  }

}


@media (min-width: 380px) {
  .Small__buttons {
    display:none;
  }

  .Large__buttons {
    display: block;
  }
}

blockquote {
  border-left:transparent;
  margin-bottom: 30px;
  color: $gray;

  i.fa {
    font-size: 20px;
    margin-left:-10px;
    padding-right:10px;
    padding-left:10px;
    color: $gray;
  }
}

.Testimonial__border {
  width: 85%;
  margin: 20px auto;
}

.Testimonial__person {
  padding: 0 8%;
}

#responsive-menu {

  .is-drilldown-submenu-parent>a:after {
    border-color: transparent transparent transparent #fff;
  }

  .js-drilldown-back>a:before {
    border-color: transparent #fff transparent transparent;
  }
}