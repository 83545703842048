.IFAs {
  &.Description {

    .Description-col {
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 30px;
      background: $green;
      position: relative;

      .Description-content {
        padding-top: 40px;

        p {
          color: #fff;
        }

        ul {
          list-style: none;
          padding: 0;
          li {
            padding-left: 1.3em;
            color: $black;
            font-size: 13px;
            font-weight: 600;
          }

          li:before {
            content: "\f105"; /* FontAwesome Unicode */
            font-family: FontAwesome;
            display: inline-block;
            margin-left: -1.3em; /* same as padding-left set on li */
            width: 1.3em; /* same as padding-left set on li */
            font-size: 15px;
            font-weight: 800;
          }
        }
      }

      .half-div {
        width: 65%;

        h4 {
          color: $black;
          font-weight: 600;
        }
      }

      .IFA_Rule {
        border-bottom: 2px solid $black;

      }

      @media (min-width: 40em) {
        .IFA_Rule {
          width: 200px;
          left: -50px;
          position: absolute;
        }
      }
    }
  }
}

.IFAs {
  .Join {
    position: relative;
    .Join-row {

      .faded-header {
        text-transform: uppercase;
        color: $gray;
        letter-spacing: 2px;
      }

      .normal-header {
        color: $black;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      p {
        color: $black;
      }

      .Process {
        img {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }

      .Image {
        padding-top: 30px;
      }

      .Empower {
        position: absolute;
        top: 350px;
        bottom: 0;
        z-index: 3;
        color: #fff;
        padding: 30px;
        text-align: left;


        h3, h5 {
          color: #fff;
          text-align:left;
        }

        h3 {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 40px;
        }

        h5 {
          font-size: 1.25rem;
        }
      }

      .Side-col {
        position: relative;
      }

      @media (min-width: 40em) {
        .Side-col {
          position: static;
        }

        .Empower {
          top: 120px;

          h3, h5 {
            text-align:center;
          }
        }

      }
      @media (min-width: 40em) {
        .Image {
          padding-top: 0;
          position: absolute;
          top: -75px;
          bottom: 0;

          img {
            max-width: 100%;
            height: auto;
            display: block;
          }
        }
      }
    }
  }


  .Apply_section {
    position: relative;
    background: linear-gradient(90deg, #1b2733, #1b2733, #1b2733, #fff, #fff);

    .Apply_col {
      background: #1b2733;
      padding-top: 70px;

      .diligence_content {
        width: 85%;

        .diligence_content__body{
          color: #fff;
        }
      }

      h4 {
        color: $green;
        font-weight: 600;
        margin-bottom: 20px;
      }
      p {
        color: #fff;
      }

        .Apply {
          position: relative;
          height: 100%;

          a {
            position: absolute;
            display: flex;
            bottom: 20px;
            left: 15px;
            color: $green;
            font-weight: 700;
            text-transform: uppercase;

            i.fa {
              font-weight: 800;
              font-size: 20px;
            }
        }

          @media (min-width: 40em) {
            a {
              right: 100px;
            }
          }
      }
    }
  }
}

.IFA_Form {
  padding: 15px;

  h4 {
    color: $green;
  }

  hr {
    margin-top: 5px;
  }
}

.IFA_Introduction {
  padding-top: 50px;
  padding-bottom: 50px;
}

.First_container {

  h5 {
    text-transform: uppercase;
    font-size: 17px;
    color: #ccc;
    letter-spacing: 2px;
  }

  h3 {
    color: $black;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;

  }

  @media (min-width: 40em) {
    padding-top: 100px;
    padding-right: 50px;
  }
}

.IFA_section {
  padding-top: 50px;
  padding-bottom: 50px;

  &.RE_section {
    .RE_row {
      margin-bottom: 0;
    }

    .Amenity {
      .Content_section {
        h5 {
          text-transform: none;
        }
      }
    }
  }
}

.Blue_container {
  padding: 150px 30px 80px;
  background: $darker-green;
  position: relative;
  margin-left:30px;

  hr {
    width: 200px;
    border-bottom: 2px solid $green;
    position: absolute;
    left: -75px;
    top: 100px;
  }


  a {
    color: #fff;
    font-weight: 800;

    .fa {
      font-weight: 800;
      font-size: 16px;
    }
  }

  p {
    color: #fff;
    margin-bottom: 40px;
  }

  .para {
    margin-bottom: 10px;

    a {
      font-weight: 400;
    }
  }

  h4 {
    color: #fff;
    font-weight: 600;
  }
}

.Search_form {
  position: relative;

  .search {
    height: 40px;
    width: 100%;
  }

  .search-button {
    position: absolute;
    right: 0;
    top: 0;
    background: $green;
  }


}

.Errors_form {
  padding-top: 50px;
  margin-bottom: -20px;

  ul {
    color: red;
    font-style: italic;
  }
}

span.error {
  color: red;
  font-style: italic;
  font-size: 15px;
}
