.Finance_Header {
  position:relative;

  .Finance_Action {
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 14px;
    margin:0;
    padding:15px 40px;
    background: $green;

    i {
      font-size:18px;
      font-weight: 800;
      padding-left:10px;
    }
  }
}

.Product__Intro {

  .Heading {
    h4 {
      font-weight: 700;
      color:$black;
    }

    h6 {
      text-transform: none;
      letter-spacing: 3px;
    }

    .Small__Rule {
      width: 170px;
      border-bottom: 2px solid $black;
      margin-left:0;
    }
  }

  .Content {
    * {
      color: $black;
    }
  }
}

.Our_Rates {
  .Heading {
    h4 {
      font-weight: 700;
      color:$black;
    }
  }

  .Rates {
    ul.menu {

      .is-accordion-submenu-parent>a:after {
        border-color: $gray transparent transparent;
      }

      li {

        a:active,a:focus {
          background: $green;
          color:#fff;
          outline:none;
          border: none;
        }

        a {
          padding: 20px;
          color: $green;
          border-radius: 0;
          border: 1px solid #dbdbdb;
          margin-bottom:6px;
          font-weight: 600;
          font-style: italic;
          font-size: 14px;

        }

        .Content {
          padding-top: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #dbdbdb;;
        }
      }
    }
  }

  .Goal_Calculator {
    padding: 30px 20px;
    background: $black;

    label {
      color: #fff;
    }

    .Inputs {
      .columns:first-of-type {
        padding-left:0;
      }

      .columns:nth-of-type(2) {
        padding-right:0;
      }
    }

    .Title {
      color: $green;
    }

    .Calculate {
      background: $green;
      padding: 10px 40px;
      border-radius: 2px;
    }
  }
}

.Product_Rates {
  padding-top: 40px;
  padding-bottom: 40px;
  .Black_Container {
    background: $black;
    padding: 30px 30px 15px;
    border-radius: 3px;

    .Rate_Title {
      color: $gray;
      font-size: 24px;
      text-align: center;
      margin-top: 30px;
    }

    .Invest_Call {
      margin:0;
      text-align: center;
      color: $gray;
    }

    .White_Rule {
      margin-bottom: 15px;
    }

    .Date_Title {
      text-align: center;
      color:$green;
      font-size: 36px;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .Padding {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .Current_Rates {

    .rzslider .rz-bar {
      height: 2px;
    }

    .rzslider {
      margin-bottom: 50px;

      .rz-bar.rz-selection {
        background: $green;
      }

      .rz-pointer {
        top: -12.5px;
        z-index: 3;
        width: 25px;
        height: 25px;
        background: $black;

        &:after {
          top: 9px;
          left: 9px;
          background: $black;
        }

        &:focus {
          outline: none;
          background: $black;
        }
      }
    }
    h6 {
      color: $gray;
      text-align: center;
    }

    h4 {
      color: $green;
      text-transform: uppercase;
      font-size: 28px;
      text-align: center;
    }

    #rate {
      background: #fff;
      border: none;

      &:focus {
        outline:none;
      }
    }

    .slider {
      height: 2px;

      .slider-fill {
        height: 2px;
        background: $green;
      }

      .slider-handle {
        border-radius: 50%;
        background: $black;
      }

      .slider-handle:focus {
        outline: none;
      }
    }

    @media (min-width: 640px) {
      .Ksh_Rate {
        padding-right: 0;
        padding-left: 30px;
      }

      .Usd_Rate {
        padding-left:0;
        padding-right: 30px;
        margin-bottom:0;
      }
    }

    .Usd_Rate {
      margin-bottom: 40px;
    }

    .Ksh_Rate {

    }

    .Rate {
      background:$green;
      padding: 16px;
      border-radius: 2px;

      p {
        text-align:center;
        font-size: 16px;
        color: #fff;
        margin-bottom:0;
      }

      h6 {
        font-size: 15px;
        color: #fff;
        font-weight: 600;
      }

      .rate {
        color: $black;
        text-align: center;
        line-height: 28px;

        .symbol {
          font-size: 14px;
          top: -20px;
        }

      }
    }
  }
}


.Governance {
  padding-top: 40px;
  padding-bottom: 40px;

  .Gov_Title {
    color: $black;
    font-weight: 600;
    margin-bottom: 20px;

    p {
      color: $paragraph;
    }
  }

  ul.menu {

    .is-accordion-submenu-parent>a:after {
      border-color: $green transparent transparent;
    }


    li {

      a:active, a:focus {
        background: $black;
        color: #fff;
        outline: none;
        border: none;
      }

      a {
        border-radius: 0;
        padding: 25px 20px;
        color: $green;
        border: 1px solid #dbdbdb;
        margin-bottom:6px;
        font-weight: 600;
        font-style: italic;
        font-size: 14px;
      }

      .Content {
        padding-top: 15px;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #dbdbdb;

        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }

  }
}

@media (min-width: 640px) {
  .Product_Rates {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .Governance {
    padding-top: 0;
    padding-bottom: 80px;

    .Governance_Content {
      padding-right: 60px;

      ul {
        li {
          font-size: 13px;
          padding-bottom:10px;
          color: $paragraph;
        }
      }
    }
  }
}

.Big_Text {
  line-height: 46px;
  font-size: 42px;
  font-weight: 600;
}

.Small_Text {
  line-height: 42px;
  color: #fff;
  h3 {
    color: #fff;
  }
}

.Product_Slider_Text {
  margin-top: 350px;
}

.Slider__text {
  .Green_Link {
    color: $green;
    border-bottom: 1px solid $green;
  }
}

.Slider__position {
  .Finance_Action {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
    background: $green;
    font-weight: 800;

    .fa-home {
      font-size: 20px;
    }

    .fa-angle-right {
      font-size: 16px;
      font-weight: 800;
      padding-left:10px;
      border-left: 2px solid $green;
    }
  }
}

.finance_para {
  font-size: 16px;
  p{
    font-size: 16px;
  }
  a{
    color: $green;
    border-color: $green;
  }
}

.Product_Highlights {
  padding-bottom: 20px;
  .Highlights {
    .Product {
      max-width: 320px;
      margin-right: auto;
      margin-left:auto;
      margin-top:20px;
      margin-bottom: 20px;

      img {
        min-height: 350px;
      }

      .Content_section {
        border: 1px solid $light-gray;
        padding: 30px 30px;
        background: #fff;
        min-height: 260px;
        overflow-wrap: break-word;

        h5 {
          color: $green;
        }

        a {
          color:$green;
          font-size: 13px;
          font-weight: 600;
          border-bottom: 1px solid $green;
          padding-bottom: 3px;
        }

        p {
          font-weight: 400;
        }
      }
    }
  }

  .Highlights_Heading {
    background: $green;
    padding-top: 50px;
    padding-bottom:40px;

    .Highlights_Rule {
      width: 150px;
      float: left;
      border-bottom: 1px solid $black;
    }

    .small-header {
      text-transform: none;
      letter-spacing: 3px;
      color: $light-gray;
    }

  .bigger-header {
    color: $black;
    font-weight: 600;
  }

  }
}

@media(min-width: 640px) {
  .Product_Slider {
    position:relative;
  }
  .Product_Highlights {
    .Highlights {
      padding-left: 80px;
      padding-right: 80px;
      margin-top: -200px;

      .Product {
        margin-top:0;
        margin-bottom: 0;
      }
    }

    .Highlights_Heading {
      padding-bottom: 200px;
      .heading {
        padding-left: 80px;
      }
    }
  }
}

.Product_Rates.General_Rates {
  background: #fff;
  .Relative_Row {
    position: relative;
  }
  .Current_Rates {
    .Products {
      padding-left: 5px;
    }
    .Rate {
      background: #fff;
      border: 1px solid $green;
      border-radius: 0;

      h6 {
        color: $gray;
      }

      p {
        color: $gray;
      }

      .rate {
        color: $green;
      }
    }
  }

  .Holder {
    position:relative;
    margin-top: 50px;

    .Black_Container {
      .Padding {
        .Rate_Title {
          margin-top:10px;
        }

        .Date_Title {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (min-width: 640px) {
    .Holder {
      padding-left: 50px;
      position: absolute;
      bottom: 40px;
    }
  }
}

.Signup_Section {
  padding-top: 100px;
  padding-bottom: 100px;
  background: $lightest-gray;

  .Signup_Call {
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    color: $green;
  }

  .Sign_Para {
    color: $gray;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 14px;
  }

  .Sign_button {
    background: $green;
    padding-right: 50px;
    padding-left: 50px;
    border-radius: 2px;
  }

  .Signup_Content {
    margin-right:auto;
    margin-left:auto;
  }
}

.Slider__position .Finance_Action {
  font-weight: 700;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}


